import { default as _401fnxP86FOlDMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/401.vue?macro=true";
import { default as change_45passwordrozeD39yndMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/change-password.vue?macro=true";
import { default as indexnTDlmwKCIQMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/index.vue?macro=true";
import { default as notificationsozz3omyq5xMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/notifications.vue?macro=true";
import { default as preferences9khdzfbnMJMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/preferences.vue?macro=true";
import { default as accountvMbtXyMDOXMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account.vue?macro=true";
import { default as _91invoiceid_93fo7s4FKj5eMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/[invoiceid].vue?macro=true";
import { default as _91postageid_93ae8zlDvjWKMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/[serviceid]/[postageid].vue?macro=true";
import { default as indexhTaary8YidMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/index.vue?macro=true";
import { default as newinvoice5bua9d7QSpMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/newinvoice.vue?macro=true";
import { default as companyZKUbYb4hcPMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/company.vue?macro=true";
import { default as indexY5rB4QS6c4Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/confirm/email/[email]/index.vue?macro=true";
import { default as indexLaDbXkRjuUMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/confirm/index.vue?macro=true";
import { default as indexv9ZxpDgzv5Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/reset-password/email/[email]/code/[code]/index.vue?macro=true";
import { default as indexWknMG2fyJMMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/reset-password/email/[email]/index.vue?macro=true";
import { default as indexVlgVHUY4IEMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/reset-password/index.vue?macro=true";
import { default as campaignsKbWVxOB7FXMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/campaigns.vue?macro=true";
import { default as _91folderName_935Emq09ckg6Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/assets/[folderName].vue?macro=true";
import { default as indexB8EYfL2ZSbMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/assets/index.vue?macro=true";
import { default as billingvfsF07HosbMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/billing.vue?macro=true";
import { default as index4UjS7E360NMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/[campaignId]/index.vue?macro=true";
import { default as createxUJAAtNDl6Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/create.vue?macro=true";
import { default as index0Jp6SwtAoZMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/index.vue?macro=true";
import { default as campaignSupportDUiOFKqfIWMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaignSupport.vue?macro=true";
import { default as creditscP3nZN2EBiMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/credits.vue?macro=true";
import { default as discountsRdDS5rVW26Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/discounts.vue?macro=true";
import { default as historyIWUY783WrXMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/history.vue?macro=true";
import { default as indexNBKCIKbUOlMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/index.vue?macro=true";
import { default as index7Qr9n68vsCMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/file/[listFileId]/index.vue?macro=true";
import { default as resultsueHEAi2jcvMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/file/[listFileId]/results.vue?macro=true";
import { default as index8QrfIe8nypMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/index.vue?macro=true";
import { default as upload1an5GfSpItMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/upload.vue?macro=true";
import { default as createznPAxF8JQ6Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/create.vue?macro=true";
import { default as index59P2NL4Tr0Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/index.vue?macro=true";
import { default as indexX1ltMRQcdHMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations/[locationId]/index.vue?macro=true";
import { default as indexOx32TfYHKgMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations/index.vue?macro=true";
import { default as locationsdX62eiPJe7Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations.vue?macro=true";
import { default as settingsdRo2NC7kNsMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/settings.vue?macro=true";
import { default as indexXZXmnhuw4BMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/users/index.vue?macro=true";
import { default as users6lNBzLfcs7Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/users.vue?macro=true";
import { default as createp82qzStFCrMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/create.vue?macro=true";
import { default as indexBfE1GwOSc0Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/index.vue?macro=true";
import { default as companyfPzteudkO0Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company.vue?macro=true";
import { default as faqcn7zQLbSYPMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/faq.vue?macro=true";
import { default as forgotPassword9jeN0Pkm0OMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/forgotPassword.vue?macro=true";
import { default as index0Hquz1dJouMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/index.vue?macro=true";
import { default as listsuN0P9iGNEfMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/lists.vue?macro=true";
import { default as loginfvw1wfu1dxMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/login.vue?macro=true";
import { default as privacy9gE8S3bhm9Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/privacy.vue?macro=true";
import { default as indexUDO8h0nBf1Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/[productid]/index.vue?macro=true";
import { default as categoriesrqkauglh6AMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/categories.vue?macro=true";
import { default as envelopesNuY0Qxw1ZtMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/envelopes.vue?macro=true";
import { default as indexaiWibLfWB6Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/index.vue?macro=true";
import { default as letterShop5hJthmNit9Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/letterShop.vue?macro=true";
import { default as paperStockfgj7FqE54nMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/paperStock.vue?macro=true";
import { default as registerdJCzS6FEmqMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/register.vue?macro=true";
import { default as indexw15Li3rtW0Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/reports/index.vue?macro=true";
import { default as brandsqMCGf8xgM7Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/brands.vue?macro=true";
import { default as index7HR0D3RDLJMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/campaignType/[campaignTypeId]/index.vue?macro=true";
import { default as indexSXrLcE2nsDMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/campaignType/index.vue?macro=true";
import { default as creditszDjQwKC2xLMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/credits.vue?macro=true";
import { default as discountsJPI9q6xqgeMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/discounts.vue?macro=true";
import { default as indexfqklpF4pmvMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/index.vue?macro=true";
import { default as jobIntegrationsFFZQxfnRnoMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/jobIntegrations.vue?macro=true";
import { default as supportnkX95JW7KVMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/support.vue?macro=true";
import { default as terms8p0pIoEthMMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/terms.vue?macro=true";
import { default as clientWLVRjtXMVnMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users/client.vue?macro=true";
import { default as indexnFwzREju40Meta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users/index.vue?macro=true";
import { default as internalJhCltyAbwhMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users/internal.vue?macro=true";
import { default as usersSFwlHZ9KFeMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users.vue?macro=true";
import { default as version2DVDRheQ4yMeta } from "/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/version.vue?macro=true";
export default [
  {
    name: _401fnxP86FOlDMeta?.name ?? "401",
    path: _401fnxP86FOlDMeta?.path ?? "/401",
    meta: _401fnxP86FOlDMeta || {},
    alias: _401fnxP86FOlDMeta?.alias || [],
    redirect: _401fnxP86FOlDMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/401.vue").then(m => m.default || m)
  },
  {
    path: accountvMbtXyMDOXMeta?.path ?? "/account",
    children: [
  {
    name: change_45passwordrozeD39yndMeta?.name ?? "account-change-password",
    path: change_45passwordrozeD39yndMeta?.path ?? "change-password",
    meta: change_45passwordrozeD39yndMeta || {},
    alias: change_45passwordrozeD39yndMeta?.alias || [],
    redirect: change_45passwordrozeD39yndMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: indexnTDlmwKCIQMeta?.name ?? "account",
    path: indexnTDlmwKCIQMeta?.path ?? "",
    meta: indexnTDlmwKCIQMeta || {},
    alias: indexnTDlmwKCIQMeta?.alias || [],
    redirect: indexnTDlmwKCIQMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsozz3omyq5xMeta?.name ?? "account-notifications",
    path: notificationsozz3omyq5xMeta?.path ?? "notifications",
    meta: notificationsozz3omyq5xMeta || {},
    alias: notificationsozz3omyq5xMeta?.alias || [],
    redirect: notificationsozz3omyq5xMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: preferences9khdzfbnMJMeta?.name ?? "account-preferences",
    path: preferences9khdzfbnMJMeta?.path ?? "preferences",
    meta: preferences9khdzfbnMJMeta || {},
    alias: preferences9khdzfbnMJMeta?.alias || [],
    redirect: preferences9khdzfbnMJMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account/preferences.vue").then(m => m.default || m)
  }
],
    name: accountvMbtXyMDOXMeta?.name ?? undefined,
    meta: accountvMbtXyMDOXMeta || {},
    alias: accountvMbtXyMDOXMeta?.alias || [],
    redirect: accountvMbtXyMDOXMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/account.vue").then(m => m.default || m)
  },
  {
    name: _91invoiceid_93fo7s4FKj5eMeta?.name ?? "accounting-invoice-invoiceid",
    path: _91invoiceid_93fo7s4FKj5eMeta?.path ?? "/accounting/invoice/:invoiceid()",
    meta: _91invoiceid_93fo7s4FKj5eMeta || {},
    alias: _91invoiceid_93fo7s4FKj5eMeta?.alias || [],
    redirect: _91invoiceid_93fo7s4FKj5eMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/[invoiceid].vue").then(m => m.default || m)
  },
  {
    name: _91postageid_93ae8zlDvjWKMeta?.name ?? "accounting-invoice-serviceid-postageid",
    path: _91postageid_93ae8zlDvjWKMeta?.path ?? "/accounting/invoice/:serviceid()/:postageid()",
    meta: _91postageid_93ae8zlDvjWKMeta || {},
    alias: _91postageid_93ae8zlDvjWKMeta?.alias || [],
    redirect: _91postageid_93ae8zlDvjWKMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/[serviceid]/[postageid].vue").then(m => m.default || m)
  },
  {
    name: indexhTaary8YidMeta?.name ?? "accounting-invoice",
    path: indexhTaary8YidMeta?.path ?? "/accounting/invoice",
    meta: indexhTaary8YidMeta || {},
    alias: indexhTaary8YidMeta?.alias || [],
    redirect: indexhTaary8YidMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/index.vue").then(m => m.default || m)
  },
  {
    name: newinvoice5bua9d7QSpMeta?.name ?? "accounting-invoice-newinvoice",
    path: newinvoice5bua9d7QSpMeta?.path ?? "/accounting/invoice/newinvoice",
    meta: newinvoice5bua9d7QSpMeta || {},
    alias: newinvoice5bua9d7QSpMeta?.alias || [],
    redirect: newinvoice5bua9d7QSpMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/accounting/invoice/newinvoice.vue").then(m => m.default || m)
  },
  {
    name: companyZKUbYb4hcPMeta?.name ?? "auth-company",
    path: companyZKUbYb4hcPMeta?.path ?? "/auth/company",
    meta: companyZKUbYb4hcPMeta || {},
    alias: companyZKUbYb4hcPMeta?.alias || [],
    redirect: companyZKUbYb4hcPMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/company.vue").then(m => m.default || m)
  },
  {
    name: indexY5rB4QS6c4Meta?.name ?? "auth-confirm-email-email",
    path: indexY5rB4QS6c4Meta?.path ?? "/auth/confirm/email/:email()",
    meta: indexY5rB4QS6c4Meta || {},
    alias: indexY5rB4QS6c4Meta?.alias || [],
    redirect: indexY5rB4QS6c4Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/confirm/email/[email]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLaDbXkRjuUMeta?.name ?? "auth-confirm",
    path: indexLaDbXkRjuUMeta?.path ?? "/auth/confirm",
    meta: indexLaDbXkRjuUMeta || {},
    alias: indexLaDbXkRjuUMeta?.alias || [],
    redirect: indexLaDbXkRjuUMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: indexv9ZxpDgzv5Meta?.name ?? "auth-reset-password-email-email-code-code",
    path: indexv9ZxpDgzv5Meta?.path ?? "/auth/reset-password/email/:email()/code/:code()",
    meta: indexv9ZxpDgzv5Meta || {},
    alias: indexv9ZxpDgzv5Meta?.alias || [],
    redirect: indexv9ZxpDgzv5Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/reset-password/email/[email]/code/[code]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWknMG2fyJMMeta?.name ?? "auth-reset-password-email-email",
    path: indexWknMG2fyJMMeta?.path ?? "/auth/reset-password/email/:email()",
    meta: indexWknMG2fyJMMeta || {},
    alias: indexWknMG2fyJMMeta?.alias || [],
    redirect: indexWknMG2fyJMMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/reset-password/email/[email]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVlgVHUY4IEMeta?.name ?? "auth-reset-password",
    path: indexVlgVHUY4IEMeta?.path ?? "/auth/reset-password",
    meta: indexVlgVHUY4IEMeta || {},
    alias: indexVlgVHUY4IEMeta?.alias || [],
    redirect: indexVlgVHUY4IEMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: campaignsKbWVxOB7FXMeta?.name ?? "campaigns",
    path: campaignsKbWVxOB7FXMeta?.path ?? "/campaigns",
    meta: campaignsKbWVxOB7FXMeta || {},
    alias: campaignsKbWVxOB7FXMeta?.alias || [],
    redirect: campaignsKbWVxOB7FXMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/campaigns.vue").then(m => m.default || m)
  },
  {
    path: companyfPzteudkO0Meta?.path ?? "/company",
    children: [
  {
    name: _91folderName_935Emq09ckg6Meta?.name ?? "company-companyId-assets-folderName",
    path: _91folderName_935Emq09ckg6Meta?.path ?? ":companyId()/assets/:folderName()",
    meta: _91folderName_935Emq09ckg6Meta || {},
    alias: _91folderName_935Emq09ckg6Meta?.alias || [],
    redirect: _91folderName_935Emq09ckg6Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/assets/[folderName].vue").then(m => m.default || m)
  },
  {
    name: indexB8EYfL2ZSbMeta?.name ?? "company-companyId-assets",
    path: indexB8EYfL2ZSbMeta?.path ?? ":companyId()/assets",
    meta: indexB8EYfL2ZSbMeta || {},
    alias: indexB8EYfL2ZSbMeta?.alias || [],
    redirect: indexB8EYfL2ZSbMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/assets/index.vue").then(m => m.default || m)
  },
  {
    name: billingvfsF07HosbMeta?.name ?? "company-companyId-billing",
    path: billingvfsF07HosbMeta?.path ?? ":companyId()/billing",
    meta: billingvfsF07HosbMeta || {},
    alias: billingvfsF07HosbMeta?.alias || [],
    redirect: billingvfsF07HosbMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/billing.vue").then(m => m.default || m)
  },
  {
    name: index4UjS7E360NMeta?.name ?? "company-companyId-campaigns-campaignId",
    path: index4UjS7E360NMeta?.path ?? ":companyId()/campaigns/:campaignId()",
    meta: index4UjS7E360NMeta || {},
    alias: index4UjS7E360NMeta?.alias || [],
    redirect: index4UjS7E360NMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/[campaignId]/index.vue").then(m => m.default || m)
  },
  {
    name: createxUJAAtNDl6Meta?.name ?? "company-companyId-campaigns-create",
    path: createxUJAAtNDl6Meta?.path ?? ":companyId()/campaigns/create",
    meta: createxUJAAtNDl6Meta || {},
    alias: createxUJAAtNDl6Meta?.alias || [],
    redirect: createxUJAAtNDl6Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/create.vue").then(m => m.default || m)
  },
  {
    name: index0Jp6SwtAoZMeta?.name ?? "company-companyId-campaigns",
    path: index0Jp6SwtAoZMeta?.path ?? ":companyId()/campaigns",
    meta: index0Jp6SwtAoZMeta || {},
    alias: index0Jp6SwtAoZMeta?.alias || [],
    redirect: index0Jp6SwtAoZMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: campaignSupportDUiOFKqfIWMeta?.name ?? "company-companyId-campaignSupport",
    path: campaignSupportDUiOFKqfIWMeta?.path ?? ":companyId()/campaignSupport",
    meta: campaignSupportDUiOFKqfIWMeta || {},
    alias: campaignSupportDUiOFKqfIWMeta?.alias || [],
    redirect: campaignSupportDUiOFKqfIWMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaignSupport.vue").then(m => m.default || m)
  },
  {
    name: creditscP3nZN2EBiMeta?.name ?? "company-companyId-credits",
    path: creditscP3nZN2EBiMeta?.path ?? ":companyId()/credits",
    meta: creditscP3nZN2EBiMeta || {},
    alias: creditscP3nZN2EBiMeta?.alias || [],
    redirect: creditscP3nZN2EBiMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/credits.vue").then(m => m.default || m)
  },
  {
    name: discountsRdDS5rVW26Meta?.name ?? "company-companyId-discounts",
    path: discountsRdDS5rVW26Meta?.path ?? ":companyId()/discounts",
    meta: discountsRdDS5rVW26Meta || {},
    alias: discountsRdDS5rVW26Meta?.alias || [],
    redirect: discountsRdDS5rVW26Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/discounts.vue").then(m => m.default || m)
  },
  {
    name: historyIWUY783WrXMeta?.name ?? "company-companyId-history",
    path: historyIWUY783WrXMeta?.path ?? ":companyId()/history",
    meta: historyIWUY783WrXMeta || {},
    alias: historyIWUY783WrXMeta?.alias || [],
    redirect: historyIWUY783WrXMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/history.vue").then(m => m.default || m)
  },
  {
    name: indexNBKCIKbUOlMeta?.name ?? "company-companyId",
    path: indexNBKCIKbUOlMeta?.path ?? ":companyId()",
    meta: indexNBKCIKbUOlMeta || {},
    alias: indexNBKCIKbUOlMeta?.alias || [],
    redirect: indexNBKCIKbUOlMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/index.vue").then(m => m.default || m)
  },
  {
    name: index7Qr9n68vsCMeta?.name ?? "company-companyId-lists-listId-file-listFileId",
    path: index7Qr9n68vsCMeta?.path ?? ":companyId()/lists/:listId()/file/:listFileId()",
    meta: index7Qr9n68vsCMeta || {},
    alias: index7Qr9n68vsCMeta?.alias || [],
    redirect: index7Qr9n68vsCMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/file/[listFileId]/index.vue").then(m => m.default || m)
  },
  {
    name: resultsueHEAi2jcvMeta?.name ?? "company-companyId-lists-listId-file-listFileId-results",
    path: resultsueHEAi2jcvMeta?.path ?? ":companyId()/lists/:listId()/file/:listFileId()/results",
    meta: resultsueHEAi2jcvMeta || {},
    alias: resultsueHEAi2jcvMeta?.alias || [],
    redirect: resultsueHEAi2jcvMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/file/[listFileId]/results.vue").then(m => m.default || m)
  },
  {
    name: index8QrfIe8nypMeta?.name ?? "company-companyId-lists-listId",
    path: index8QrfIe8nypMeta?.path ?? ":companyId()/lists/:listId()",
    meta: index8QrfIe8nypMeta || {},
    alias: index8QrfIe8nypMeta?.alias || [],
    redirect: index8QrfIe8nypMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/index.vue").then(m => m.default || m)
  },
  {
    name: upload1an5GfSpItMeta?.name ?? "company-companyId-lists-listId-upload",
    path: upload1an5GfSpItMeta?.path ?? ":companyId()/lists/:listId()/upload",
    meta: upload1an5GfSpItMeta || {},
    alias: upload1an5GfSpItMeta?.alias || [],
    redirect: upload1an5GfSpItMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/upload.vue").then(m => m.default || m)
  },
  {
    name: createznPAxF8JQ6Meta?.name ?? "company-companyId-lists-create",
    path: createznPAxF8JQ6Meta?.path ?? ":companyId()/lists/create",
    meta: createznPAxF8JQ6Meta || {},
    alias: createznPAxF8JQ6Meta?.alias || [],
    redirect: createznPAxF8JQ6Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/create.vue").then(m => m.default || m)
  },
  {
    name: index59P2NL4Tr0Meta?.name ?? "company-companyId-lists",
    path: index59P2NL4Tr0Meta?.path ?? ":companyId()/lists",
    meta: index59P2NL4Tr0Meta || {},
    alias: index59P2NL4Tr0Meta?.alias || [],
    redirect: index59P2NL4Tr0Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/index.vue").then(m => m.default || m)
  },
  {
    path: locationsdX62eiPJe7Meta?.path ?? ":companyId()/locations",
    children: [
  {
    name: indexX1ltMRQcdHMeta?.name ?? "company-companyId-locations-locationId",
    path: indexX1ltMRQcdHMeta?.path ?? ":locationId()",
    meta: indexX1ltMRQcdHMeta || {},
    alias: indexX1ltMRQcdHMeta?.alias || [],
    redirect: indexX1ltMRQcdHMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations/[locationId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOx32TfYHKgMeta?.name ?? "company-companyId-locations",
    path: indexOx32TfYHKgMeta?.path ?? "",
    meta: indexOx32TfYHKgMeta || {},
    alias: indexOx32TfYHKgMeta?.alias || [],
    redirect: indexOx32TfYHKgMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations/index.vue").then(m => m.default || m)
  }
],
    name: locationsdX62eiPJe7Meta?.name ?? undefined,
    meta: locationsdX62eiPJe7Meta || {},
    alias: locationsdX62eiPJe7Meta?.alias || [],
    redirect: locationsdX62eiPJe7Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations.vue").then(m => m.default || m)
  },
  {
    name: settingsdRo2NC7kNsMeta?.name ?? "company-companyId-settings",
    path: settingsdRo2NC7kNsMeta?.path ?? ":companyId()/settings",
    meta: settingsdRo2NC7kNsMeta || {},
    alias: settingsdRo2NC7kNsMeta?.alias || [],
    redirect: settingsdRo2NC7kNsMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/settings.vue").then(m => m.default || m)
  },
  {
    path: users6lNBzLfcs7Meta?.path ?? ":companyId()/users",
    children: [
  {
    name: indexXZXmnhuw4BMeta?.name ?? "company-companyId-users",
    path: indexXZXmnhuw4BMeta?.path ?? "",
    meta: indexXZXmnhuw4BMeta || {},
    alias: indexXZXmnhuw4BMeta?.alias || [],
    redirect: indexXZXmnhuw4BMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/users/index.vue").then(m => m.default || m)
  }
],
    name: users6lNBzLfcs7Meta?.name ?? undefined,
    meta: users6lNBzLfcs7Meta || {},
    alias: users6lNBzLfcs7Meta?.alias || [],
    redirect: users6lNBzLfcs7Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/[companyId]/users.vue").then(m => m.default || m)
  },
  {
    name: createp82qzStFCrMeta?.name ?? "company-create",
    path: createp82qzStFCrMeta?.path ?? "create",
    meta: createp82qzStFCrMeta || {},
    alias: createp82qzStFCrMeta?.alias || [],
    redirect: createp82qzStFCrMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/create.vue").then(m => m.default || m)
  },
  {
    name: indexBfE1GwOSc0Meta?.name ?? "company",
    path: indexBfE1GwOSc0Meta?.path ?? "",
    meta: indexBfE1GwOSc0Meta || {},
    alias: indexBfE1GwOSc0Meta?.alias || [],
    redirect: indexBfE1GwOSc0Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company/index.vue").then(m => m.default || m)
  }
],
    name: companyfPzteudkO0Meta?.name ?? undefined,
    meta: companyfPzteudkO0Meta || {},
    alias: companyfPzteudkO0Meta?.alias || [],
    redirect: companyfPzteudkO0Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/company.vue").then(m => m.default || m)
  },
  {
    name: faqcn7zQLbSYPMeta?.name ?? "faq",
    path: faqcn7zQLbSYPMeta?.path ?? "/faq",
    meta: faqcn7zQLbSYPMeta || {},
    alias: faqcn7zQLbSYPMeta?.alias || [],
    redirect: faqcn7zQLbSYPMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: forgotPassword9jeN0Pkm0OMeta?.name ?? "forgotPassword",
    path: forgotPassword9jeN0Pkm0OMeta?.path ?? "/forgotPassword",
    meta: forgotPassword9jeN0Pkm0OMeta || {},
    alias: forgotPassword9jeN0Pkm0OMeta?.alias || [],
    redirect: forgotPassword9jeN0Pkm0OMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/forgotPassword.vue").then(m => m.default || m)
  },
  {
    name: index0Hquz1dJouMeta?.name ?? "index",
    path: index0Hquz1dJouMeta?.path ?? "/",
    meta: index0Hquz1dJouMeta || {},
    alias: index0Hquz1dJouMeta?.alias || [],
    redirect: index0Hquz1dJouMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: listsuN0P9iGNEfMeta?.name ?? "lists",
    path: listsuN0P9iGNEfMeta?.path ?? "/lists",
    meta: listsuN0P9iGNEfMeta || {},
    alias: listsuN0P9iGNEfMeta?.alias || [],
    redirect: listsuN0P9iGNEfMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/lists.vue").then(m => m.default || m)
  },
  {
    name: loginfvw1wfu1dxMeta?.name ?? "login",
    path: loginfvw1wfu1dxMeta?.path ?? "/login",
    meta: loginfvw1wfu1dxMeta || {},
    alias: loginfvw1wfu1dxMeta?.alias || [],
    redirect: loginfvw1wfu1dxMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: privacy9gE8S3bhm9Meta?.name ?? "privacy",
    path: privacy9gE8S3bhm9Meta?.path ?? "/privacy",
    meta: privacy9gE8S3bhm9Meta || {},
    alias: privacy9gE8S3bhm9Meta?.alias || [],
    redirect: privacy9gE8S3bhm9Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexUDO8h0nBf1Meta?.name ?? "products-productid",
    path: indexUDO8h0nBf1Meta?.path ?? "/products/:productid()",
    meta: indexUDO8h0nBf1Meta || {},
    alias: indexUDO8h0nBf1Meta?.alias || [],
    redirect: indexUDO8h0nBf1Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/[productid]/index.vue").then(m => m.default || m)
  },
  {
    name: categoriesrqkauglh6AMeta?.name ?? "products-categories",
    path: categoriesrqkauglh6AMeta?.path ?? "/products/categories",
    meta: categoriesrqkauglh6AMeta || {},
    alias: categoriesrqkauglh6AMeta?.alias || [],
    redirect: categoriesrqkauglh6AMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/categories.vue").then(m => m.default || m)
  },
  {
    name: envelopesNuY0Qxw1ZtMeta?.name ?? "products-envelopes",
    path: envelopesNuY0Qxw1ZtMeta?.path ?? "/products/envelopes",
    meta: envelopesNuY0Qxw1ZtMeta || {},
    alias: envelopesNuY0Qxw1ZtMeta?.alias || [],
    redirect: envelopesNuY0Qxw1ZtMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/envelopes.vue").then(m => m.default || m)
  },
  {
    name: indexaiWibLfWB6Meta?.name ?? "products",
    path: indexaiWibLfWB6Meta?.path ?? "/products",
    meta: indexaiWibLfWB6Meta || {},
    alias: indexaiWibLfWB6Meta?.alias || [],
    redirect: indexaiWibLfWB6Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: letterShop5hJthmNit9Meta?.name ?? "products-letterShop",
    path: letterShop5hJthmNit9Meta?.path ?? "/products/letterShop",
    meta: letterShop5hJthmNit9Meta || {},
    alias: letterShop5hJthmNit9Meta?.alias || [],
    redirect: letterShop5hJthmNit9Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/letterShop.vue").then(m => m.default || m)
  },
  {
    name: paperStockfgj7FqE54nMeta?.name ?? "products-paperStock",
    path: paperStockfgj7FqE54nMeta?.path ?? "/products/paperStock",
    meta: paperStockfgj7FqE54nMeta || {},
    alias: paperStockfgj7FqE54nMeta?.alias || [],
    redirect: paperStockfgj7FqE54nMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/products/paperStock.vue").then(m => m.default || m)
  },
  {
    name: registerdJCzS6FEmqMeta?.name ?? "register",
    path: registerdJCzS6FEmqMeta?.path ?? "/register",
    meta: registerdJCzS6FEmqMeta || {},
    alias: registerdJCzS6FEmqMeta?.alias || [],
    redirect: registerdJCzS6FEmqMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/register.vue").then(m => m.default || m)
  },
  {
    name: indexw15Li3rtW0Meta?.name ?? "reports",
    path: indexw15Li3rtW0Meta?.path ?? "/reports",
    meta: indexw15Li3rtW0Meta || {},
    alias: indexw15Li3rtW0Meta?.alias || [],
    redirect: indexw15Li3rtW0Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: brandsqMCGf8xgM7Meta?.name ?? "settings-brands",
    path: brandsqMCGf8xgM7Meta?.path ?? "/settings/brands",
    meta: brandsqMCGf8xgM7Meta || {},
    alias: brandsqMCGf8xgM7Meta?.alias || [],
    redirect: brandsqMCGf8xgM7Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/brands.vue").then(m => m.default || m)
  },
  {
    name: index7HR0D3RDLJMeta?.name ?? "settings-campaignType-campaignTypeId",
    path: index7HR0D3RDLJMeta?.path ?? "/settings/campaignType/:campaignTypeId()",
    meta: index7HR0D3RDLJMeta || {},
    alias: index7HR0D3RDLJMeta?.alias || [],
    redirect: index7HR0D3RDLJMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/campaignType/[campaignTypeId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSXrLcE2nsDMeta?.name ?? "settings-campaignType",
    path: indexSXrLcE2nsDMeta?.path ?? "/settings/campaignType",
    meta: indexSXrLcE2nsDMeta || {},
    alias: indexSXrLcE2nsDMeta?.alias || [],
    redirect: indexSXrLcE2nsDMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/campaignType/index.vue").then(m => m.default || m)
  },
  {
    name: creditszDjQwKC2xLMeta?.name ?? "settings-credits",
    path: creditszDjQwKC2xLMeta?.path ?? "/settings/credits",
    meta: creditszDjQwKC2xLMeta || {},
    alias: creditszDjQwKC2xLMeta?.alias || [],
    redirect: creditszDjQwKC2xLMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/credits.vue").then(m => m.default || m)
  },
  {
    name: discountsJPI9q6xqgeMeta?.name ?? "settings-discounts",
    path: discountsJPI9q6xqgeMeta?.path ?? "/settings/discounts",
    meta: discountsJPI9q6xqgeMeta || {},
    alias: discountsJPI9q6xqgeMeta?.alias || [],
    redirect: discountsJPI9q6xqgeMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/discounts.vue").then(m => m.default || m)
  },
  {
    name: indexfqklpF4pmvMeta?.name ?? "settings",
    path: indexfqklpF4pmvMeta?.path ?? "/settings",
    meta: indexfqklpF4pmvMeta || {},
    alias: indexfqklpF4pmvMeta?.alias || [],
    redirect: indexfqklpF4pmvMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: jobIntegrationsFFZQxfnRnoMeta?.name ?? "settings-jobIntegrations",
    path: jobIntegrationsFFZQxfnRnoMeta?.path ?? "/settings/jobIntegrations",
    meta: jobIntegrationsFFZQxfnRnoMeta || {},
    alias: jobIntegrationsFFZQxfnRnoMeta?.alias || [],
    redirect: jobIntegrationsFFZQxfnRnoMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/settings/jobIntegrations.vue").then(m => m.default || m)
  },
  {
    name: supportnkX95JW7KVMeta?.name ?? "support",
    path: supportnkX95JW7KVMeta?.path ?? "/support",
    meta: supportnkX95JW7KVMeta || {},
    alias: supportnkX95JW7KVMeta?.alias || [],
    redirect: supportnkX95JW7KVMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/support.vue").then(m => m.default || m)
  },
  {
    name: terms8p0pIoEthMMeta?.name ?? "terms",
    path: terms8p0pIoEthMMeta?.path ?? "/terms",
    meta: terms8p0pIoEthMMeta || {},
    alias: terms8p0pIoEthMMeta?.alias || [],
    redirect: terms8p0pIoEthMMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/terms.vue").then(m => m.default || m)
  },
  {
    path: usersSFwlHZ9KFeMeta?.path ?? "/users",
    children: [
  {
    name: clientWLVRjtXMVnMeta?.name ?? "users-client",
    path: clientWLVRjtXMVnMeta?.path ?? "client",
    meta: clientWLVRjtXMVnMeta || {},
    alias: clientWLVRjtXMVnMeta?.alias || [],
    redirect: clientWLVRjtXMVnMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users/client.vue").then(m => m.default || m)
  },
  {
    name: indexnFwzREju40Meta?.name ?? "users",
    path: indexnFwzREju40Meta?.path ?? "",
    meta: indexnFwzREju40Meta || {},
    alias: indexnFwzREju40Meta?.alias || [],
    redirect: indexnFwzREju40Meta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: internalJhCltyAbwhMeta?.name ?? "users-internal",
    path: internalJhCltyAbwhMeta?.path ?? "internal",
    meta: internalJhCltyAbwhMeta || {},
    alias: internalJhCltyAbwhMeta?.alias || [],
    redirect: internalJhCltyAbwhMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users/internal.vue").then(m => m.default || m)
  }
],
    name: usersSFwlHZ9KFeMeta?.name ?? undefined,
    meta: usersSFwlHZ9KFeMeta || {},
    alias: usersSFwlHZ9KFeMeta?.alias || [],
    redirect: usersSFwlHZ9KFeMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/users.vue").then(m => m.default || m)
  },
  {
    name: version2DVDRheQ4yMeta?.name ?? "version",
    path: version2DVDRheQ4yMeta?.path ?? "/version",
    meta: version2DVDRheQ4yMeta || {},
    alias: version2DVDRheQ4yMeta?.alias || [],
    redirect: version2DVDRheQ4yMeta?.redirect || undefined,
    component: () => import("/home/ghrun/actions-runner/_work/portal2-frontend/portal2-frontend/pages/version.vue").then(m => m.default || m)
  }
]