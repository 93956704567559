/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** @example {"DisplayName":"Asset Name","FolderName":"myAssets","CompanyId":2,"ContentType":"image/jpg","OriginalFileName":"image.jpg"} */
export interface AssetCreationRequest {
  /** The display name of the asset */
  DisplayName?: string
  /** The original file name of the asset */
  OriginalFileName?: string
  /** The files content type */
  ContentType?: string
  /** The folder name */
  FolderName?: string | null
  /**
   * The identifier of the associated company. Null if global
   * @format int32
   */
  CompanyId?: number | null
  /**
   * The asset type identifier
   * @format int32
   */
  AssetTypeId?: number | null
  /**
   * The size of the file (for display purposes)
   * @format int32
   */
  FileSize?: number | null
  /**
   * The width of the file, if applicable
   * @format int32
   */
  Width?: number | null
  /**
   * The height of the file, if applicable
   * @format int32
   */
  Height?: number | null
}

/** @example {"AssetId":1,"CompanyId":2,"S3Key":"/folder/assetName.pdf","AssetTypeId":3,"CreatedDate":"0001-01-01T00:00:00.0000000","ModifiedDate":"0001-01-01T00:00:00.0000000","CreatedBy":4,"ModifiedBy":5} */
export interface AssetResponse {
  /**
   * The asset identifier
   * @format int32
   */
  AssetId?: number
  /**
   * The company id of the associated company. Null if global.
   * @format int32
   */
  CompanyId?: number | null
  /** The AWS S3 key of the object */
  S3Key?: string | null
  /** The asset's display name */
  DisplayName?: string | null
  /**
   * Id of the asset's type
   * @format int32
   */
  AssetTypeId?: number
  /**
   * The date the asset was created
   * @format date-time
   */
  CreatedDate?: string
  /**
   * The date the asset was modified
   * @format date-time
   */
  ModifiedDate?: string
  /**
   * Id of the creating user
   * @format int32
   */
  CreatedBy?: number
  /**
   * Id of the last modifying user
   * @format int32
   */
  ModifiedBy?: number
  /** Name of creating user */
  CreatedByName?: string | null
  /** Name of modifying user */
  ModifiedByName?: string | null
  /**
   * The file's size
   * @format int32
   */
  FileSize?: number | null
  /** The folder name */
  Folder?: string | null
  /**
   * The width of the asset, if applicable
   * @format int32
   */
  Width?: number | null
  /**
   * The height of the asset, if applicable
   * @format int32
   */
  Height?: number | null
  /** The file type */
  FileType?: string | null
}

/** @example {"NewName":"New Asset Name","NewFolder":"PossibleNewFolder","AssetId":4} */
export interface AssetUpdateRequest {
  /** The name to update the asset to */
  NewName?: string | null
  /**
   * The asset's AssetId
   * @format int32
   */
  AssetId?: number
  /** New folder name, if applicable */
  NewFolder?: string | null
}

/** @example {"BinderyTypeDisplayName":"A","BinderyTypeProductionName":"A"} */
export interface BinderyTypeCreationRequest {
  /** The name of the Bindery Type */
  BinderyTypeDisplayName: string
  /** The production name of the Binder yType */
  BinderyTypeProductionName: string
}

export interface BinderyTypeRecord {
  /** @format int32 */
  BinderyTypeId?: number
  /** @minLength 1 */
  BinderyTypeDisplayName: string
  /** @minLength 1 */
  BinderyTypeProductionName: string
  /** @format date-time */
  CreateTimeStamp: string
  /** @format date-time */
  UpdateTimeStamp?: string | null
}

/** @example {"BinderyTypeId":1,"BinderyTypeDisplayName":"B","BinderyTypeProductionName":"B","CreateTimeStamp":"2024-10-07T17:42:14.3492546+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.3492592+00:00"} */
export interface BinderyTypeResponse {
  /**
   * The Bindery Type identifier
   * @format int32
   */
  BinderyTypeId: number
  /** The name of the Bindery Type */
  BinderyTypeDisplayName: string
  /** The production name of the Bindery Type */
  BinderyTypeProductionName: string
  /**
   * Date the Bindery Type was created
   * @format date-time
   */
  CreateTimeStamp?: string
  /**
   * Date the Bindery Type was updated
   * @format date-time
   */
  UpdateTimeStamp?: string | null
}

/** @example {"BinderyTypeDisplayName":"A","BinderyTypeProductionName":"A"} */
export interface BinderyTypeUpdateRequest {
  /**
   * The Bindery Type Identifier
   * @format int32
   */
  BinderyTypeId: number
  /** The name of the Bindery Type */
  BinderyTypeDisplayName: string
  /** The production name of the Binder yType */
  BinderyTypeProductionName: string
}

export interface BrandRecord {
  /** @format int32 */
  BrandId?: number
  BrandName?: string | null
  Active?: boolean
  Default?: boolean
}

/** @example {"BrandId":1,"BrandName":"Cheetos","Active":true,"Default":false} */
export interface BrandResponse {
  /** @format int32 */
  BrandId: number
  BrandName: string | null
  Active: boolean
  Default: boolean
  CompanyData?: CompanyBrandResponse[] | null
}

export interface CCTemplateId {
  TemplateId?: string | null
  CCTemplateName?: string | null
}

/** @example {"CampaignName":"AAA Repairs","CampaignTypeId":1} */
export interface CampaignCreationRequest {
  /** The name of the Campaign */
  CampaignName?: string
  /**
   * The CampaignType identifier
   * @format int32
   */
  CampaignTypeId?: number | null
}

export interface CampaignEventDateType {
  /** @format int32 */
  CampaignEventDateTypeId?: number
  CampaignEventDateTypeDescription?: string | null
}

/** @example {"CampaignListConfigId":1,"ListColumnName":"First","MappedColumnName":"First Name","StaticValue":"100","AssetId":10} */
export interface CampaignListColumnMappingConfigCreationRequest {
  /**
   * The Campaign List Id
   * @format int32
   */
  CampaignListConfigId: number
  /** The DB Column display name */
  ListColumnName: string | null
  /** The DB Column display name */
  MappedColumnName: string | null
  /** The DB Static Value if no List Column is Mapped */
  StaticValue?: string | null
  /**
   * The DB Asset for Variable Images
   * @format int32
   */
  AssetId?: number | null
}

/** @example {"CampaignListColumnMappingConfigId":1,"CampaignListConfigId":1,"ListColumnName":"Column Name","MappedColumnName":"Mapped Column Name","StaticValue":"value","AssetId":6} */
export interface CampaignListColumnMappingConfigRecordResponse {
  /**
   * The Campaign List Column Mapping Config identifier
   * @format int32
   */
  CampaignListColumnMappingConfigId?: number
  /**
   * The Campaign List Config identifier
   * @format int32
   */
  CampaignListConfigId?: number
  /**
   * The Campaign List Column identifier
   * @format int32
   */
  CampaignListColumnId?: number
  /** The Mapped Column Name */
  MappedColumnName?: string | null
  /** The List Column Name */
  ListColumnName?: string | null
  /** The Static Value */
  StaticValue?: string | null
  /**
   * The Asset S3 Key
   * @format int32
   */
  AssetId?: number | null
}

export interface CampaignListColumnMappingConfigResponse {
  List?: ListResponse
  ListColumnHeader?: ListColumnResponse[] | null
  ListColumnMapping?: ListColumnMappingConfig[] | null
  TemplateVariables?: VariableData[] | null
  PreviewUrls?: string[] | null
}

/** @example {"CampaignListConfigId":1,"FileColumnName":"Test","DataFormatOptionId":2} */
export interface CampaignListColumnMappingConfigUpdateRequest {
  /**
   * The Campaign List Config Id
   * @format int32
   */
  CampaignListConfigId: number
  /** The Campaign List Column Mapping Id */
  FileColumnName: string | null
  /**
   * The Data Formatting Option for the Column
   * @format int32
   */
  DataFormatOptionId: number
}

/** @example {"ListId":2,"CampaignId":2} */
export interface CampaignListConfigCreationRequest {
  /**
   * The List identifier
   * @format int32
   */
  ListId?: number
  /**
   * The Campaign identifier
   * @format int32
   */
  CampaignId?: number
}

/** @example {"CampaignListConfigId":5,"CampaignId":1,"ListId":1} */
export interface CampaignListConfigResponse {
  /**
   * The campaign list config identifier
   * @format int32
   */
  CampaignListConfigId?: number
  /**
   * The campaign identifier
   * @format int32
   */
  CampaignId: number
  /**
   * The list identifier
   * @format int32
   */
  ListId: number
  /** The campaign list config active status */
  Active?: boolean
  /**
   * The create date
   * @format date-time
   */
  CreateTimeStamp?: string
  /**
   * The date the campaign list was updated
   * @format date-time
   */
  UpdateTimeStamp?: string
}

/** @example {"CampaignListConfigId":5,"ListValidationOptionId":1,"ProcessingFailureOption":"Do Not Mail"} */
export interface CampaignListValidationOptionCreateRequest {
  /**
   * The Campaign List identifier
   * @format int32
   */
  CampaignListConfigId?: number
  /**
   * The List validation option identifier
   * @format int32
   */
  ListValidationOptionId?: number
  /** The Processing Failure Chosen */
  ProcessingFailureOption?: string
}

/** @example {"CampaignId":5,"ProductionProductId":"11ea1838-796d-4bed-97dc-d0982a6bb270"} */
export interface CampaignProductConfigCreateRequest {
  /**
   * The Campaign identifier
   * @format int32
   */
  CampaignId?: number
  /**
   * The Product identifier
   * @format uuid
   */
  ProductionProductId?: string
}

/** @example {"CampaignId":5,"ProductionProductId":"11ea1838-796d-4bed-97dc-d0982a6bb270","ProductId":"11ea1838-796d-4bed-97dc-d0982a6bb270","EnvelopeId":2,"CCTemplateId":"Template123","CCVariantId":2,"CCDesignId":"Design456","CCTemplateOptions":"Option789","Active":true} */
export interface CampaignProductConfigUpdateRequest {
  /**
   * The Campaign identifier
   * @format int32
   */
  CampaignId?: number
  /**
   * The Production Product identifier
   * @format uuid
   */
  ProductionProductId?: string
  /**
   * The Product identifier
   * @format uuid
   */
  ProductId?: string | null
  /**
   * The Envelope identifier
   * @format int32
   */
  EnvelopeId?: number | null
  /** The CC Template identifier */
  CCTemplateId?: string | null
  /**
   * The CC Variant identifier
   * @format int32
   */
  CCVariantId?: number | null
  /** The CC Design identifier */
  CCDesignId?: string | null
  /** The CC Template Options */
  CCTemplateOptions?: string | null
  /** The Active status */
  Active?: boolean
}

export interface CampaignProductTemplate {
  /** @format uuid */
  ProductionProductId: string
  /** @format uuid */
  ProductId: string
  ProductDisplayName: string | null
  TemplateDisplayName: string | null
  Description: string | null
  TemplateId: string | null
  /** @format int32 */
  VariantId: number
  PreviewUrl?: string | null
  StockImageUrl?: string | null
  PreviewUrls?: string[] | null
  /** @format int32 */
  ProductCategoryId?: number
  Envelope?: EnvelopeRecord
  /** @format double */
  BaseCostPerPiece?: number
  Options?: Options[] | null
}

export interface CampaignProductTemplateResponse {
  CampaignProductTemplates?: CampaignProductTemplate[] | null
  TemplateEnvelopes?: EnvelopeRecord[] | null
}

export interface CampaignProductionTimelineConfigResponse {
  /** @format int32 */
  CampaignId?: number
  /** @format int32 */
  ProductionTimelineId?: number | null
  /** @format int32 */
  MailingClassId?: number | null
  /** @format date-time */
  InHomeDate?: string | null
  Active?: boolean
  Campaign?: CampaignResponse
  ProductionTimeline?: ProductionTimelineResponse
  MailingClass?: MailingClassResponse
}

export interface CampaignRecord {
  /** @format int32 */
  CampaignId?: number
  CampaignName?: string | null
  /** @format int32 */
  CompanyId?: number
  /** @format int32 */
  CampaignTypeId?: number
  /** @format int32 */
  CampaignStatusId?: number
  Active?: boolean
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format int32 */
  CreatedBy?: number
  /** @format uuid */
  CustomersCanvasCampaignId?: string
  Company?: CompanyRecord
  CampaignType?: CampaignTypeRecord
  CampaignStatus?: CampaignStatusRecord
}

/** @example {"CampaignId":5,"CampaignName":"First Campaign","CompanyId":1,"CampaignTypeId":1,"CampaignStatusId":1,"Active":true,"CreateTimeStamp":"2024-10-07T17:42:14.1167202+00:00","CreatedBy":1} */
export interface CampaignResponse {
  /**
   * The campaign identifier
   * @format int32
   */
  CampaignId?: number
  /** The name of the campaign */
  CampaignName: string
  /**
   * The company identifier
   * @format int32
   */
  CompanyId?: number
  /**
   * The CampaignType identifier
   * @format int32
   */
  CampaignTypeId?: number
  /**
   * The CampaignStatus identifier
   * @format int32
   */
  CampaignStatusId?: number
  /** The Active status identifier */
  Active?: boolean
  /**
   * The Datetime the campaign was Created
   * @format date-time
   */
  CreateTimeStamp?: string
  /**
   * The User Id who created the Campaign
   * @format int32
   */
  CreatedBy?: number
  CampaignStatus?: CampaignStatusRecord
  CampaignType?: CampaignTypeRecord
}

export interface CampaignStatusRecord {
  /** @format int32 */
  CampaignStatusId?: number
  CampaignStatusDescription?: string | null
}

/** @example {"CampaignId":5,"CampaignName":"First Campaign","CompanyId":1,"CampaignTypeId":1,"CampaignProductId":10,"CampaignTemplateId":"7a824g35","CampaignTemplateVariantId":1000,"CampaignListId":15,"CampaignListConfigId":20,"CcCampaignId":"0bd5a486-d53e-4fcd-afdb-31f86ad609e3","CcTemplateId":"6602900be80ed81337133eee","CcDesignId":"66c626d5ca10b5a3e7e689bf"} */
export interface CampaignStoreResponse {
  /**
   * The campaign identifier
   * @format int32
   */
  CampaignId?: number
  /** The name of the campaign */
  CampaignName: string
  /**
   * The company identifier
   * @format int32
   */
  CompanyId?: number
  /**
   * The Campaign Type identifier
   * @format int32
   */
  CampaignTypeId?: number
  /**
   * The Campaign Product identifier
   * @format uuid
   */
  CampaignProductId?: string
  /**
   * The Campaign Template identifier
   * @format uuid
   */
  CampaignTemplateId?: string
  /**
   * The Campaign Template Variant identifier
   * @format int32
   */
  CampaignTemplateVariantId?: number
  /**
   * The Campaign List identifier
   * @format int32
   */
  CampaignListId?: number
  /**
   * The Campaign List Config identifier
   * @format int32
   */
  CampaignListConfigId?: number
  /**
   * The Campaign List Mapping Config identifier
   * @format int32
   */
  CampaignListColumnMappingConfigId?: number
  /**
   * The Campaign List Validation Config identifier
   * @format int32
   */
  CampaignListValidationOptionId?: number
  /** The CC Template identifier */
  CcTemplateId?: string
  /** The CC Design identifier */
  CcDesignId?: string | null
  /**
   * The CC Campaign identifier
   * @format uuid
   */
  CcCampaignId?: string
  /** The ListColumn mapping config */
  ListColumnMappingConfigs?: CampaignListColumnMappingConfigRecordResponse[] | null
}

export interface CampaignTypeEventDataTypesResponse {
  /** @format int32 */
  CampaignEventDateTypeId?: number
  CampaignEventDateTypeDescription?: string | null
}

export interface CampaignTypeRecord {
  /** @format int32 */
  CampaignTypeId?: number
  CampaignTypeName?: string | null
  /** @format int32 */
  EventDateTypeId?: number
  EventDateType?: CampaignEventDateType
  RecurringPayment?: boolean
  Active?: boolean
  /** @format date-time */
  CreateTimeStamp?: string
}

/** @example {"CampaignTypeId":1,"CampaignTypeName":"BirthdayMail","EventDateTypeId":1,"RecurringPayment":true,"Active":true,"CreateTimeStamp":"2024-10-07T17:42:14.1698138+00:00"} */
export interface CampaignTypeResponse {
  /**
   * The CampaignType identifier
   * @format int32
   */
  CampaignTypeId: number
  /** The CampaignType name */
  CampaignTypeName: string
  /**
   * The Event Date Type
   * @format int32
   */
  EventDateTypeId: number
  /** Recurring Payment Indicator */
  RecurringPayment: boolean
  /** The Active status of the CampaignType */
  Active: boolean
  /**
   * The CampaignType Create Date
   * @format date-time
   */
  CreateTimeStamp?: string
  /** List of Response Tracking Products */
  ResponseTracking?: ProductResponse[] | null
  /** List of Product Add Ons */
  ProductAddOns?: ProductResponse[] | null
  /** List of Production Products */
  ProductionProducts?: ProductionProductResponse[] | null
}

/** @example {"CampaignTypeId":1,"CampaignTypeName":"BirthdayMail","EventDateTypeId":1,"RecurringPayment":true,"Active":true} */
export interface CampaignTypeUpdateRequest {
  /**
   * The CampaignType identifier
   * @format int32
   */
  CampaignTypeId: number
  /** The CampaignType name */
  CampaignTypeName: string
  /**
   * The Event Date Type
   * @format int32
   */
  EventDateTypeId: number
  /** Recurring Payment Indicator */
  RecurringPayment: boolean
  /** The Active status of the CampaignType */
  Active: boolean
  /** List of Allowed Response Tracking Products for Campaign Type */
  ResponseTrackingIds?: string[]
  /** List of Allowed Product Add Ons for Campaign Type */
  ProductAddOnIds?: string[]
  /** List of Allowed Production Products for Campaign Type */
  ProductionProductIds?: string[]
}

export interface CampaignUpdateRequest {
  /** The name of the Campaign */
  CampaignName?: string
  /** The CampaignStatus */
  Status?: string | null
  /**
   * The days before the recipient's campaign event to send
   * @format int32
   */
  SendDaysBeforeEvent?: number | null
  /**
   * The mailing class identifier
   * @format int32
   */
  MailingClassId?: number | null
  /**
   * The production timeline identifier
   * @format int32
   */
  ProductionTimelineId?: number | null
}

export interface CompanyBrand {
  /** @format int32 */
  CompanyBrandId?: number
  /** @format int32 */
  CompanyId?: number
  /** @format int32 */
  BrandId?: number
}

/** @example {"CompanyName":"AAA Repairs","MailworksId":"AAA Repairs","CompanyId":"AAA Repairs"} */
export interface CompanyBrandResponse {
  CompanyName?: string | null
  MailworksId?: string | null
  /** @format int32 */
  CompanyId?: number
}

/** @example {"CompanyName":"AAA Repairs","MailworksId":"AB10","AccountNumber":"AB129018","SicCodeId":1,"NonProfitCode":1,"CRID":1,"Address1":"123 Main St.","Address2":"Suite 201","City":"Beverly Hills","State":"CA","ZipCode":"90210","LocationCode":"RHTG0001","LocationAlias":"This serves no purpose","ReturnAddress1":"123 Main St.","ReturnAddress2":"Suite 201","ReturnAddressCity":"Beverly Hills","ReturnAddressState":"CA","ReturnAddressZipCode":"90210","PhoneNumber":"1-555-555-1234","Website":"https://themailworks.com/","TwitterHandle":"@themailworks","InstagramHandle":"@the-mailworks"} */
export interface CompanyCreationRequest {
  /** The name of the Company */
  CompanyName: string
  /** The company mailworks identifier */
  MailworksId?: string | null
  /** The company mailworks account number */
  AccountNumber?: string | null
  /**
   * The company's standard industrial classification id
   * @format int32
   */
  SicCodeId?: number
  /**
   * The company's standard Program Manager id
   * @format int32
   */
  AccountManagerId?: number | null
  /**
   * The company's Program Manager id
   * @format int32
   */
  ProgramManagerId?: number | null
  /** The company's Crid Number */
  CRID?: string | null
  /** The company's Non Profit Code */
  NonProfitCode?: string | null
  /** The location's address part 1 */
  Address1: string
  /** The location's address part 2 */
  Address2?: string | null
  /** The location's city */
  City: string
  /** The location's state */
  State: string
  /** The location's zip */
  ZipCode: string
  /** The location's location code */
  LocationCode?: string | null
  /** The location's location alias */
  LocationAlias?: string | null
  /** The location's return address address part 1 */
  ReturnAddress1: string
  /** The location's return address address part 2 */
  ReturnAddress2?: string | null
  /** The location's return address city */
  ReturnAddressCity: string
  /** The location's return address state */
  ReturnAddressState: string
  /** The location's return address zip */
  ReturnAddressZipCode: string
  /** The location's phone number */
  PhoneNumber?: string | null
  /** The location's website url */
  Website?: string | null
  /** The location's Twitter handle */
  TwitterHandle?: string | null
  /** The location's Instagram handle */
  InstagramHandle?: string | null
  /**
   * The company's Tier ID
   * @format int32
   */
  CompanyTierId: number
  /**
   * The company's Service Terms
   * @format int32
   */
  ServiceTerms?: number
  /**
   * The company's Postage Terms
   * @format int32
   */
  PostageTerms?: number
}

/** @example {"CompanyTierId":1,"TierLevel":1,"TierName":"Enterprise"} */
export interface CompanyCustomerTierResponse {
  /**
   * The id for the tier
   * @format int32
   */
  CompanyTierId: number
  /**
   * The tier level
   * @format int32
   */
  TierLevel: number
  /** The tier name */
  TierName: string
}

export interface CompanyLocation {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  CompanyId?: number
  LocationName?: string | null
  Address1?: string | null
  Address2?: string | null
  City?: string | null
  State?: string | null
  ZipCode?: string | null
  LocationCode?: string | null
  LocationAlias?: string | null
  ReturnAddress1?: string | null
  ReturnAddress2?: string | null
  ReturnAddressCity?: string | null
  ReturnAddressState?: string | null
  ReturnAddressZipCode?: string | null
  PhoneNumber?: string | null
  Website?: string | null
  TwitterHandle?: string | null
  InstagramHandle?: string | null
  Primary?: boolean
}

/** @example {"FirstName":"John","LastName":"Doe","Title":"Manager","Credentials":"MBA","Gender":"Male","Tagline":"Some tag","Email":"john.doe@example.com","OfficePhoneNumber":"518-555-1234","CellPhoneNumber":"518-555-5678","BioText":"Bio information","PhotoUrl":"https://example.com/photo.jpg","SecondaryUrl":"https://example.com/secondary","CompanyId":1,"LocationId":1} */
export interface CompanyLocationContactCreationRequest {
  /** The location contact's first name */
  FirstName: string
  /** The location contact's last name */
  LastName: string
  /** The location contact's title */
  Title?: string | null
  /** The location contact's credentials */
  Credentials?: string | null
  /** The location contact's gender */
  Gender?: string | null
  /** The location contact's tagline */
  Tagline?: string | null
  /** The location contact's email */
  Email?: string | null
  /** The location contact's office phone number */
  OfficePhoneNumber?: string | null
  /** The location contact's cell phone number */
  CellPhoneNumber?: string | null
  /** The location contact's bio text */
  BioText?: string | null
  /** The location contact's photo URL */
  PhotoUrl?: string | null
  /** The location contact's secondary URL */
  SecondaryUrl?: string | null
}

/** @example {"FirstName":"John","LastName":"Doe","Title":"Manager","Credentials":"MBA","Gender":"Male","Tagline":"Some tag","Email":"john.doe@example.com","OfficePhoneNumber":"518-555-1234","CellPhoneNumber":"518-555-5678","BioText":"Bio information","PhotoUrl":"https://example.com/photo.jpg","SecondaryUrl":"https://example.com/secondary","Id":1,"CompanyId":1,"LocationId":1,"Active":true} */
export interface CompanyLocationContactResponse {
  /**
   * The location identifier
   * @format int32
   */
  Id: number
  /**
   * The location contact's company
   * @format int32
   */
  CompanyId: number
  /**
   * The location contact's location
   * @format int32
   */
  LocationId: number
  /** The location contact's first name */
  FirstName: string
  /** The location contact's last name */
  LastName: string
  /** The location contact's title */
  Title?: string | null
  /** The location contact's credentials */
  Credentials?: string | null
  /** The location contact's gender */
  Gender?: string | null
  /** The location contact's tagline */
  Tagline?: string | null
  /** The location contact's email */
  Email?: string | null
  /** The location contact's office phone number */
  OfficePhoneNumber?: string | null
  /** The location contact's cell phone number */
  CellPhoneNumber?: string | null
  /** The location contact's bio text */
  BioText?: string | null
  /** The location contact's photo URL */
  PhotoUrl?: string | null
  /** The location contact's secondary URL */
  SecondaryUrl?: string | null
  /** Indicates whether the location contact is active */
  Active?: boolean
}

/** @example {"FirstName":"John","LastName":"Doe","Title":"Manager","Credentials":"MBA","Gender":"Male","Tagline":"Some tag","Email":"john.doe@example.com","OfficePhoneNumber":"518-555-1234","CellPhoneNumber":"518-555-5678","BioText":"Bio information","PhotoUrl":"https://example.com/photo.jpg","SecondaryUrl":"https://example.com/secondary","Active":true} */
export interface CompanyLocationContactUpdateRequest {
  /** The location contact's first name */
  FirstName: string
  /** The location contact's last name */
  LastName: string
  /** The location contact's title */
  Title?: string | null
  /** The location contact's credentials */
  Credentials?: string | null
  /** The location contact's gender */
  Gender?: string | null
  /** The location contact's tagline */
  Tagline?: string | null
  /** The location contact's email */
  Email?: string | null
  /** The location contact's office phone number */
  OfficePhoneNumber?: string | null
  /** The location contact's cell phone number */
  CellPhoneNumber?: string | null
  /** The location contact's bio text */
  BioText?: string | null
  /** The location contact's photo URL */
  PhotoUrl?: string | null
  /** The location contact's secondary URL */
  SecondaryUrl?: string | null
  /** The location contact's secondary URL */
  Active?: boolean
}

/** @example {"LocationName":"HQ","Address1":"123 Main St.","Address2":"Suite 201","City":"Beverly Hills","State":"CA","ZipCode":"90210","LocationCode":"RHTG0001","LocationAlias":"This serves no purpose","ReturnAddress1":"123 Main St.","ReturnAddress2":"Suite 201","ReturnAddressCity":"Beverly Hills","ReturnAddressState":"CA","ReturnAddressZipCode":"90210","PhoneNumber":"1-555-555-1234","Website":"https://themailworks.com/","TwitterHandle":"@themailworks","InstagramHandle":"@the-mailworks","Primary":true} */
export interface CompanyLocationCreationRequest {
  /** The name of the Location */
  LocationName: string
  /** The location's address part 1 */
  Address1: string
  /** The location's address part 2 */
  Address2?: string | null
  /** The location's city */
  City: string
  /** The location's state */
  State: string
  /** The location's zip */
  ZipCode: string
  /** The location's location code */
  LocationCode?: string | null
  /** The location's location alias */
  LocationAlias?: string | null
  /** The location's return address address part 1 */
  ReturnAddress1: string
  /** The location's return address address part 2 */
  ReturnAddress2?: string | null
  /** The location's return address city */
  ReturnAddressCity: string
  /** The location's return address state */
  ReturnAddressState: string
  /** The location's return address zip */
  ReturnAddressZipCode: string
  /** The location's phone number */
  PhoneNumber?: string | null
  /** The location's website url */
  Website?: string | null
  /** The location's Twitter handle */
  TwitterHandle?: string | null
  /** The location's Instagram handle */
  InstagramHandle?: string | null
  /** Indication if this is the primary location for the Company */
  Primary?: boolean
}

/** @example {"Id":5,"CompanyId":5,"LocationName":"HQ","Address1":"123 Main St.","Address2":"Suite 201","City":"Beverly Hills","State":"CA","ZipCode":"90210","LocationCode":"RHTG0001","LocationAlias":"This serves no purpose","ReturnAddress1":"123 Main St.","ReturnAddress2":"Suite 201","ReturnAddressCity":"Beverly Hills","ReturnAddressState":"CA","ReturnAddressZipCode":"90210","PhoneNumber":"1-555-555-1234","Website":"https://themailworks.com/","TwitterHandle":"@themailworks","InstagramHandle":"@the-mailworks","Primary":true} */
export interface CompanyLocationResponse {
  /**
   * The location identifier
   * @format int32
   */
  Id?: number
  /**
   * The id of the company
   * @format int32
   */
  CompanyId: number
  /** The address of the companyId for location record */
  LocationName?: string
  /** The location's address part 1 */
  Address1?: string
  /** The location's address part 2 */
  Address2?: string | null
  /** The location's city */
  City?: string
  /** The location's state */
  State?: string
  /** The location's zip */
  ZipCode?: string
  /** The location's location code */
  LocationCode?: string | null
  /** The location's alias */
  LocationAlias?: string | null
  /** The location's return address address part 1 */
  ReturnAddress1?: string
  /** The location's return address address part 2 */
  ReturnAddress2?: string | null
  /** The location's return address city */
  ReturnAddressCity?: string
  /** The location's return address state */
  ReturnAddressState?: string
  /** The location's return address zip */
  ReturnAddressZipCode?: string
  /** The location's phone number */
  PhoneNumber?: string | null
  /** The location's website url */
  Website?: string | null
  /** The location's Twitter handle */
  TwitterHandle?: string | null
  /** The location's Instagram handle */
  InstagramHandle?: string | null
  /** Indication if this is the primary location for the Company */
  Primary?: boolean
}

/** @example {"FirstName":"John","LastName":"Smith","Address1":"123 Main St.","Address2":"Suite 201","City":"Beverly Hills","State":"CA","Zip":"90210","CompanyId":1,"LocationId":1,"Active":true} */
export interface CompanyLocationSeedCreationRequest {
  /**
   * The location seed's company identifier
   * @format int32
   */
  CompanyId: number
  /**
   * The location seed's location identifier
   * @format int32
   */
  LocationId: number
  /** The name of the Company */
  FirstName: string
  /** The company mailworks identifier */
  LastName: string
  /** The location's address part 1 */
  Address1: string
  /** The location's address part 2 */
  Address2?: string | null
  /** The location's city */
  City: string
  /** The location's state */
  State: string
  /** The location's zip */
  Zip: string
  /** The location's location code */
  Active?: boolean
}

/** @example {"FirstName":"John","LastName":"Smith","Address1":"123 Main St.","Address2":"Suite 201","City":"Beverly Hills","State":"CA","Zip":"90210","CompanyLocationSeedId":1,"CompanyId":1,"LocationId":1,"Active":true} */
export interface CompanyLocationSeedResponse {
  /**
   * The location identifier
   * @format int32
   */
  CompanyLocationSeedId: number
  /**
   * The location seed's company
   * @format int32
   */
  CompanyId: number
  /**
   * The location seed's location
   * @format int32
   */
  LocationId: number
  /** The location seed's first name */
  FirstName: string
  /** The location seed's last name */
  LastName: string
  /** The location seed's address 1 */
  Address1: string
  /** The location seed's address 2 */
  Address2?: string | null
  /** The location seed's city */
  City: string
  /** The location seed's state */
  State: string
  /** The location seed's zip code */
  Zip: string
  /** Indicates whether the location seed is active */
  Active?: boolean
}

/** @example {"FirstName":"John","LastName":"Smith","Address1":"123 Main St.","Address2":"Suite 201","City":"Beverly Hills","State":"CA","Zip":"90210","CompanyLocationSeedId":1,"CompanyId":1,"LocationId":1,"Active":true} */
export interface CompanyLocationSeedUpdateRequest {
  /**
   * The location seed's identifier
   * @format int32
   */
  CompanyLocationSeedId: number
  /**
   * The location seed's company identifier
   * @format int32
   */
  CompanyId: number
  /**
   * The location seed's location identifier
   * @format int32
   */
  LocationId: number
  /** The name of the Company */
  FirstName: string
  /** The company mailworks identifier */
  LastName: string
  /** The location's address part 1 */
  Address1: string
  /** The location's address part 2 */
  Address2?: string | null
  /** The location's city */
  City: string
  /** The location's state */
  State: string
  /** The location's zip */
  Zip: string
  /** The location's location code */
  Active?: boolean
}

/** @example {"LocationName":"HQ","Address1":"123 Main St.","Address2":"Suite 201","City":"Beverly Hills","State":"CA","ZipCode":"90210","LocationCode":"RHTG0001","LocationAlias":"This serves no purpose","ReturnAddress1":"123 Main St.","ReturnAddress2":"Suite 201","ReturnAddressCity":"Beverly Hills","ReturnAddressState":"CA","ReturnAddressZipCode":"90210","PhoneNumber":"1-555-555-1234","Website":"https://themailworks.com/","TwitterHandle":"@themailworks","InstagramHandle":"@the-mailworks","Primary":true} */
export interface CompanyLocationUpdateRequest {
  /** The name of the Location */
  LocationName: string
  /** The location's address part 1 */
  Address1: string
  /** The location's address part 2 */
  Address2?: string | null
  /** The location's city */
  City: string
  /** The location's state */
  State: string
  /** The location's zip */
  ZipCode: string
  /** The location's location code */
  LocationCode?: string | null
  /** The location's alias */
  LocationAlias?: string | null
  /** The location's return address address part 1 */
  ReturnAddress1: string
  /** The location's return address address part 2 */
  ReturnAddress2?: string | null
  /** The location's return address city */
  ReturnAddressCity: string
  /** The location's return address state */
  ReturnAddressState: string
  /** The location's return address zip */
  ReturnAddressZipCode: string
  /** The location's phone number */
  PhoneNumber?: string | null
  /** The location's website url */
  Website?: string | null
  /** The location's Twitter handle */
  TwitterHandle?: string | null
  /** The location's Instagram handle */
  InstagramHandle?: string | null
  /** Indication if this is the primary location for the Company */
  Primary: boolean
}

/** @example {"Id":1,"FirstName":"Nick","LastName":"Seney","Title":"Program Manager"} */
export interface CompanyManagerUserResponse {
  /**
   * The user's identifier
   * @format int32
   */
  Id: number
  /** The users' title */
  Title?: string | null
  /** The user's first name */
  FirstName?: string | null
  /** The user's last name */
  LastName?: string | null
}

export interface CompanyRecord {
  /** @format int32 */
  Id?: number
  CompanyName?: string | null
  MailworksId?: string | null
  AccountNumber?: string | null
  /** @format int32 */
  AccountManagerId?: number | null
  /** @format int32 */
  ProgramManagerId?: number | null
  /** @format int32 */
  SicCodeId?: number
  /** @format int32 */
  CompanyTierId?: number
  /** @format int32 */
  ServiceTerms?: number
  /** @format int32 */
  PostageTerms?: number
  CompanyTier?: CompanyTierRecord
  SicCode?: SicCodeRecord
  Locations?: CompanyLocation[] | null
}

/** @example {"Id":5,"CompanyName":"Acme, Inc","NonProfitCode":1,"CRID":1,"MailworksId":"AB12","TotalCampaigns":29,"TotalUsers":3,"Brands":[]} */
export interface CompanyResponse {
  /**
   * The company identifier
   * @format int32
   */
  Id?: number
  /** The name of the company */
  CompanyName: string
  /** The company's Crid Number */
  CRID?: string | null
  /** The company's Non Profit Code */
  NonProfitCode?: string | null
  /**
   * The User ID that verified the CRID Number
   * @format int32
   */
  VerifiedByUserId?: number | null
  /** The company identifier */
  MailworksId?: string | null
  /** The company corporate account number */
  AccountNumber?: string | null
  /**
   * The company's standard industrial classification id
   * @format int32
   */
  SicCodeId?: number
  /**
   * The company's standard Program Manager id
   * @format int32
   */
  AccountManagerId?: number | null
  /**
   * The company's Program Manager id
   * @format int32
   */
  ProgramManagerId?: number | null
  /**
   * The number of active campaigns for the company
   * @format int32
   */
  TotalCampaigns?: number
  /**
   * The number of active users for the company
   * @format int32
   */
  TotalUsers?: number
  SicCode?: SicCodeResponse
  PrimaryLocation?: CompanyLocationResponse
  /**
   * The company's tier id
   * @format int32
   */
  CompanyTierId?: number
  CompanyTier?: CompanyCustomerTierResponse
  AccountManager?: CompanyManagerUserResponse
  ProgramManager?: CompanyManagerUserResponse
  /** The brands that the company is associated with */
  Brands?: number[] | null
  /**
   * The company's Service Terms
   * @format int32
   */
  ServiceTerms?: number | null
  /**
   * The company's Postage Terms
   * @format int32
   */
  PostageTerms?: number | null
}

export interface CompanyRoleRecord {
  /** @format int32 */
  Id?: number
  Role?: string | null
  Label?: string | null
  Active?: boolean
}

/** @example {"Id":5,"Role":"admin","Label":"Admin"} */
export interface CompanyRoleResponse {
  /**
   * The company role identifier
   * @format int32
   */
  Id?: number
  /** The name of the role */
  Role?: string
  /** The label for the role */
  Label?: string
}

export interface CompanyTierRecord {
  /** @format int32 */
  CompanyTierId?: number
  TierName?: string | null
  /** @format int32 */
  TierLevel?: number
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  VerifiedTimeStamp?: string | null
  /** @format date-time */
  UpdateTimeStamp?: string | null
}

/** @example {"CompanyName":"AAA Repairs","AccountNumber":"AB129018","SicCodeId":1,"NonProfitCode":1,"CRID":1} */
export interface CompanyUpdateRequest {
  /** The name of the Company */
  CompanyName: string
  /** The name of the company corporate account number */
  AccountNumber?: string | null
  /** The company's Crid Number */
  CRID?: string | null
  /** The company's Non Profit Code */
  NonProfitCode?: string | null
  /**
   * The company's Tier ID
   * @format int32
   */
  CompanyTierId: number
  /**
   * The company's standard Program Manager id
   * @format int32
   */
  AccountManagerId?: number | null
  /**
   * The company's Program Manager id
   * @format int32
   */
  ProgramManagerId?: number | null
  /**
   * The User ID that verified the CRID Number
   * @format int32
   */
  VerifiedByUserId?: number | null
  /** The ID's of the user's Associated brands */
  CompanyBrands?: number[]
  /**
   * The company's standard industrial classification id
   * @format int32
   */
  SicCodeId?: number
  /**
   * The company's Service Terms
   * @format int32
   */
  ServiceTerms?: number
  /**
   * The company's Postage Terms
   * @format int32
   */
  PostageTerms?: number
}

/** @example {"Email":"joey@themailworks.com","FirstName":"Joey","LastName":"Giraffe","Title":"Mascot","RoleId":29} */
export interface CompanyUserCreationRequest {
  /** The email of the user */
  Email: string
  /** The user's first name; only used when a new system user is created */
  FirstName?: string | null
  /** The user's last name; only used when a new system user is created */
  LastName?: string | null
  /**
   * The role identifier
   * @format int32
   */
  RoleId: number
  /** The user's title */
  Title?: string | null
}

export interface CompanyUserRecord {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  CompanyId?: number
  /** @format int32 */
  UserId?: number
  /** @format int32 */
  RoleId?: number
  Title?: string | null
  Active?: boolean
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string
  Company?: CompanyRecord
  Role?: CompanyRoleRecord
}

/** @example {"Id":5,"UserId":82,"Email":"joey@themailworks.com","FirstName":"Joey","LastName":"Giraffe","PhoneNumber":"555-555-5555","Title":"Mascot","RoleId":29,"Role":"Admin","Status":"Active","CompanyId":14,"CompanyName":"Acme, Inc"} */
export interface CompanyUserResponse {
  /**
   * The company user identifier
   * @format int32
   */
  Id?: number
  /**
   * The user identifier
   * @format int32
   */
  UserId?: number
  /** The email of the user */
  Email?: string
  /** The first name of the user */
  FirstName?: string | null
  /** The last name of the user */
  LastName?: string | null
  /** The phone number of the user */
  PhoneNumber?: string | null
  /** The user's title within the company */
  Title?: string | null
  /**
   * The role identifier
   * @format int32
   */
  RoleId?: number
  /** The role of the user */
  Role?: string
  /** The status of the user */
  Status?: string
  /**
   * The company identifier
   * @format int32
   */
  CompanyId?: number
  /** The company name of the user */
  CompanyName: string
}

/** @example {"RoleId":29,"Status":"Active","Title":"Mascot"} */
export interface CompanyUserUpdateRequest {
  /**
   * The role identifier
   * @format int32
   */
  RoleId: number
  /** The status of the user */
  Status: string
  /** The user's title */
  Title?: string | null
}

/** @example {"CoverageDisplayName":"Simplex","CoverageProductionName":"Color Single-Sided","CoverageCostPerPiece":0.0623} */
export interface CoverageCreationRequest {
  /** The name of the Coverage */
  CoverageDisplayName: string
  /** The production name of the Coverage */
  CoverageProductionName: string
  /**
   * The cost of the Coverage
   * @format double
   */
  CoverageCostPerPiece: number
}

export interface CoverageRecord {
  /** @format int32 */
  CoverageId?: number
  /** @minLength 1 */
  CoverageDisplayName: string
  /** @minLength 1 */
  CoverageProductionName: string
  /** @format double */
  CoverageCostPerPiece: number
  /** @format date-time */
  CreateTimeStamp: string
  /** @format date-time */
  UpdateTimeStamp?: string | null
}

/** @example {"CoverageId":1,"CoverageDisplayName":"Simplex","CoverageProductionName":"Color Single-Sided","CoverageCostPerPiece":0.0623,"CreateTimeStamp":"2024-10-07T17:42:14.3462985+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.3463001+00:00"} */
export interface CoverageResponse {
  /**
   * The Coverage identifier
   * @format int32
   */
  CoverageId: number
  /** The name of the Coverage */
  CoverageDisplayName: string
  /** The production name of the Coverage */
  CoverageProductionName: string
  /**
   * The cost of the Coverage
   * @format double
   */
  CoverageCostPerPiece: number
  /**
   * Date the Coverage was created
   * @format date-time
   */
  CreateTimeStamp?: string
  /**
   * Date the Coverage was updated
   * @format date-time
   */
  UpdateTimeStamp?: string | null
}

/** @example {"CoverageId":1,"CoverageDisplayName":"Simplex","CoverageProductionName":"Color Single-Sided","CoverageCostPerPiece":0.0623} */
export interface CoverageUpdateRequest {
  /**
   * The Coverage Identifier
   * @format int32
   */
  CoverageId: number
  /** The name of the Coverage */
  CoverageDisplayName: string
  /** The production name of the Coverage */
  CoverageProductionName: string
  /**
   * The Cost of the Coverage
   * @format double
   */
  CoverageCostPerPiece: number
}

export interface CreateBrandRequest {
  /** The name for the new brand */
  BrandName?: string
  /** The brands current status */
  Active?: boolean
  /** Companies (if any) to add to the brand */
  Companies?: number[]
}

/** @example {"CompanyId":4,"FolderName":"FolderName"} */
export interface CreateFolderRequest {
  /**
   * The upload url for the asset
   * @format int32
   */
  CompanyId?: number
  /** The name of the folder */
  FolderName?: string | null
}

/** @example {"ListId":5,"FirstName":"Bob","LastName":"Smith","Address1":"1 State St","Address2":"Apt 2","City":"New York","State":"NY","Zip":"11111"} */
export interface CreateListRecipientRequest {
  /**
   * The list identifier
   * @format int32
   */
  ListId: number
  /** The FirstName of the  list Recipient */
  FirstName: string
  /** The LastName of the  list Recipient */
  LastName: string
  /** The Full Name of the  list Recipient */
  FullName: string
  /** The Company Name of the  list Recipient */
  CompanyName: string
  /** The Address1 of the  list Recipient */
  Address1: string
  /** The Address2 of the  list Recipient */
  Address2: string
  /** The City of the  list Recipient */
  City?: string
  /** The State of the  list Recipient */
  State: string
  /** The Zip of the  list Recipient */
  Zip: string
}

export interface CreditReason {
  /** @format int32 */
  CreditReasonId?: number
  CreditReasonName?: string | null
  ActiveInd?: boolean
  /** @format date-time */
  CreateTimestamp?: string
  /** @format date-time */
  UpdateTimestamp?: string
}

/** @example {"CreditDisplayName":"Direct Mail Rules!","CreditCodeName":"D1R3CTM41L","MinQty":42,"Amount":3.5,"ExpirationDate":"2042-01-01T00:00:00.0000000","ReasonId":2,"CompanyId":42,"ActiveInd":true} */
export interface CreditRequest {
  /** @format int32 */
  CompanyCreditId?: number | null
  /** The name of the credit */
  CreditDisplayName?: string
  /** The code name used to access the credit */
  CreditCodeName?: string
  /**
   * The minimum quantity in order to use the credit
   * @format int32
   */
  MinQty?: number
  /**
   * The amount to be credited
   * @format double
   */
  Amount?: number
  /**
   * The expiration date of the credit
   * @format date-time
   */
  ExpirationDate?: string | null
  /**
   * The reason id for the credit
   * @format int32
   */
  ReasonId?: number
  /**
   * The associated company's id
   * @format int32
   */
  CompanyId?: number
  /** The status of the credit */
  ActiveInd?: boolean
  /**
   * the associated campaign's id
   * @format int32
   */
  CampaignId?: number
}

/** @example {"CreditDisplayName":"Direct Mail Rules!","CreditCodeName":"D1R3CTM41L","MinQty":42,"Amount":3.5,"ExpirationDate":"2042-01-01T00:00:00.0000000","ReasonId":2,"ReasonName":"Because we like you!","CompanyId":42,"CompanyName":"Your Company","ActiveInd":true,"CreatedDate":"2024-01-01T00:00:00.0000000+00:00","UpdatedDate":"2024-01-01T00:00:00.0000000+00:00"} */
export interface CreditResponse {
  /**
   * The Id of the credit
   * @format int32
   */
  CompanyCreditId?: number
  /** The name of the credit */
  CreditDisplayName?: string
  /** The code name used to access the credit */
  CreditCodeName?: string
  /**
   * The minimum quantity in order to use the credit
   * @format int32
   */
  MinQty?: number
  /**
   * The amount to be credited
   * @format double
   */
  Amount?: number
  /**
   * The expiration date of the credit
   * @format date-time
   */
  ExpirationDate?: string | null
  /**
   * The reason id for the credit
   * @format int32
   */
  ReasonId?: number
  /** The description of the credit reason */
  ReasonName?: string
  /**
   * The associated company's id
   * @format int32
   */
  CompanyId?: number
  /** The associated company's name */
  CompanyName?: string
  /**
   * The associated campaign's id
   * @format int32
   */
  CampaignId?: number
  /** The associated campaign's name */
  CampaignName?: string
  /** The status of the credit */
  ActiveInd?: boolean
  /**
   * The date the credit was created
   * @format date-time
   */
  CreatedDate?: string
  /**
   * The date the credit was updated (if applicable)
   * @format date-time
   */
  UpdatedDate?: string | null
}

/** @example {"DataFormatOptionId":1,"DataFormatTypeId":1,"OptionDisplayName":"Text","OptionValue":"Text"} */
export interface DataFormatOptionResponse {
  /**
   * Data Format Option Id
   * @format int32
   */
  DataFormatOptionId: number
  /**
   * Data Format Type Id
   * @format int32
   */
  DataFormatTypeId: number
  /** The Option Display Name */
  OptionDisplayName: string | null
  /** The Option Value */
  OptionValue: string | null
}

/** @example {"DataFormatTypeId":1,"Description":"Text"} */
export interface DataFormatTypeResponse {
  /**
   * Data Format Type Id
   * @format int32
   */
  DataFormatTypeId: number
  /** The Data Formatting Option Description */
  Description: string | null
}

/** @example {"Id":1,"ListId":1,"ListFileId":null,"ListValidationGroupId":1,"UserSelection":"","CreateTimeStamp":"2024-10-07T17:42:14.1412353+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.1412372+00:00","ListValidationGroup":{"Name":"Address Standardization","AllowMultipleSelection":false,"Order":1,"Active":true,"Required":true,"ListValidationOptions":[{"Name":"USPS Address Standardization","Description":"Validates, standardizes, and enriches recipient address data.","Order":1,"Active":true,"Required":true,"ProcessingFailureOptions":"Do Not Mail,Mail Them Anyways"}]}} */
export interface DefaultListValidationGroupRecord {
  /**
   * The ID of the List Validation Group
   * @format int32
   */
  Id?: number
  /** The Name of the List Validation Group */
  Name?: string
  /** Ability to allow multiple */
  AllowMultipleSelection?: boolean
  /**
   * Order of this group
   * @format int32
   */
  Order?: number
  /** If this group is active */
  Active?: boolean
  /** If this group is required */
  Required?: boolean
  /** The associated list of options for this group */
  ListValidationOptions?: DefaultListValidationOptionsRecord[]
}

/** @example {"Name":"USPS Address Standardization","Description":"Validates, standardizes, and enriches recipient address data.","Order":1,"Active":true,"Required":true,"ProcessingFailureOptions":"Do Not Mail,Mail Them Anyways"} */
export interface DefaultListValidationOptionsRecord {
  /**
   * The ID of the Option
   * @format int32
   */
  Id?: number
  /** The Name for this List Validation Option */
  Name?: string
  /** The Description for this List Validation Option */
  Description?: string
  /**
   * Order of this option
   * @format int32
   */
  Order?: number
  /** If this option is active */
  Active?: boolean
  /** If this option is required */
  Required?: boolean
  /** Processing failure options */
  ProcessingFailureOptions?: string
}

/** @example {"ListId":5,"SortId":"b3fb0bc9-478e-442b-9d4f-5fe4e2c774f9"} */
export interface DeleteListRecipientRequest {
  /**
   * The list identifier
   * @format int32
   */
  ListId: number
  /** The list sort identifier */
  SortId: string | null
}

export interface DiscountReason {
  /** @format int32 */
  DiscountReasonId?: number
  DiscountReasonName?: string | null
  ActiveInd?: boolean
  /** @format date-time */
  CreateTimestamp?: string
  /** @format date-time */
  UpdateTimestamp?: string
}

/** @example {"DiscountDisplayName":"Direct Mail Rules!","DiscountCodeName":"D1R3CTM41L","MinQty":42,"PercentageAmount":10,"DollarAmount":3.5,"ExpirationDate":"2042-01-01T00:00:00.0000000","ReasonId":2,"CompanyId":42,"ActiveInd":true} */
export interface DiscountRequest {
  /** @format int32 */
  DiscountId?: number | null
  /** The name of the discount */
  DiscountDisplayName?: string
  /** The code name used to access the discount */
  DiscountCodeName?: string
  /**
   * The minimum quantity in order to use the credit
   * @format int32
   */
  MinQty?: number
  /**
   * The amount to be discounted as a percentage. If using dollar amount use 0
   * @format double
   */
  PercentageAmount?: number
  /**
   * The amount to be discounted as a dollar amount. If using percentage use 0
   * @format double
   */
  DollarAmount?: number
  /**
   * The expiration date of the discount
   * @format date-time
   */
  ExpirationDate?: string | null
  /**
   * The reason id for the discount
   * @format int32
   */
  ReasonId?: number
  /**
   * The associated company's id
   * @format int32
   */
  CompanyId?: number
  /** The status of the discount */
  ActiveInd?: boolean | null
  /** Indicator for non-company based discounts */
  AllCompanyInd?: boolean
}

/** @example {"DiscountDisplayName":"Direct Mail Rules!","DiscountCodeName":"D1R3CTM41L","MinQty":42,"PercentageAmount":10,"DollarAmount":3.5,"ExpirationDate":"2042-01-01T00:00:00.0000000","ReasonId":2,"ReasonName":"Because we like you!","CompanyId":42,"CompanyName":"Your Company","ActiveInd":true,"CreatedDate":"2024-01-01T00:00:00.0000000+00:00","UpdatedDate":"2024-01-01T00:00:00.0000000+00:00"} */
export interface DiscountResponse {
  /**
   * The ID of the discount
   * @format int32
   */
  DiscountId?: number
  /** The name of the discount */
  DiscountDisplayName?: string
  /** The code name used to access the discount */
  DiscountCodeName?: string
  /**
   * The minimum quantity in order to use the discount
   * @format int32
   */
  MinQty?: number
  /**
   * The Percentage amount to be discounted (Either this or Dollar Amount must be 0)
   * @format double
   */
  PercentageAmount?: number
  /**
   * The Dollar amount to be discounted (Either this or Dollar Amount must be 0)
   * @format double
   */
  DollarAmount?: number
  /**
   * The expiration date of the discount
   * @format date-time
   */
  ExpirationDate?: string | null
  /**
   * The reason id for the discount
   * @format int32
   */
  ReasonId?: number
  /** The description of the discount reason */
  ReasonName?: string
  /**
   * The associated company's id
   * @format int32
   */
  CompanyId?: number
  /** The associated company's name */
  CompanyName?: string
  /** The status of the discount */
  ActiveInd?: boolean
  /** The status of the discount */
  AllCompanyInd?: boolean
  /**
   * The date the discount was created
   * @format date-time
   */
  CreatedDate?: string
  /**
   * The date the discount was updated (if applicable)
   * @format date-time
   */
  UpdatedDate?: string | null
}

export interface DropDown {
  Text?: string | null
  /** @format int32 */
  Value?: number
}

/** @example {"DesignEditorUrl":"https://api.customerscanvashub.com/designeditor/","ProductPersonalizationWorkflow":"{}"} */
export interface EditorIntegrationDetails {
  /** The url for the editor */
  DesignEditorUrl?: string
  /** The workflow for the specified product */
  ProductPersonalizationWorkflow?: string
}

export enum EditorVariableBarcodeFormat {
  EAN8 = 'EAN_8',
  EAN13 = 'EAN_13',
  QR_CODE = 'QR_CODE',
}

export enum EditorVariableBarcodeSubtype {
  None = 'None',
  Phone = 'Phone',
  Url = 'Url',
}

/** @example {"Name":"FistName","Type":"Text","BarcodeFormat":"QR_CODE","BarcodeSubtype":"None"} */
export interface EditorVariableInfo {
  /** The variable name */
  Name?: string
  Type?: EditorVariableType
  BarcodeSubtype?: EditorVariableBarcodeSubtype
  BarcodeFormat?: EditorVariableBarcodeFormat
}

export enum EditorVariableType {
  Text = 'Text',
  CustomImage = 'CustomImage',
  CustomBarcode = 'CustomBarcode',
}

/** @example {"EnvelopeDisplayName":"TestEnvelope","EnvelopeTypeId":2,"EnvelopeColor":"TestEnvelope Production","EnvelopeCostPerPiece":0.5433,"EnvelopeListCostPerPiece":0.7433,"AllowEnhanced":true,"EnhancedCostPerPiece":0.4563,"Active":true} */
export interface EnvelopeCreateRequest {
  /** The name of the Envelope */
  EnvelopeDisplayName: string
  /**
   * The name of the Envelope
   * @format int32
   */
  EnvelopeTypeId: number
  /** The Envelope color */
  EnvelopeColor: string
  /**
   * The Envelope cost per piece
   * @format double
   */
  EnvelopeCostPerPiece: number
  /**
   * The Envelope cost per piece for customers
   * @format double
   */
  EnvelopeListCostPerPiece: number
  /** The allow enhancement cost status of the Envelope */
  AllowEnhanced: boolean
  /** The default status of the Envelope */
  DefaultEnvelope?: boolean
  /**
   * The Envelope enhancement cost per piece
   * @format double
   */
  EnhancedCostPerPiece: number
  /** The active status of the Envelope within an Envelope Type */
  Active: boolean
}

export interface EnvelopeRecord {
  /** @format int32 */
  EnvelopeId?: number
  /** @format int32 */
  EnvelopeTypeId?: number
  EnvelopeDisplayName?: string | null
  EnvelopeColor?: string | null
  /** @format double */
  EnvelopeCostPerPiece?: number
  /** @format double */
  EnvelopeListCostPerPiece?: number
  AllowEnhanced?: boolean
  /** @format double */
  EnhancedCostPerPiece?: number
  Active?: boolean
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string | null
}

/** @example {"EnvelopeId":5,"EnvelopeTypeId":7,"EnvelopeDisplayName":"TestEnvelope","EnvelopeColor":"Black","EnvelopeCostPerPiece":0.0123,"EnvelopeListCostPerPiece":0.05,"AllowEnhanced":true,"EnhancedCostPerPiece":0.0321,"Active":true,"CreateTimeStamp":"2024-10-07T17:42:14.3314717+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.3314750+00:00"} */
export interface EnvelopeResponse {
  /**
   * The Envelope identifier
   * @format int32
   */
  EnvelopeId: number
  /**
   * The Envelope Type identifier
   * @format int32
   */
  EnvelopeTypeId: number
  /** The name of the Envelope */
  EnvelopeDisplayName: string
  /** The Envelope color */
  EnvelopeColor: string
  /**
   * The Envelope cost per piece
   * @format double
   */
  EnvelopeCostPerPiece: number
  /**
   * The Envelope List cost per piece.Customer Facing.
   * @format double
   */
  EnvelopeListCostPerPiece?: number
  /** The allow enhancement cost status of the Envelope */
  AllowEnhanced: boolean
  /**
   * The Envelope enhancement cost per piece
   * @format double
   */
  EnhancedCostPerPiece: number
  /** The active status of the Envelope */
  Active: boolean
  /**
   * Date the Envelope was created
   * @format date-time
   */
  CreateTimeStamp: string
  /**
   * Date the Envelope was updated
   * @format date-time
   */
  UpdateTimeStamp?: string | null
}

/** @example {"EnvelopeTypeDisplayName":"TestEnvelope","Active":true} */
export interface EnvelopeTypeCreateRequest {
  /** The name of the Envelope Type */
  EnvelopeTypeDisplayName: string
  /** The Active status of the Envelope Type */
  Active?: boolean
}

export interface EnvelopeTypeRecord {
  /** @format int32 */
  EnvelopeTypeId?: number
  EnvelopeTypeDisplayName?: string | null
  Active?: boolean
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string | null
  Envelopes?: EnvelopeRecord[] | null
}

/** @example {"EnvelopeTypeId":5,"EnvelopeTypeDisplayName":"TestEnvelopeType","Active":true,"CreateTimeStamp":"2024-10-07T17:42:14.3384899+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.3384922+00:00","Envelopes":[{"EnvelopeId":1,"EnvelopeDisplayName":"TestEnvelope","EnvelopeColor":"Black","EnvelopeCostPerPiece":0.0123,"AllowEnhanced":true,"EnhancedCostPerPiece":0.0321,"Active":true,"CreateTimeStamp":"2024-10-07T17:42:14.3384947+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.3384950+00:00"}]} */
export interface EnvelopeTypeResponse {
  /**
   * The Envelope Type identifier
   * @format int32
   */
  EnvelopeTypeId: number
  /** The name of the Envelope Type */
  EnvelopeTypeDisplayName: string
  /** The active status of the Envelope Type */
  Active: boolean
  /**
   * Date the Envelope Type was created
   * @format date-time
   */
  CreateTimeStamp: string
  /**
   * Date the Envelope Type was updated
   * @format date-time
   */
  UpdateTimeStamp?: string | null
  /** List of Envelopes with this Envelope Type */
  Envelopes?: EnvelopeRecord[] | null
}

/** @example {"EnvelopeTypeId":1,"EnvelopeTypeDisplayName":"TestEnvelopeType","Active":true} */
export interface EnvelopeTypeUpdateRequest {
  /**
   * The Envelope Type Identifier
   * @format int32
   */
  EnvelopeTypeId: number
  /** The name of the Envelope Type */
  EnvelopeTypeDisplayName: string
  /** The Active status of the Envelope */
  Active: boolean
}

/** @example {"EnvelopeId":2,"EnvelopeDisplayName":"TestEnvelope","EnvelopeColor":"TestEnvelope Production","EnvelopeCostPerPiece":0.5433,"EnvelopeListCostPerPiece":0.7433,"AllowEnhanced":true,"EnhancedCostPerPiece":0.4563,"Active":true} */
export interface EnvelopeUpdateRequest {
  /**
   * The Envelope Identifier
   * @format int32
   */
  EnvelopeId: number
  /**
   * The Envelope Type Identifier
   * @format int32
   */
  EnvelopeTypeId: number
  /** The name of the Envelope */
  EnvelopeDisplayName: string
  /** The Envelope color */
  EnvelopeColor: string
  /**
   * The Envelope cost per piece
   * @format double
   */
  EnvelopeCostPerPiece: number
  /**
   * The Envelope list cost per piece
   * @format double
   */
  EnvelopeListCostPerPiece: number
  /** The allow enhancement cost status of the Envelope */
  AllowEnhanced: boolean
  /** The default status of the Envelope */
  DefaultEnvelope?: boolean
  /**
   * The Envelope enhancement cost per piece
   * @format double
   */
  EnhancedCostPerPiece: number
  /** The active status of the Envelope within an Envelope Type */
  Active: boolean
}

export interface InvoiceDropDowns {
  InvoiceTypeDropDown?: DropDown[] | null
  InvoiceLineTypeDropDown?: DropDown[] | null
}

/** @example {"InvoiceId":0,"InvoiceTypeId":1,"InvoiceFriendlyId":"MyInvoice","DueDate":"1984-08-23T00:00:00.0000000+00:00","CompanyId":2,"CampaignId":2,"OrderId":"00000000-0000-0000-0000-000000000000","Version":1,"Canceled":false,"CompanySequenceNumber":0,"InvoiceLines":[{"InvoiceLineId":0,"InvoiceLineTypeId":1,"Qty":1,"Rate":3.5,"SalesTax":1.1,"Total":4.6,"SortNumber":1,"IsManualEntryInd":true,"DateCreated":"1984-08-23T00:00:00.0000000+00:00","CreatedBy":1,"InvoiceStateTaxSettingId":1,"InvoiceSubLines":[{"InvoiceLineTypeId":1,"Qty":1,"Rate":3.5,"SalesTax":1.1,"Total":4.6,"SortNumber":1}]}]} */
export interface InvoiceGeneratorRequest {
  /** @format int32 */
  InvoiceId?: number
  /** @format int32 */
  InvoiceTypeId?: number
  InvoiceFriendlyId?: string | null
  /** @format int32 */
  CompanyId?: number
  InvoiceLines?: InvoiceLine[] | null
  IsCurrentInd?: boolean
  AvalaraTaxCalculatedInd?: boolean
  /** @format int32 */
  CampaignId?: number | null
  /** @format date-time */
  DueDate?: string | null
  /** @format uuid */
  InvoiceGuid?: string
  /** @format uuid */
  OrderId?: string | null
  /** @format int32 */
  Version?: number
  Canceled?: boolean
  /** @format int32 */
  CompanySequenceNumber?: number | null
  InvoiceTransactions?: InvoiceTransaction[] | null
}

export interface InvoiceLine {
  /** @format int32 */
  InvoiceLineId?: number
  /** @format int32 */
  InvoiceId?: number
  /** @format int32 */
  InvoiceLineTypeId?: number
  InvoiceLineType?: InvoiceLineType
  InvoiceLineName?: string | null
  /** @format int32 */
  Qty?: number
  /** @format double */
  Rate?: number
  /** @format double */
  SalesTax?: number
  /** @format double */
  Total?: number
  /** @format int32 */
  SortNumber?: number
  IsManualEntryInd?: boolean
  /** @format date-time */
  DateCreated?: string
  /** @format int32 */
  CreatedBy?: number
  /** @format int32 */
  InvoiceStateTaxSettingId?: number
  InvoiceSubLines?: InvoiceSubLine[] | null
}

export interface InvoiceLineType {
  /** @format int32 */
  InvoiceLineTypeId?: number
  /** @maxLength 50 */
  InvoiceLineTypeName?: string | null
  /** @maxLength 50 */
  AvalaraTaxCode?: string | null
}

export interface InvoicePaymentStatus {
  /** @format int32 */
  InvoicePaymentStatusId?: number
  InvoicePaymentStatusName?: string | null
}

export interface InvoiceRecord {
  /** @format int32 */
  InvoiceId?: number
  /** @format uuid */
  InvoiceGuid?: string
  InvoiceFriendlyId?: string | null
  /** @format int32 */
  InvoiceTypeId?: number
  InvoiceType?: InvoiceType
  /** @format int32 */
  InvoicePaymentStatusId?: number
  InvoicePaymentStatus?: InvoicePaymentStatus
  /** @format double */
  SalesTax?: number
  /** @format double */
  Total?: number
  /** @format int32 */
  CompanyId?: number
  Company?: CompanyRecord
  /** @format int32 */
  CampaignId?: number | null
  Campaign?: CampaignRecord
  AvalaraTaxCalculatedInd?: boolean
  /** @format date-time */
  DueDate?: string
  /** @format int32 */
  InvoiceTermId?: number
  InvoiceTerm?: InvoiceTerm
  /** @format int32 */
  CompanySequenceNumber?: number
  IsCurrentInd?: boolean
  /** @format date-time */
  DateCreated?: string
  /** @format int32 */
  CreatedByUserId?: number
  User?: UserRecord
  /** @format uuid */
  OrderId?: string | null
  Order?: OrderRecord
  /** @format int32 */
  Version?: number
  Canceled?: boolean
  InvoiceLines?: InvoiceLine[] | null
  InvoiceTransactions?: InvoiceTransaction[] | null
}

/** @example {"InvoiceId":0,"InvoiceGuid":"5987e700-3e75-421c-9a38-1e937cd71f1c","InvoiceFriendlyId":"MyInvoice","InvoiceTypeId":10000,"InvoiceTypeName":"Services","InvoicePaymentStatusId":1,"InvoicePaymentStatusName":"Unpaid","CompanyId":1,"CompanyName":"MyCompany","CampaignId":1,"CampaignName":"MyCampaign","Total":3.5,"DateProcessed":"1984-08-23T00:00:00.0000000+00:00","DueDate":"1984-08-23T00:00:00.0000000+00:00","OrderId":"00000000-0000-0000-0000-000000000000","Canceled":false,"Version":1,"InvoiceLines":[{"InvoiceLineId":0,"InvoiceLineTypeId":1,"Qty":1,"Rate":3.5,"SalesTax":1.1,"Total":4.6,"SortNumber":1,"IsManualEntryInd":true,"DateCreated":"1984-08-23T00:00:00.0000000+00:00","CreatedBy":1,"InvoiceStateTaxSettingId":1,"InvoiceSubLines":[{"InvoiceLineTypeId":1,"Qty":1,"Rate":3.5,"SalesTax":1.1,"Total":4.6,"SortNumber":1}]}]} */
export interface InvoiceResponse {
  /** @format int32 */
  InvoiceId?: number
  /** @format uuid */
  InvoiceGuid?: string
  InvoiceFriendlyId?: string | null
  /** @format int32 */
  InvoiceTypeId?: number
  InvoiceTypeName?: string | null
  /** @format int32 */
  InvoicePaymentStatusId?: number
  InvoicePaymentStatusName?: string | null
  /** @format int32 */
  CompanyId?: number
  CompanyName?: string | null
  /** @format int32 */
  CompanySequenceNumber?: number | null
  /** @format int32 */
  CampaignId?: number | null
  CampaignName?: string | null
  /** @format double */
  Total?: number
  /** @format double */
  SalesTax?: number
  AvalaraTaxCalculatedInd?: boolean
  /** @format date-time */
  DateProcessed?: string
  /** @format date-time */
  DueDate?: string
  InvoiceLines?: InvoiceLine[] | null
  InvoiceTransactions?: InvoiceTransaction[] | null
  /** @format uuid */
  OrderId?: string | null
  Order?: OrderRecord
  /** @format int32 */
  Version?: number
  Canceled?: boolean
}

export interface InvoiceSubLine {
  /** @format int32 */
  InvoiceSubLineId?: number
  /** @format int32 */
  InvoiceLineId?: number
  /** @format int32 */
  InvoiceLineTypeId?: number
  InvoiceLineType?: InvoiceLineType
  InvoiceLineName?: string | null
  /** @format int32 */
  Qty?: number
  /** @format double */
  Rate?: number
  /** @format double */
  SalesTax?: number
  /** @format double */
  Total?: number
  /** @format int32 */
  SortNumber?: number
}

export interface InvoiceTableRecord {
  /** @format int32 */
  CampaignId?: number | null
  /** @format uuid */
  OrderId?: string | null
  /** @format int32 */
  CompanyId?: number
  CompanyName?: string | null
  ServiceInvoice?: InvoiceRecord
  PostageInvoice?: InvoiceRecord
}

export interface InvoiceTerm {
  /** @format int32 */
  InvoiceTermId?: number
  /** @maxLength 50 */
  InvoiceTermName?: string | null
  /** @format int32 */
  DaysFromDate?: number
  /** @maxLength 50 */
  DueDateDbField?: string | null
}

export interface InvoiceTransaction {
  /** @format int32 */
  InvoiceTransactionId?: number
  /** @format int32 */
  InvoiceId?: number
  /** @format double */
  Total?: number
  /** @format int32 */
  InvoiceTransactionTypeId?: number
  InvoiceTransactionType?: InvoiceTransactionType
  ConfirmationId?: string | null
  /** @format date-time */
  DateCreated?: string
  /** @maxLength 15 */
  CreditCardType?: string | null
  /** @format int32 */
  DiscountId?: number
  /** @format int32 */
  CreditId?: number
  PaymentResponse?: string | null
  /** @format int32 */
  PaymentExportedStatusId?: number
  AutoPayInd?: boolean
  Memo?: string | null
}

export interface InvoiceTransactionRequest {
  /** @format int32 */
  InvoiceId?: number
  /** @format double */
  Total?: number
  /** @format int32 */
  InvoiceTransactionTypeId?: number
  ConfirmationId?: string | null
  /** @format date-time */
  DateCreated?: string
  CreditCardType?: string | null
  /** @format int32 */
  DiscountId?: number
  /** @format int32 */
  CreditId?: number
  PaymentResponse?: string | null
  /** @format int32 */
  PaymentExportedStatusId?: number
  AutoPayInd?: boolean
  Memo?: string | null
}

export interface InvoiceTransactionType {
  /** @format int32 */
  InvoiceTransactionTypeId?: number
  /** @maxLength 50 */
  InvoiceTransactionTypeName?: string | null
}

export interface InvoiceType {
  /** @format int32 */
  InvoiceTypeId?: number
  InvoiceTypeName?: string | null
  InvoiceHTMLLocation?: string | null
  RunTaxInd?: boolean
  IsPostageInd?: boolean
}

/** @example {"LetterShopDisplayName":"Insert","LetterShopCostPerPiece":0.0123} */
export interface LetterShopCreationRequest {
  /** The name of the Letter Shop */
  LetterShopDisplayName: string
  /**
   * The Letter Shop Cost per Piece
   * @format double
   */
  LetterShopCostPerPiece: number | null
}

export interface LetterShopRecord {
  /** @format int32 */
  LetterShopId?: number
  /**
   * @minLength 1
   * @maxLength 100
   */
  LetterShopDisplayName: string
  /** @format double */
  LetterShopCostPerPiece: number
  /** @format date-time */
  CreateTimestamp: string
  /** @format date-time */
  UpdateTimestamp?: string | null
}

/** @example {"LetterShopId":1,"LetterShopDisplayName":"Insert","LetterShopCostPerPiece":0.0123,"CreateTimestamp":"2024-10-07T17:42:14.3555779+00:00","UpdateTimestamp":"2024-10-07T17:42:14.3555799+00:00"} */
export interface LetterShopResponse {
  /**
   * The Letter Shop identifier
   * @format int32
   */
  LetterShopId: number
  /** The Letter Shop Display Name */
  LetterShopDisplayName: string
  /**
   * The Letter Shop Production Name
   * @format double
   */
  LetterShopCostPerPiece: number
  /**
   * The Letter Shop Production Name
   * @format date-time
   */
  CreateTimestamp?: string
  /**
   * The Time that the Letter Shop Production Name
   * @format date-time
   */
  UpdateTimestamp?: string
}

/** @example {"LetterShopId":1,"LetterShopDisplayName":"Insert","LetterShopCostPerPiece":0.0123} */
export interface LetterShopUpdateRequest {
  /**
   * The Letter Shop identifier
   * @format int32
   */
  LetterShopId: number
  /** The name of the Letter Shop */
  LetterShopDisplayName: string
  /**
   * The Letter Shop Cost per Piece
   * @format double
   */
  LetterShopCostPerPiece: number | null
}

/** @example {"ListId":1,"FileColumnName":"First Name"} */
export interface ListColumnCreationRequest {
  /**
   * The List Id
   * @format int32
   */
  ListId?: number
  /** Name of the List */
  FileColumnName: string
}

export interface ListColumnMappingConfig {
  /** @format int32 */
  ListColumnMappingConfigId: number
  /** @minLength 1 */
  ListColumnName: string
  MappedColumnName?: string | null
  /** @format int32 */
  ListId: number
  List?: ListRecord
}

/** @example {"ListId":1,"ListColumnName":"First","MappedColumnName":"First Name"} */
export interface ListColumnMappingConfigCreationRequest {
  /**
   * The List Id
   * @format int32
   */
  ListId: number
  /** The DB Column display name */
  ListColumnName: string | null
  /** The DB Column display name */
  MappedColumnName: string | null
}

/** @example {"ListColumnName":"First"} */
export interface ListColumnMappingConfigResponse {
  /** The DB Column display name */
  ListColumnName: string | null
}

/** @example {"FileColumnName":"First Name"} */
export interface ListColumnResponse {
  /** Name of the List */
  FileColumnName: string
}

/** @example {"Name":"Bob's list o lists"} */
export interface ListCreationRequest {
  /** Name of the List */
  Name: string
}

export interface ListFile {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  ListId?: number
  /** @format int32 */
  CompanyId?: number
  FileName?: string | null
  /** @format int32 */
  FileRowCount?: number | null
  /** @format int32 */
  FileStatusId?: number
  /** @format int32 */
  ErrorCount?: number | null
  /** @format int32 */
  ConversionCount?: number | null
  /** @format int32 */
  DedupeCount?: number | null
  /** @format int32 */
  AddressUpdateCount?: number | null
  /** @format int32 */
  InvalidAddressCount?: number | null
  Active?: boolean
  IsUploadComplete?: boolean
  ListValidationSetting?: ListValidationSetting
  Recipients?: ListRecipient[] | null
  List?: ListRecord
  FileStatus?: ListFileStatus
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string
  /** @format double */
  ProcessingTimeTotalMinutes?: number | null
}

/** @example {"FileName":"example_file.csv","FileRowCount":1200,"Active":true} */
export interface ListFileCreationRequest {
  /** Name of the File */
  FileName: string
  /**
   * Number of rows in the File
   * @format int32
   */
  FileRowCount: number
  /** If is active */
  Active: boolean
}

/** @example {"ListFileId":1,"ListId":1,"CompanyId":1,"FileStatus":3,"FileName":"my_cool_file.csv","FileRowCount":38,"ErrorCount":1,"ConversionCount":10,"DedupeCount":3,"InvalidAddressCount":2,"AddressUpdateCount":5,"Active":true,"IsUploadComplete":true,"UpdateTimeStamp":"2024-10-07T17:42:14.2699505+00:00","CreateTimeStamp":"2024-10-07T17:42:14.2699564+00:00"} */
export interface ListFileResponse {
  /**
   * The List File identifier
   * @format int32
   */
  ListFileId?: number
  /**
   * The id of the associated list
   * @format int32
   */
  ListId?: number
  /**
   * The id of the associated company
   * @format int32
   */
  CompanyId?: number
  /** The file status of the associated list file */
  FileStatus?: string
  /** The File name */
  FileName?: string
  /**
   * The File Row Count
   * @format int32
   */
  FileRowCount?: number | null
  /**
   * The Error Count
   * @format int32
   */
  ErrorCount?: number | null
  /**
   * The Conversions Count
   * @format int32
   */
  ConversionCount?: number | null
  /**
   * The Dedupe Count
   * @format int32
   */
  DedupeCount?: number | null
  /**
   * Invalid Address Count
   * @format int32
   */
  InvalidAddressCount?: number | null
  /**
   * The Address Updated Count
   * @format int32
   */
  AddressUpdateCount?: number | null
  /** Active status of the List File */
  Active?: boolean
  /** Upload status of the List File */
  IsUploadComplete?: boolean
  /**
   * The updated time stamp of the list
   * @format date-time
   */
  UpdateTimeStamp?: string
  /**
   * The created time stamp of the list
   * @format date-time
   */
  CreateTimeStamp?: string
}

export interface ListFileStatus {
  /** @format int32 */
  Id?: number
  Name?: string | null
  Description?: string | null
  Active?: boolean
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string
}

/** @example {"IsUploadComplete":true} */
export interface ListFileUpdateRequest {
  /** If file has been uploaded */
  IsUploadComplete: boolean
}

export interface ListRecipient {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  ListId?: number
  /** @format int32 */
  ListFileId?: number | null
  /** @format int32 */
  CompanyId?: number
  CompanyName?: string | null
  FullName?: string | null
  FirstName?: string | null
  LastName?: string | null
  Prefix?: string | null
  Suffix?: string | null
  Title?: string | null
  Address1?: string | null
  Address2?: string | null
  City?: string | null
  State?: string | null
  Zip?: string | null
  Country?: string | null
  /** @format date-time */
  DateOfBirth?: string | null
  PhoneNumber?: string | null
  Email?: string | null
  List?: ListRecord
  ListFile?: ListFile
  Company?: CompanyRecord
  Address1Standardized?: string | null
  Address2Standardized?: string | null
  CityStandardized?: string | null
  StateStandardized?: string | null
  ZipStandardized?: string | null
  CountryStandardized?: string | null
  IsStandardized?: boolean
  IsDuplicate?: boolean
  IsInvalid?: boolean
  IsInvalidAddress?: boolean
  IsActive?: boolean
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string
  IsFinalized?: boolean
  DataValidationMessage?: string | null
  AddressValidationMessage?: string | null
  DeduplicationMessage?: string | null
}

/** @example {"ListId":5,"SortId":"b3fb0bc9-478e-442b-9d4f-5fe4e2c774f9","FirstName":"Bob","LastName":"Smith","Address1":"1 State St","Address2":"Apt 2","City":"New York","State":"NY","Zip":"11111","CreateTimeStamp":"2024-10-07T17:42:14.2615790+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.2615805+00:00"} */
export interface ListRecipientResponse {
  /**
   * The list identifier
   * @format int32
   */
  ListId: number
  /** The list sort identifier */
  SortId: string | null
  /** The FirstName of the list Recipient */
  FirstName: string
  /** The LastName of the list Recipient */
  LastName: string
  /** The Full Name of the list Recipient */
  FullName: string
  /** The Company Name of the list Recipient */
  CompanyName: string
  /** The Address1 of the list Recipient */
  Address1: string
  /** The Address2 of the list Recipient */
  Address2: string
  /** The City of the list Recipient */
  City?: string
  /** The State of the list Recipient */
  State: string
  /** The Zip of the list Recipient */
  Zip: string
  /**
   * The created time stamp of the list Recipient
   * @format date-time
   */
  CreateTimeStamp: string
  /**
   * The updated time stamp of the list Recipient
   * @format date-time
   */
  UpdateTimeStamp: string
}

export interface ListRecord {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  CompanyId?: number
  Name?: string | null
  Stage?: ListStage
  /** @format int32 */
  RecipientCount?: number | null
  Suppression?: boolean
  Deleted?: boolean
  Recipients?: ListRecipient[] | null
  ListValidationSetting?: ListValidationSetting
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string
}

/** @example {"Id":5,"CompanyId":29,"Name":"Full Client List","Stage":"Active","CreateTimeStamp":"2024-10-07T17:42:14.1320195+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.1320203+00:00","RecipientCount":29,"Suppression":false} */
export interface ListResponse {
  /**
   * The list identifier
   * @format int32
   */
  Id?: number
  /**
   * The id of the company
   * @format int32
   */
  CompanyId: number
  /** The name of the list */
  Name: string
  Stage?: ListStage
  /**
   * The updated time stamp of the list
   * @format date-time
   */
  UpdateTimeStamp?: string
  /**
   * The created time stamp of the list
   * @format date-time
   */
  CreateTimeStamp?: string
  /**
   * The recipient count of the list
   * @format int32
   */
  RecipientCount?: number | null
  /** The suppression list identifier */
  Suppression?: boolean
}

export enum ListStage {
  Pending = 'Pending',
  Processing = 'Processing',
  ErrorProcessing = 'ErrorProcessing',
  RecipientsProcessing = 'RecipientsProcessing',
  ErrorRecipientsProcessing = 'ErrorRecipientsProcessing',
  Active = 'Active',
}

/** @example {"Name":"Full Client List","Stage":"Active"} */
export interface ListUpdateRequest {
  /** The name of the list */
  Name: string
  Stage?: ListStage
}

export interface ListValidationGroup {
  /** @format int32 */
  Id?: number
  Name?: string | null
  Type?: ListValidationType
  AllowMultipleSelection?: boolean
  /** @format int32 */
  Order?: number
  Active?: boolean
  Required?: boolean
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string
  ListValidationOptions?: ListValidationOption[] | null
}

export interface ListValidationOption {
  /** @format int32 */
  Id?: number
  Name?: string | null
  Description?: string | null
  /** @format int32 */
  ListValidationGroupId?: number
  /** @format int32 */
  Order?: number
  Active?: boolean
  Required?: boolean
  ProcessingFailureOptions?: string | null
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string
}

export interface ListValidationResult {
  Success?: Record<string, StringStringKeyValuePair[] | null>
  Error?: Record<string, StringStringKeyValuePair[] | null>
}

export interface ListValidationResultUpdate {
  UpdateRequest?: CampaignListColumnMappingConfigUpdateRequest
  UpdateRows?: ListValidationResultUpdateRow[] | null
}

export interface ListValidationResultUpdateRow {
  SortId?: string | null
  UpdateValue?: string | null
}

export interface ListValidationSetting {
  /** @format int32 */
  Id?: number
  /** @format int32 */
  ListId?: number | null
  /** @format int32 */
  ListFileId?: number | null
  /** @format int32 */
  ListValidationGroupId?: number
  UserSelection?: UserSelection
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string
  ListValidationGroup?: ListValidationGroup
}

export enum ListValidationType {
  ListFile = 'ListFile',
  List = 'List',
}

export interface MailingClassResponse {
  /** @format int32 */
  Id?: number
  MailingClassName?: string | null
  MailsWithin?: string | null
  /** @format double */
  PostageCostPerPiece?: number
  Active?: boolean
  /** @format int32 */
  PieceMinimum?: number
  NonProfitOnly?: boolean
}

export interface Options {
  Title?: string | null
  Value?: string | null
}

export interface OrderLineItem {
  /** @format int32 */
  OrderLineItemId?: number
  /** @format date-time */
  CreatedDate?: string
  /** @format int32 */
  CreatedBy?: number
  /** @format date-time */
  UpdatedDate?: string | null
  /** @format int32 */
  UpdatedBy?: number
  Active?: boolean
  /** @format uuid */
  OrderId?: string
  Order?: OrderRecord
  /** @format uuid */
  ProductId?: string | null
  Product?: ProductRecord
  /** @format int32 */
  Quantity?: number
  /** @format double */
  Rate?: number
  Description?: string | null
  /** @format int32 */
  SortNumber?: number
  /** @format double */
  LineTotal?: number
}

export interface OrderLineItemResponse {
  /** @format int32 */
  OrderLineItemId?: number
  /** @format date-time */
  CreatedDate?: string
  /** @format int32 */
  CreatedBy?: number
  /** @format date-time */
  UpdatedDate?: string | null
  /** @format int32 */
  UpdatedBy?: number
  Active?: boolean
  /** @format uuid */
  OrderId?: string
  Order?: OrderResponse
  /** @format uuid */
  ProductId?: string | null
  Product?: ProductResponse
  /** @format int32 */
  Quantity?: number
  /** @format double */
  Rate?: number
  Description?: string | null
  /** @format int32 */
  SortNumber?: number
  /** @format double */
  LineTotal?: number
}

export interface OrderRecord {
  /** @format uuid */
  OrderId?: string
  /** @format int32 */
  CampaignId?: number | null
  Campaign?: CampaignRecord
  /** @format date-time */
  CreatedDate?: string
  /** @format int32 */
  CreatedBy?: number
  /** @format date-time */
  UpdatedDate?: string | null
  /** @format int32 */
  UpdatedBy?: number | null
  /** @format date-time */
  ExportedDate?: string | null
  /** @format int32 */
  ExportedBy?: number | null
  /** @format double */
  TotalBaseCost?: number
  LineItems?: OrderLineItem[] | null
}

export interface OrderResponse {
  /** @format uuid */
  OrderId?: string
  /** @format int32 */
  CampaignId?: number | null
  Campaign?: CampaignResponse
  /** @format date-time */
  CreatedDate?: string
  /** @format int32 */
  CreatedBy?: number
  /** @format date-time */
  UpdatedDate?: string | null
  /** @format int32 */
  UpdatedBy?: number | null
  /** @format date-time */
  ExportedDate?: string | null
  /** @format int32 */
  ExportedBy?: number | null
  /** @format int32 */
  TotalRecipientCount?: number
  /** @format double */
  TotalBaseCost?: number
  LineItems?: OrderLineItemResponse[] | null
  CampaignList?: CampaignListConfigResponse[] | null
}

/** @example {"PaperStockTypeId":1,"PrimaryPrinterId":1,"PaperStockProductionName":"TestPaperStock","PaperStockCostPerPiece":0.5433,"PaperStockListCostPerPiece":0.7433,"Width":1.7433,"Length":2.0033,"FedPerHour":15000,"Active":true} */
export interface PaperStockCreateRequest {
  /**
   * The paper stock type identifier
   * @format int32
   */
  PaperStockTypeId: number
  /** The Paper Stock production name */
  PaperStockProductionName: string
  /**
   * The Paper Stock cost per piece
   * @format double
   */
  PaperStockCostPerPiece: number
  /**
   * The Paper Stock List cost per piece
   * @format double
   */
  PaperStockListCostPerPiece: number
  /**
   * The Paper Stock Width
   * @format double
   */
  Width: number
  /**
   * The Paper Stock Length
   * @format double
   */
  Length: number
  /**
   * The Paper Stock FedPerHour
   * @format int32
   */
  FedPerHour: number
  /**
   * The paper stock identifier
   * @format int32
   */
  PrimaryPrinterId?: number
  /** The active status of the Paper Stock within a Paper Stock Type */
  Active: boolean
}

export interface PaperStockRecord {
  /** @format int32 */
  PaperStockId?: number
  /** @format int32 */
  PaperStockTypeId?: number
  PaperStockProductionName?: string | null
  /** @format double */
  PaperStockCostPerPiece?: number
  /** @format double */
  PaperStockListCostPerPiece?: number
  /** @format int32 */
  FedPerHour?: number
  /** @format int32 */
  PrimaryPrinterId?: number | null
  /** @format double */
  Width?: number
  /** @format double */
  Length?: number
  Active?: boolean
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string | null
  PrimaryPrinter?: PrimaryPrinterRecord
}

/** @example {"PaperStockId":5,"PaperStockTypeId":9,"PrimaryPrinterId":1,"PaperStockProductionName":"TestPaperStockinProduction","PaperStockCostPerPiece":0.0123,"PaperStockListCostPerPiece":0.0623,"Width":0.0623,"Length":0.0623,"FedPerHour":15000,"Active":true,"CreateTimeStamp":"2024-10-07T17:42:14.3242232+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.3242280+00:00"} */
export interface PaperStockResponse {
  /**
   * The paper stock identifier
   * @format int32
   */
  PaperStockId: number
  /**
   * The paper stock type identifier
   * @format int32
   */
  PaperStockTypeId: number
  /** The Paper Stock production name */
  PaperStockProductionName: string
  /** The active status of the Paper Stock */
  Active: boolean
  /**
   * The Paper Stock cost per piece
   * @format double
   */
  PaperStockCostPerPiece: number
  /**
   * The Paper Stock List cost per piece
   * @format double
   */
  PaperStockListCostPerPiece: number
  /**
   * The Paper Stock Width
   * @format double
   */
  Width: number
  /**
   * The Paper Stock Length
   * @format double
   */
  Length: number
  /**
   * The Paper Stock FedPerHour
   * @format int32
   */
  FedPerHour: number
  /**
   * The paper stock identifier
   * @format int32
   */
  PrimaryPrinterId?: number
  /**
   * Date the Paper Stock was created
   * @format date-time
   */
  CreateTimeStamp: string
  /**
   * Date the Paper Stock was updated
   * @format date-time
   */
  UpdateTimeStamp?: string | null
}

/** @example {"PaperStockTypeDisplayName":"TestPaperStockType","Active":true} */
export interface PaperStockTypeCreateRequest {
  /** The name of the Paper Stock Type */
  PaperStockTypeDisplayName: string
  /** The Active status of the Paper Stock Type */
  Active: boolean
}

export interface PaperStockTypeRecord {
  /** @format int32 */
  PaperStockTypeId?: number
  PaperStockTypeDisplayName?: string | null
  Active?: boolean
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string | null
  PaperStocks?: PaperStockRecord[] | null
}

/** @example {"PaperStockTypeId":5,"PaperStockTypeDisplayName":"TestPaperStockType","Active":true,"CreateTimeStamp":"2024-10-07T17:42:14.3187571+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.3187590+00:00","PaperStocks":[{"PaperStockId":5,"PaperStockProductionName":"TestPaperStockinProduction","PaperStockCostPerPiece":0.0123,"Active":true,"CreateTimeStamp":"2024-10-07T17:42:14.3187612+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.3187613+00:00"}]} */
export interface PaperStockTypeResponse {
  /**
   * The paper stock type identifier
   * @format int32
   */
  PaperStockTypeId: number
  /** The name of the Paper Stock Type */
  PaperStockTypeDisplayName: string
  /** The active status of the Paper Stock Type */
  Active: boolean
  /**
   * Date the Paper Stock was created
   * @format date-time
   */
  CreateTimeStamp: string
  /**
   * Date the Paper Stock was updated
   * @format date-time
   */
  UpdateTimeStamp?: string | null
  /** List of Paper Stocks with this Paper Stock Type */
  PaperStocks?: PaperStockRecord[] | null
}

/** @example {"PaperStockTypeId":1,"PaperStockTypeDisplayName":"TestPaperStockType","Active":true} */
export interface PaperStockTypeUpdateRequest {
  /**
   * The paper stock type identifier
   * @format int32
   */
  PaperStockTypeId: number
  /** The name of the Paper Stock Type */
  PaperStockTypeDisplayName: string
  /** The active status of the Paper Stock Type */
  Active: boolean
}

/** @example {"PaperStockId":5,"PrimaryPrinterId":1,"PaperStockTypeId":9,"PaperStockProductionName":"TestPaperStockinProduction","PaperStockCostPerPiece":0.0123,"PaperStockListCostPerPiece":0.0623,"Active":true,"Width":1.7433,"Length":2.0033,"FedPerHour":15000,"DefaultPaperStock":true} */
export interface PaperStockUpdateRequest {
  /**
   * The paper stock identifier
   * @format int32
   */
  PaperStockId: number
  /**
   * The paper stock type identifier
   * @format int32
   */
  PaperStockTypeId: number
  /** The Paper Stock production name */
  PaperStockProductionName: string
  /** The active status of the Paper Stock */
  Active: boolean
  /**
   * The Paper Stock Width
   * @format double
   */
  Width: number
  /**
   * The Paper Stock Length
   * @format double
   */
  Length: number
  /**
   * The Paper Stock FedPerHour
   * @format int32
   */
  FedPerHour: number
  /**
   * The paper stock identifier
   * @format int32
   */
  PrimaryPrinterId?: number
  /** The default status of the Paper Stock within a Paper Stock Type */
  DefaultPaperStock: boolean
  /**
   * The Paper Stock cost per piece
   * @format double
   */
  PaperStockCostPerPiece: number
  /**
   * The Paper Stock List cost per piece
   * @format double
   */
  PaperStockListCostPerPiece: number
}

export interface PaymentCreditRequest {
  /** @format int32 */
  PaymentMethodId?: number
  /** @format double */
  Amount?: number
  Currency?: string | null
}

export interface PaymentMethod {
  /** @format int32 */
  PaymentMethodId?: number
  /** @format int32 */
  CreatedBy?: number
  /** @format date-time */
  CreatedDate?: string
  /** @format int32 */
  CompanyUserId?: number
  CompanyUser?: CompanyUserRecord
  Active?: boolean
  DisplayName?: string | null
  CustomerToken?: string | null
  InstrumentToken?: string | null
  PaymentToken?: string | null
  Primary?: boolean
  /** @format int32 */
  ExpirationMonth?: number | null
  /** @format int32 */
  ExpirationYear?: number | null
  CardType?: string | null
  CardDisplay?: string | null
  LastFourPan?: string | null
  FirstName?: string | null
  LastName?: string | null
  LastFourRouting?: string | null
  PaymentMethodAccountTypeId?: string | null
  PaymentMethodAccountType?: PaymentMethodAccountType
}

export interface PaymentMethodAccountType {
  PaymentMethodAccountTypeId?: string | null
  Display?: string | null
  Active?: boolean
  /** @format int32 */
  DisplayOrder?: number
}

export interface PaymentMethodRequest {
  /** @format int32 */
  CompanyUserId?: number
  FirstName?: string | null
  LastName?: string | null
  City?: string | null
  State?: string | null
  Zip?: string | null
  Country?: string | null
  Email?: string | null
  Phone?: string | null
  Address1?: string | null
  Address2?: string | null
  DisplayName?: string | null
  Primary?: boolean
  CardNumber?: string | null
  ExpirationMonth?: string | null
  ExpirationYear?: string | null
  SecurityCode?: string | null
  CardDisplay?: string | null
  AccountType?: string | null
  AccountNumber?: string | null
  RoutingNumber?: string | null
}

export interface PaymentMethodResponse {
  /** @format int32 */
  PaymentMethodId?: number
  /** @format int32 */
  CompanyUserId?: number
  DisplayName?: string | null
  Primary?: boolean
  /** @format int32 */
  ExpirationMonth?: number | null
  /** @format int32 */
  ExpirationYear?: number | null
  LastFourPan?: string | null
  CardDisplay?: string | null
  FirstName?: string | null
  LastName?: string | null
  LastFourRouting?: string | null
  AccountType?: string | null
}

export interface PaymentTransaction {
  /** @format int32 */
  PaymentTransactionId?: number
  /** @format double */
  Amount?: number
  Currency?: string | null
  /** @format int32 */
  PaymentTransactionTypeId?: number | null
  PaymentTransactionType?: PaymentTransactionType
  /** @format int32 */
  PaymentMethodId?: number | null
  PaymentMethod?: PaymentMethod
  AuthorizationId?: string | null
  /** @format date-time */
  AuthorizationDate?: string | null
  CaptureId?: string | null
  /** @format date-time */
  CaptureDate?: string | null
  Status?: string | null
  ErrorMessage?: string | null
}

export interface PaymentTransactionType {
  /** @format int32 */
  PaymentTransactionTypeId?: number
  Display?: string | null
  Active?: boolean
}

/** @example {"PrimaryPrinterName":"Prostream","Feed":"Roll","PrimaryPrinterClickCostPerPiece":0.001,"PrimaryPrinterInkCostPerPiece":0.001} */
export interface PrimaryPrinterCreateRequest {
  /** The name of the Primary Printer */
  PrimaryPrinterName: string
  /**
   * The Click Cost of the Primary Printer
   * @format double
   */
  PrimaryPrinterClickCostPerPiece: number
  /**
   * The Ink Cost of the Primary Printer
   * @format double
   */
  PrimaryPrinterInkCostPerPiece: number
  /** The Way Paper is Fed */
  Feed: string
}

export interface PrimaryPrinterRecord {
  /** @format int32 */
  PrimaryPrinterId?: number
  /** @minLength 1 */
  PrimaryPrinterName: string
  /** @format double */
  PrimaryPrinterClickCostPerPiece: number
  /** @format double */
  PrimaryPrinterInkCostPerPiece: number
  /** @minLength 1 */
  Feed: string
  /** @format date-time */
  CreateTimeStamp: string
  /** @format date-time */
  UpdateTimeStamp?: string | null
}

/** @example {"PrimaryPrinterId":5,"PrimaryPrinterName":"Prostream","Feed":"Roll","PrimaryPrinterClickCostPerPiece":0.001,"PrimaryPrinterInkCostPerPiece":0.001,"CreateTimeStamp":"2024-10-07T17:42:14.3429600Z","UpdateTimeStamp":"2024-10-07T17:42:14.3429609Z"} */
export interface PrimaryPrinterResponse {
  /**
   * The Primary Printer identifier
   * @format int32
   */
  PrimaryPrinterId: number
  /** The name of the Primary Printer */
  PrimaryPrinterName: string
  /**
   * The Click Cost of the Primary Printer
   * @format double
   */
  PrimaryPrinterClickCostPerPiece: number
  /**
   * The Ink Cost of the Primary Printer
   * @format double
   */
  PrimaryPrinterInkCostPerPiece: number
  /** The Way Paper is Fed */
  Feed: string
  /**
   * The Create Date of the Primary Printer
   * @format date-time
   */
  CreateTimeStamp?: string
  /**
   * The Last date the Primary Printer was updated
   * @format date-time
   */
  UpdateTimeStamp?: string
}

/** @example {"PrimaryPrinterId":5,"PrimaryPrinterName":"Prostream","Feed":"Roll","PrimaryPrinterClickCostPerPiece":0.001,"PrimaryPrinterInkCostPerPiece":0.001} */
export interface PrimaryPrinterUpdateRequest {
  /**
   * The Primary Printer identifier
   * @format int32
   */
  PrimaryPrinterId: number
  /** The name of the Primary Printer */
  PrimaryPrinterName: string
  /**
   * The Click Cost of the Primary Printer
   * @format double
   */
  PrimaryPrinterClickCostPerPiece: number
  /**
   * The Ink Cost of the Primary Printer
   * @format double
   */
  PrimaryPrinterInkCostPerPiece: number
  /** The Way Paper is Fed */
  Feed?: string
}

export interface ProcessPaymentRequest {
  /** @format int32 */
  PaymentMethodId?: number
  /** @format double */
  Amount?: number
  Currency?: string | null
}

/** @example {"ProductId":"11ea1838-796d-4bed-97dc-d0982a6bb270","BrandIds":5} */
export interface ProductBrandConfigCreateRequest {
  /**
   * The Product identifier
   * @format uuid
   */
  ProductId?: string
  /** The Brand identifier */
  BrandIds?: number[]
}

/** @example {"ProductCategoryDescription":"TestProductCategory","RequiresEnvelope":true,"RequiresPaperStock":true} */
export interface ProductCategoryCreateRequest {
  /** The name of the Product Category */
  ProductCategoryDescription: string
  /** Identifies if the products in the category require an envelope */
  RequiresEnvelope: boolean
  /** Identifies if the products in the category requires paper stock */
  RequiresPaperStock: boolean
}

export interface ProductCategoryRecord {
  /** @format int32 */
  ProductCategoryId?: number
  ProductCategoryDescription?: string | null
  Active?: boolean
  RequiresEnvelope?: boolean
  RequiresPaperStock?: boolean
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string | null
}

/** @example {"ProductCategoryId":5,"ProductCategoryDescription":"TestProductCategory","Active":true,"RequiresEnvelope":true,"RequiresPaperStock":true,"CreateTimeStamp":"2024-10-07T17:42:14.3118157+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.3118172+00:00"} */
export interface ProductCategoryResponse {
  /**
   * The product category identifier
   * @format int32
   */
  ProductCategoryId: number
  /** The name of the Product Category */
  ProductCategoryDescription: string
  /** The active status of the Product Category */
  Active: boolean
  /** Identifies if the products in the category require an envelope */
  RequiresEnvelope: boolean
  /** Identifies if the products in the category requires paper stock */
  RequiresPaperStock: boolean
  /**
   * Date the product category was created
   * @format date-time
   */
  CreateTimeStamp: string
  /**
   * Date the product category was updated
   * @format date-time
   */
  UpdateTimeStamp?: string | null
}

/** @example {"ProductCategoryDescription":"TestProductCategory","Active":true,"RequiresEnvelope":true,"RequiresPaperStock":true} */
export interface ProductCategoryUpdateRequest {
  /**
   * The product category identifier
   * @format int32
   */
  ProductCategoryId: number
  /** The name of the Product Category */
  ProductCategoryDescription: string
  /** Identifies if the products in the category require an envelope */
  RequiresEnvelope: boolean
  /** Identifies if the products in the category requires paper stock */
  RequiresPaperStock: boolean
}

/** @example {"StockImageUrl":"https://google.com","Active":true,"ProductProductionName":"5x7 Folded Card","ProductDisplayName":"TestProductType","ProductionProductId":"11ea1838-796d-4bed-97dc-d0982a6bb270","ProductCategoryId":1,"PaperStockTypeId":2,"EnvelopeTypeId":3,"DefaultEnvelopeId":2,"DefaultPaperStockId":3,"Width":1.12,"Length":1.12,"FinishedWeight":1.12,"FinishedThickness":1.12,"SlitterChanges":2,"FinishedWidth":1.12,"FinishedLength":1.12,"PostageType":"Stamp","PerMailPiece":2,"MailworksMarkup":1.12,"ICAMarkup":1.12,"Description":"5 X 7 Card"} */
export interface ProductCreateRequest {
  /** The active status of the ProductType */
  Active: boolean
  /** The url of the Stock Image */
  StockImageUrl?: string | null
  /** The product production name */
  ProductProductionName: string
  /**
   * The category for the product
   * @format int32
   */
  ProductCategoryId: number
  /**
   * The Production Product Identifier
   * @format uuid
   */
  ProductionProductId: string
  /** The name of the Product Type */
  ProductDisplayName: string
  /**
   * Product Envelope Identifier
   * @format int32
   */
  EnvelopeTypeId?: number | null
  /**
   * Product Paper Stock Type Identifier
   * @format int32
   */
  PaperStockTypeId?: number | null
  /**
   * Product Envelope Type Default Envelope
   * @format int32
   */
  DefaultEnvelopeId?: number | null
  /**
   * Product Paper Stock Type Default Paper Stock
   * @format int32
   */
  DefaultPaperStockId?: number | null
  /**
   * Product Type Identifier
   * @format int32
   */
  ProductTypeId: number
  /**
   * Product Width Across
   * @format double
   */
  Width: number | null
  /**
   * Product Length Across
   * @format double
   */
  Length: number | null
  /**
   * Product Finished Width Across
   * @format double
   */
  FinishedWidth?: number | null
  /**
   * Product Finished Length Across
   * @format double
   */
  FinishedLength?: number | null
  /**
   * Product Finished Weight
   * @format double
   */
  FinishedWeight?: number | null
  /**
   * Product Finished Thickness
   * @format double
   */
  FinishedThickness?: number | null
  /**
   * Product Slitter Changes
   * @format int32
   */
  SlitterChanges?: number | null
  /** Product Postage Type */
  PostageType?: string | null
  /**
   * Product PerMailPiece
   * @format int32
   */
  PerMailPiece: number | null
  /**
   * Product Mailworks Markup
   * @format double
   */
  MailworksMarkup: number | null
  /**
   * Product ICA Markup
   * @format double
   */
  ICAMarkup: number | null
  /** Letter Shop Cost Identifiers */
  LetterShopCostIds?: number[] | null
  /**
   * Coverage Identifier
   * @format int32
   */
  CoverageId?: number | null
  /**
   * Bindery Type Identifier
   * @format int32
   */
  BinderyTypeId?: number | null
  /** Product Description */
  Description: string
  /** Customer's Canvas Tempalte Identifiers */
  CCTemplateIds?: CCTemplateId[] | null
}

/** @example {"ProductHistoryId":1,"ProductId":"11ea1838-796d-4bed-97dc-d0982a6bb270","ProductDisplayName":"Test Product","FieldName":"ProductProductionName","From":"Test Product Production","To":"Test Product Production 2","DateUpdated":"2024-10-07T17:42:14.3533144+00:00","UpdatedBy":"user@aol.com"} */
export interface ProductHistoryResponse {
  /**
   * The Product History identifier
   * @format int32
   */
  ProductHistoryId: number
  /**
   * The ProductId that was updated
   * @format uuid
   */
  ProductId: string
  /** The Display Name of the Product that was updated */
  ProductDisplayName: string
  /** The Field that was updated */
  FieldName: string
  /** What the Field that was updated from */
  From: string
  /** What the Field that was updated to */
  To: string
  /**
   * Date product Update was made
   * @format date-time
   */
  DateUpdated: string
  /** User id that made the update */
  UpdatedBy: string
}

/** @example {"EquipmentCostPerPiece":1,"CommissionCostPerPiece":2,"IndirectCostPerPiece":3} */
export interface ProductPricingConstantResponse {
  /**
   * The ProductId that was updated
   * @format double
   */
  EquipmentCostPerPiece: number
  /**
   * The Display Name of the Product that was updated
   * @format double
   */
  CommissionCostPerPiece: number
  /**
   * The Field that was updated
   * @format double
   */
  IndirectCostPerPiece: number
}

/** @example {"EquipmentCostPerPiece":1,"CommissionCostPerPiece":2,"IndirectCostPerPiece":3} */
export interface ProductPricingConstantUpdateRequest {
  /**
   * The ProductId that was updated
   * @format double
   */
  EquipmentCostPerPiece: number
  /**
   * The Display Name of the Product that was updated
   * @format double
   */
  CommissionCostPerPiece: number
  /**
   * The Field that was updated
   * @format double
   */
  IndirectCostPerPiece: number
}

export interface ProductRecord {
  /** @format uuid */
  ProductId?: string
  /** @format int32 */
  ProductCategoryId: number
  /** @format uuid */
  ProductionProductId: string
  /** @minLength 1 */
  ProductDisplayName: string
  /** @minLength 1 */
  ProductProductionName: string
  StockImageUrl?: string | null
  Active: boolean
  /** @format int32 */
  CoverageId?: number | null
  /** @format int32 */
  BinderyTypeId?: number | null
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string | null
  /** @format int32 */
  ProductTypeId: number
  CustomersCanvasTemplateId?: string | null
  /** @format double */
  Width: number
  /** @format double */
  Length: number
  /** @format double */
  FinishedWidth: number
  /** @format double */
  FinishedLength: number
  /** @format double */
  FinishedWeight: number
  /** @format double */
  FinishedThickness: number
  /** @format int32 */
  SlitterChanges: number
  /** @minLength 1 */
  PostageType: string
  /** @format int32 */
  PerMailPiece: number
  /** @format double */
  MailworksMarkup: number
  /** @format double */
  ICAMarkup: number
  /** @minLength 1 */
  Description: string
  ProductCategory?: ProductCategoryRecord
  ProductType?: ProductTypeRecord
  Coverage?: CoverageRecord
  BinderyType?: BinderyTypeRecord
}

/** @example {"ProductId":"11ea1838-796d-4bed-97dc-d0982a6bb270","StockImageUrl":"https://google.com","GeneratedStockImageUrl":"GeneratedImageUrlValue","Active":true,"ProductProductionName":"5x7 Folded Card","ProductDisplayName":"TestProductType","Width":1.12,"Length":1.12,"FinishedWidth":1.12,"FinishedLength":1.12,"FinishedWeight":1.12,"FinishedThickness":1.12,"SlitterChanges":2,"PostageType":"Stamp","PerMailPiece":2,"MailworksMarkup":1.12,"ICAMarkup":1.12,"CreateTimeStamp":"2024-10-07T17:42:14.1670645+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.1670667+00:00","ProductionProductId":"31ea1838-796d-4bed-97dc-d0982a6bb270","ProductTypeId":6,"CoverageId":7,"BinderyTypeId":2,"Description":"5 X 7 Card"} */
export interface ProductResponse {
  /**
   * The product type identifier
   * @format uuid
   */
  ProductId: string
  /** The url of the Stock Image */
  StockImageUrl?: string | null
  /** The active status of the ProductType */
  Active: boolean
  /** The product production name */
  ProductProductionName: string
  /** The name of the Product Type */
  ProductDisplayName: string
  /**
   * Date the Produck was created
   * @format date-time
   */
  CreateTimeStamp: string
  /**
   * Date the Product was updated
   * @format date-time
   */
  UpdateTimeStamp?: string | null
  /**
   * Production Product Identifier
   * @format uuid
   */
  ProductionProductId: string
  /**
   * Product Type Identifier
   * @format int32
   */
  ProductTypeId?: number
  /**
   * Coverage Identifier
   * @format int32
   */
  CoverageId?: number
  /**
   * Bindery Type Identifier
   * @format int32
   */
  BinderyTypeId?: number
  /**
   * Product Width Across
   * @format double
   */
  Width: number | null
  /**
   * Product Length Across
   * @format double
   */
  Length: number | null
  /**
   * Product Finished Width Across
   * @format double
   */
  FinishedWidth?: number | null
  /**
   * Product Finished Length Across
   * @format double
   */
  FinishedLength?: number | null
  /**
   * Product Finished Weight
   * @format double
   */
  FinishedWeight: number | null
  /**
   * Product Finished Thickness
   * @format double
   */
  FinishedThickness: number | null
  /**
   * Product Slitter Changes
   * @format int32
   */
  SlitterChanges: number | null
  /** Product Postage Type */
  PostageType?: string | null
  /**
   * Product PerMailPiece
   * @format int32
   */
  PerMailPiece: number | null
  /**
   * Product Mailworks Markup
   * @format double
   */
  MailworksMarkup: number | null
  /**
   * Product ICA Markup
   * @format double
   */
  ICAMarkup: number | null
  /** Product Description */
  Description: string
  /** Customer's Canvas Tempalte Identifiers */
  CCTemplateIds?: CCTemplateId[] | null
  ProductType?: ProductTypeRecord
  Coverage?: CoverageRecord
  BinderyType?: BinderyTypeRecord
  ProductCategory: ProductCategoryRecord
  PaperStockType?: PaperStockTypeRecord
  DefaultPaperStock?: PaperStockRecord
  EnvelopeType?: EnvelopeTypeRecord
  DefaultEnvelope?: EnvelopeRecord
  /** LetterShop */
  LetterShops?: LetterShopRecord[] | null
  /** Generated url of the Stock Image */
  GeneratedStockImageUrl?: string | null
  /** Brands */
  Brands?: BrandResponse[]
  /**
   * Across
   * @format double
   */
  Across?: number
  /**
   * Down
   * @format double
   */
  Down?: number
  /**
   * Inch Usage Across
   * @format double
   */
  InchUsageAcross?: number
  /**
   * Coverage Value
   * @format int32
   */
  CoverageValue?: number
  /**
   * Square Inch Coverage Per Side
   * @format double
   */
  SquareInchCoveragePerSide?: number
  /**
   * Ups per Linear Foot
   * @format double
   */
  UpsPerLinearFoot?: number
  /**
   * Ups per Sheet
   * @format double
   */
  UpsPerSheet?: number
  /**
   * Roll Usage
   * @format double
   */
  RollUsage?: number
  /**
   * Ink per Piece
   * @format double
   */
  InkPerPiece?: number
  /**
   * Click per Piece
   * @format double
   */
  ClickPerPiece?: number
  /**
   * Click/Ink per Piece
   * @format double
   */
  ClickInkPerPiece?: number
  /**
   * Paper Price Per Piece
   * @format double
   */
  PaperPricePerPiece?: number
  /**
   * Pieces Per Hour
   * @format double
   */
  PiecesPerHour?: number
  /**
   * Labor Rate Default
   * @format double
   */
  DefaultLaborCostPerPiece?: number | null
  /**
   * Coverage Cost Per Piece/ Labor Rate
   * @format double
   */
  PrintLaborCostPerHour?: number
  /**
   * Coverage Cost Per Piece/ Labor Rate
   * @format double
   */
  FedPerHour?: number
  /**
   * Coverage Cost Per Piece/ Labor Rate
   * @format double
   */
  PrintLaborCostPerPiece?: number
  /**
   * Cutting/Sheeting
   * @format double
   */
  CutSheet?: number
  /**
   * Cutting/Sheeting
   * @format double
   */
  CutSheetCostPerPiece?: number
  /**
   * Equiptment Cost Per Piece
   * @format double
   */
  EquipmentPerPiece?: number
  /**
   * Commission Cost Per Piece
   * @format double
   */
  CommissionCostPerPiece?: number
  /**
   * Default Paper Stock Cost Per Piece
   * @format double
   */
  IndirectCostPerPiece?: number
  /**
   * Default Paper Stock Cost Per Piece
   * @format double
   */
  PaperStockCostPerPiece?: number
  /**
   * Default Envelope Cost Per Piece
   * @format double
   */
  EnvelopeListCostPerPiece?: number
  /**
   * LetterShops Cost Per Piece
   * @format double
   */
  LetterShopCostPerPiece?: number
  /**
   * Sum Costs Per Piece
   * @format double
   */
  MailworksPackage?: number
  /**
   * Markup Cost Per Piece
   * @format double
   */
  MailworksMarkupCost?: number
  /**
   * Base Cost Per Piece
   * @format double
   */
  BaseCostPerPiece?: number
  /**
   * ICA Distribution
   * @format double
   */
  ICADistribution?: number
  /**
   * Mailworks Distribution
   * @format double
   */
  MailworksDistribution?: number
}

export interface ProductTypeRecord {
  /** @format int32 */
  ProductTypeId?: number
  ProductTypeDescription?: string | null
}

/** @example {"ProductTypeId":1,"ProductTypeDescription":"Mail"} */
export interface ProductTypeResponse {
  /**
   * The Product Type identifier
   * @format int32
   */
  ProductTypeId: number
  /** The Product Type Dimension */
  ProductTypeDescription: string
}

/** @example {"ProductId":"11ea1838-796d-4bed-97dc-d0982a6bb270","StockImageUrl":"https://google.com","Active":true,"ProductProductionName":"5x7 Folded Card","ProductDisplayName":"TestProductType","ProductionProductId":"11ea1838-796d-4bed-97dc-d0982a6bb270","ProductCategoryId":7,"PaperStockTypeId":8,"EnvelopeTypeId":9,"DefaultEnvelopeId":8,"DefaultPaperStockId":9,"ProductTypeId":1,"Width":1.12,"Length":1.12,"FinishedWidth":1.12,"FinishedLength":1.12,"FinishedWeight":1.12,"FinishedThickness":1.12,"SlitterChanges":2,"PostageType":"Stamp","PerMailPiece":2,"MailworksMarkup":1.12,"ICAMarkup":1.12,"Description":"5 X 7 Card"} */
export interface ProductUpdateRequest {
  /**
   * The Product Identifier
   * @format uuid
   */
  ProductId: string
  /** The active status of the Product */
  Active: boolean
  /** The url of the Stock Image */
  StockImageUrl?: string | null
  /** The Product Production name */
  ProductProductionName: string
  /**
   * The category for the Product
   * @format int32
   */
  ProductCategoryId: number
  /**
   * The Production Product Identifier
   * @format uuid
   */
  ProductionProductId: string
  /** The name of the Product */
  ProductDisplayName: string
  /**
   * Product Envelope Type Identifier
   * @format int32
   */
  EnvelopeTypeId?: number | null
  /**
   * Product Paper Stock Type Identifier
   * @format int32
   */
  PaperStockTypeId?: number | null
  /**
   * Product Envelope Type Default Envelope
   * @format int32
   */
  DefaultEnvelopeId?: number | null
  /**
   * Product Paper Stock Type Default Paper Stock
   * @format int32
   */
  DefaultPaperStockId?: number | null
  /**
   * Product Type Identifier
   * @format int32
   */
  ProductTypeId: number
  /**
   * Product Width Across
   * @format double
   */
  Width: number | null
  /**
   * Product Length Across
   * @format double
   */
  Length: number | null
  /**
   * Product Finished Width Across
   * @format double
   */
  FinishedWidth?: number | null
  /**
   * Product Finished Length Across
   * @format double
   */
  FinishedLength?: number | null
  /**
   * Product Finished Weight
   * @format double
   */
  FinishedWeight?: number | null
  /**
   * Product Finished Thickness
   * @format double
   */
  FinishedThickness?: number | null
  /**
   * Product Slitter Changes
   * @format int32
   */
  SlitterChanges?: number | null
  /** Product Postage Type */
  PostageType?: string | null
  /**
   * Product PerMailPiece
   * @format int32
   */
  PerMailPiece: number | null
  /**
   * Product Mailworks Markup
   * @format double
   */
  MailworksMarkup: number | null
  /**
   * Product ICA Markup
   * @format double
   */
  ICAMarkup: number | null
  /** Letter Shop Cost Identifiers */
  LetterShopCostIds?: number[] | null
  /**
   * Coverage Identifier
   * @format int32
   */
  CoverageId?: number | null
  /**
   * Bindery Type Identifier
   * @format int32
   */
  BinderyTypeId?: number | null
  /** Product Description */
  Description: string
  /** Customer's Canvas Tempalte Identifiers */
  CCTemplateIds?: CCTemplateId[] | null
}

/** @example {"ProductionProductName":"TestProductionProduct","ProductionName":"ProductionProduct","ProductCategoryId":1} */
export interface ProductionProductCreateRequest {
  /** The name of the Production Product */
  ProductionProductName: string
  /** The name of the Production Product */
  ProductionName: string
  /**
   * The ID of the Product Category associated with this Production Product
   * @format int32
   */
  ProductCategoryId: number
}

/** @example {"ProductionProductId":"e75b0bb4-780a-44a4-a5fa-482ced71bc26","ProductionProductName":"TestProductionProduct","ProductionName":"ProductionProduct","ProductCategoryId":1,"CreateTimeStamp":"2024-10-07T17:42:14.1695716+00:00","UpdateTimeStamp":"2024-10-07T17:42:14.1695732+00:00"} */
export interface ProductionProductResponse {
  /**
   * The Production Product Identifier
   * @format uuid
   */
  ProductionProductId: string
  /** The name of the Production Product */
  ProductionProductName: string
  /** The production name of the Production Product */
  ProductionName: string
  /**
   * The ID of the Product Category associated with this Production Product
   * @format int32
   */
  ProductCategoryId: number
  /**
   * Date the Production Product was created
   * @format date-time
   */
  CreateTimeStamp?: string
  /**
   * Date the Production Product was updated
   * @format date-time
   */
  UpdateTimeStamp?: string | null
  ProductCategory: ProductCategoryRecord
  ImageUrl?: string | null
}

/** @example {"ProductionProductId":"e75b0bb4-780a-44a4-a5fa-482ced71bc26","ProductionProductName":"TestProductionProduct","ProductionName":"ProductionProduct","ProductCategoryId":1} */
export interface ProductionProductUpdateRequest {
  /**
   * The Production Product Identifier
   * @format uuid
   */
  ProductionProductId: string
  /** The name of the Production Product */
  ProductionProductName: string
  /** The production name of the Production Product */
  ProductionName: string
  /**
   * The ID of the Product Category associated with this Production Product
   * @format int32
   */
  ProductCategoryId: number
}

export interface ProductionTimelineResponse {
  /** @format int32 */
  Id?: number
  ProductionTimelineName?: string | null
  ProcessedWithin?: string | null
  /** @format double */
  ProductionCostPerPiece?: number
  Active?: boolean
}

/** @example {"CampaignId":"31687013-9b10-4864-8a3a-a9eb7e2b3083","DesignId":"b9004905-6dea-475e-8d6e-b7dccbbe4c1e","FullName":"John Doe","PageNumber":1,"PageName":"Page 1","ProofImg":"https://example.com/proof_image.jpg"} */
export interface ProofSetResponse {
  /**
   * Id of the campaign
   * @format uuid
   */
  CampaignId?: string
  /** Id of the design */
  DesignId?: string
  /**
   * Page Number
   * @format int32
   */
  PageNumber?: number
  /** Page Name */
  PageName?: string
  /** Fullname of the recipient */
  FullName?: string
  /** Proof image URL */
  ProofImg?: string
}

export interface RenderProofImage {
  /** The id of the design */
  DesignId?: string
  /**
   * The id for the campaign that the design belongs too
   * @format uuid
   */
  CampaignId?: string
}

/** @example {"S3SignedUrl":"https://blahblahblah/mailworks/company/1/lists/1/example_4532432file.csv?X-Amz-Expires=300&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEDMaCXVzLWVhc3QtMSJIMEYCIQC5rQ3K6vTL7Y1%2BfgW%2Fo2mxdvXGQ4gFtDaSK0N3%2BUrwLwIhAPLw9xQ3ONyVHpsjJk2%2B0aq8RpuV1%2FDQPqxbYMK6%2FzSHKowDCHsQABoMOTU5NTEyMzU5MTYwIgzKj%2FsJ%2B21%2Fm31xHGUq6QI%2FXC%2F2emTe2ouGqLlDfWxPG0gTVBZXTosS1Ayx7j6DhSQ9eXKTt2d%2BnDb7dR344vP0hEDi%2FkTLVlxO2YYTgsgWflFmXLbcZHPvFtzoUZk576foE%2B4EzTV6nVsUHn1lCvv4XFRJNktABU8frnJi%2Fxw5n919bXC8KtliRTJmiiX4DBEYsHuTBaGPDnl7IwHLt%2FjY5tmB7RV69gzTExlmCZvygxvT4YDKl8KLUa2CL%2BUtiSyuwV5%2FmaizND%2Fi1bJjHpZsJuM%2BOQf6KOi1W6Vmbbk73GU3zOJCPte7t3Mrdy6wa5BzqgZSkbS7iC8VKnbg17PDPeunIdAZ2f1i6TO8b71Ys%2F9K8OiSFyseTEWJHeMGI%2Bce9ME7t1HHNsp0mDuLgVh%2Bb3GX9PeFWGvqIjRA9jIkGE7HJVFem84zp35lbOKO60ZA1kft%2BrcdQhmJuXnI1fHYVEH%2BQ3a3SpZCdTl4gwXwLrdn8N%2FsqJHBMPqW1KoGOqUBnZbZ9JK7JdK4zZYLgHrt3kNOQhIwKYSYXwKMDHLdeWWid51HMkFfEJ%2FtgFYXdUh6px2AHDT06bQ2bddNnv90v6"} */
export interface S3UploadResponse {
  /** The S3 Signed URL */
  S3SignedUrl?: string
}

/** @example {"CampaignId":"31687013-9b10-4864-8a3a-a9eb7e2b3083","DesignId":"b9004905-6dea-475e-8d6e-b7dccbbe4c1e","ProductId":"ddc9cc9c-387e-4507-88e5-e253018916f7","ProductVariantId":42,"TemplateId":"65fc6ba1b61478070200b7b0"} */
export interface SetupEditorRequest {
  /** The id of the campaign */
  CampaignId?: string
  /**
   * The id of the product
   * @format uuid
   */
  ProductId?: string
  /** The id of the template */
  TemplateId?: string
  /** The id of the design */
  DesignId?: string | null
  /**
   * The product variant id
   * @format int32
   */
  ProductVariantId?: number
}

/** @example {"Id":"b9004905-6dea-475e-8d6e-b7dccbbe4c1e","Name":"My Design","Size":42,"OwnerId":"31687013-9b10-4864-8a3a-a9eb7e2b3083","Private":true,"CustomFields":null,"EditorVariables":{"Name":"FistName","Type":"Text","BarcodeFormat":"QR_CODE","BarcodeSubtype":"None"},"EditorApiKey":"516870139b1048648a3aa9eb7e2b3088","IntegrationDetails":{"DesignEditorUrl":"https://api.customerscanvashub.com/designeditor/","ProductPersonalizationWorkflow":"{}"}} */
export interface SetupEditorResponse {
  /** Id of the design */
  Id?: string
  /** Name of the design */
  Name?: string
  /**
   * Size of the design
   * @format int64
   */
  Size?: number
  /** Guid of the owner/campaign for the design */
  OwnerId?: string
  /** Boolean indicating if the design is private or not */
  Private?: boolean
  /** List of custom fields */
  CustomFields?: Record<string, any>
  /** List of editor variables */
  EditorVariables?: EditorVariableInfo[] | null
  /** API key for the editor */
  EditorApiKey?: string
  IntegrationDetails?: EditorIntegrationDetails
}

export interface SicCodeRecord {
  /** @format int32 */
  SicCodeId?: number
  Office?: string | null
  IndustryTitle?: string | null
}

/** @example {"Id":5,"CompanyName":"Acme, Inc","NonProfitCode":1,"CRID":1,"MailworksId":"AB12","TotalCampaigns":29,"TotalUsers":3,"Brands":[]} */
export interface SicCodeResponse {
  /**
   * The sic code identifier
   * @format int32
   */
  SicCodeId: number
  /** The name of the company */
  Office?: string
  /** The company identifier */
  IndustryTitle?: string
}

export interface StringStringKeyValuePair {
  Key?: string | null
  Value?: string | null
}

export interface SystemRoleRecord {
  /** @format int32 */
  Id?: number
  Role?: string | null
  Label?: string | null
  Active?: boolean
  /** @format date-time */
  UpdateTimeStamp?: string
  /** @format date-time */
  CreateTimeStamp?: string
}

export interface SystemUserStatusRecord {
  /** @format int32 */
  Id?: number
  Status?: string | null
  Active?: boolean
}

/** @example {"Id":5,"CampaignTypeId":29,"ProductTypeId":14,"Name":"Birthday card template","Description":"Bacon ipsum dolor amet qui drumstick shoulder meatball ribeye cow meatloaf cupim biltong velit picanha strip steak. Drumstick filet mignon tri-tip ut pork loin t-bone chuck sausage pancetta id.","Price":3.5,"MarketingUrls":["https://fakeimg.pl/600x400","https://fakeimg.pl/600x400","https://fakeimg.pl/600x400"]} */
export interface TemplateResponse {
  /**
   * The template identifier
   * @format int32
   */
  Id?: number
  /**
   * The campaign type identifier
   * @format int32
   */
  CampaignTypeId?: number
  /**
   * The product type identifier
   * @format int32
   */
  ProductTypeId?: number
  /** The name of the template */
  Name: string
  /** The template description */
  Description?: string | null
  /** The category associated with the template */
  Category?: string | null
  /**
   * The price piece for the template
   * @format double
   */
  Price?: number | null
  /** Marketing URLs */
  MarketingUrls?: string[] | null
}

/** @example {"Name":"Card v1","TemplateId":1} */
export interface TemplateVersionCreationRequest {
  /**
   * The template identifier
   * @format int32
   */
  TemplateId: number
  /** The name of the template */
  Name: string
}

/** @example {"Id":5,"CampaignId":29,"TemplateId":14,"Name":"Birthday card template","Stage":"Active"} */
export interface TemplateVersionResponse {
  /**
   * The template version identifier
   * @format int32
   */
  Id?: number
  /**
   * The campaign identifier
   * @format int32
   */
  CampaignId?: number
  /**
   * The template identifier
   * @format int32
   */
  TemplateId?: number
  /** The name of the template version */
  Name?: string
  Stage?: TemplateVersionStage
}

export enum TemplateVersionStage {
  Draft = 'Draft',
  Proof = 'Proof',
  Active = 'Active',
}

/** @example {"Name":"Birthday card v2","Stage":"Active"} */
export interface TemplateVersionUpdateRequest {
  /** The name of the template */
  Name: string
  Stage: TemplateVersionStage
}

/** @example {"BrandId":"A","Companies":1} */
export interface UpdateCompanyBrandRequest {
  /**
   * The name for the new brand
   * @format int32
   */
  BrandId?: number
  /** The name for the new brand */
  BrandName?: string
  /** Companies (if any) to add to the brand */
  Companies?: number[]
}

/** @example {"ListId":5,"SortId":"b3fb0bc9-478e-442b-9d4f-5fe4e2c774f9","FirstName":"Bob","LastName":"Smith","Address1":"1 State St","Address2":"Apt 2","City":"New York","State":"NY","Zip":"11111"} */
export interface UpdateListRecipientRequest {
  /**
   * The list identifier
   * @format int32
   */
  ListId: number
  /** The list sort identifier */
  SortId: string | null
  /** The FirstName of the  list Recipient */
  FirstName: string
  /** The LastName of the  list Recipient */
  LastName: string
  /** The Full Name of the  list Recipient */
  FullName: string
  /** The Company Name of the  list Recipient */
  CompanyName: string
  /** The Address1 of the  list Recipient */
  Address1: string
  /** The Address2 of the  list Recipient */
  Address2: string
  /** The City of the  list Recipient */
  City?: string
  /** The State of the  list Recipient */
  State: string
  /** The Zip of the  list Recipient */
  Zip: string
}

/** @example {"Email":"joey@example.com","Title":"Sir","FirstName":"Joey","LastName":"Giraffe","PhoneNumber":"+15555555555","BusinessPhoneNumber":"+15555555555","TwitterHandle":"@Twitter","InstagramHandle":"@Instagram","LinkedInHandle":"@LinkedIn","CognitoId":"abc123","RoleId":1} */
export interface UserCreationRequest {
  /** The user's email */
  Email?: string
  /** The users' title */
  Title?: string | null
  /** The user's first name; required when no CognitoId provided */
  FirstName?: string | null
  /** The user's last name; required when no CognitoId provided */
  LastName?: string | null
  /** The phone number for the user */
  PhoneNumber?: string | null
  /** The business phone number for the user */
  BusinessPhoneNumber?: string | null
  /** The Twitter account of the user */
  TwitterHandle?: string | null
  /** The Instagram account of the user */
  InstagramHandle?: string | null
  /** The LinkedIn account of the user */
  LinkedInHandle?: string | null
  /** The cognito Id for an existing user */
  CognitoId?: string | null
  /**
   * The company role for the user
   * @format int32
   */
  CompanyRoleId?: number | null
  /**
   * The system role for the user
   * @format int32
   */
  RoleId?: number | null
  /**
   * The user Id of the originator of this request
   * @format int32
   */
  CreatedByUserId?: number | null
  /** The user's associated companies */
  Companies?: number[] | null
}

/** @example {"UserPreferenceId":5,"UserId":5,"ActiveTwoFactorInd":false,"NotifySendApprovalRemindersInd":false} */
export interface UserPreferenceResponse {
  /**
   * The User's preference identifier
   * @format int32
   */
  UserPreferenceId?: number
  /**
   * The User's identifier
   * @format int32
   */
  UserId?: number
  /** Indicator for activation of two factor authentication */
  ActiveTwoFactorInd?: boolean
  /** Notification preference for reminders to approve proofs */
  NotifySendApprovalRemindersInd?: boolean
}

/** @example {"UserPreferenceId":5,"UserId":5,"ActiveTwoFactorInd":false,"NotifySendApprovalRemindersInd":false} */
export interface UserPreferenceUpdateRequest {
  /**
   * The User's preference identifier
   * @format int32
   */
  UserPreferenceId?: number | null
  /**
   * The User's identifier
   * @format int32
   */
  UserId?: number
  /** Indicator for activation of two factor authentication */
  ActiveTwoFactorInd?: boolean | null
  /** Notification preference for reminders to approve proofs */
  NotifySendApprovalRemindersInd?: boolean | null
}

export interface UserRecord {
  /** @format int32 */
  Id?: number
  Email?: string | null
  FirstName?: string | null
  LastName?: string | null
  Title?: string | null
  /** @format date-time */
  DateOfBirth?: string | null
  PhoneNumber?: string | null
  BusinessPhoneNumber?: string | null
  Active?: boolean
  /** @format int32 */
  CreatedByUserId?: number | null
  CognitoUserId?: string | null
  /** @format int32 */
  SystemRoleId?: number | null
  /** @format int32 */
  StatusId?: number
  TwitterHandle?: string | null
  InstagramHandle?: string | null
  LinkedInHandle?: string | null
  /** @format date-time */
  CreateTimeStamp?: string
  /** @format date-time */
  UpdateTimeStamp?: string
  SystemRole?: SystemRoleRecord
  CreatedByUser?: UserRecord
  Status?: SystemUserStatusRecord
}

/** @example {"Id":29,"Email":"jgiraffe@mailworks.com","Title":"Sir","DateOfBirth":"2016-10-07T17:42:14.3783513+00:00","FirstName":"Joey","LastName":"Giraffe","PhoneNumber":"+15555555555","BusinessPhoneNumber":"+15555555555","TwitterHandle":"@Twitter","InstagramHandle":"@Instagram","LinkedInHandle":"@LinkedIn","Active":true,"Role":"Account Admin","RoleId":1,"Status":"Active","CreatedByUser":"Johnny Appleseed","UpdateTimeStamp":"2024-10-07T17:42:14.3783634+00:00","CreateTimeStamp":[],"CompanyLocation":"1313 Mockingbird Lane"} */
export interface UserResponse {
  /**
   * The user's identifier
   * @format int32
   */
  Id?: number
  /** The user's email address */
  Email: string
  /** The users' title */
  Title?: string | null
  /**
   * The users' date of birth
   * @format date-time
   */
  DateOfBirth?: string | null
  /** The user's first name */
  FirstName?: string | null
  /** The user's last name */
  LastName?: string | null
  /** The personal phone number for the user */
  PhoneNumber?: string | null
  /** The business phone number for the user */
  BusinessPhoneNumber?: string | null
  /** The Twitter account of the user */
  TwitterHandle?: string | null
  /** The Instagram account of the user */
  InstagramHandle?: string | null
  /** The LinkedIn account of the user */
  LinkedInHandle?: string | null
  /** Indicates if the user is active */
  Active: boolean
  /** System role */
  Role?: string | null
  /**
   * System roleid
   * @format int32
   */
  RoleId?: number | null
  /** Status of user */
  Status?: string
  /** The user who created the user; value null when self-created */
  CreatedByUser?: string | null
  /**
   * The updated time stamp of the user
   * @format date-time
   */
  UpdateTimeStamp?: string
  /**
   * The created time stamp of the user
   * @format date-time
   */
  CreateTimeStamp?: string
  /** The address of the primary location of the user's company */
  CompanyLocation?: string
  /** The user's associated companies and roles */
  Companies?: CompanyUserRecord[]
}

/** @example {"TotalRecords":29,"TotalPages":3,"PageNumber":1,"PageSize":10,"Users":[{"Id":29,"Email":"jgiraffe@mailworks.com","Title":"Sir","FirstName":"Joey","LastName":"Giraffe","PhoneNumber":"+15555555555","BusinessPhoneNumber":"+15555555555","TwitterHandle":"@Twitter","InstagramHandle":"@Instagram","LinkedInHandle":"@LinkedIn","Active":true,"Role":"Account Admin","RoleId":1,"Status":"Active","CreatedByUser":"Johnny Appleseed","UpdateTimeStamp":"2024-10-07T17:42:14.4205851+00:00","CreateTimeStamp":"2024-10-07T17:42:14.4205908+00:00"}]} */
export interface UserResponsePaged {
  /**
   * Total number of records
   * @format int32
   */
  TotalRecords: number
  /**
   * Total number of pages
   * @format int32
   */
  TotalPages?: number
  /**
   * Current page number
   * @format int32
   */
  PageNumber?: number
  /**
   * Number of records per page
   * @format int32
   */
  PageSize?: number
  /** List of Users */
  Users?: UserResponse[]
}

/** @example {"Id":5,"Role":"admin","Label":"Admin"} */
export interface UserRoleResponse {
  /**
   * The role identifier
   * @format int32
   */
  Id?: number
  /** The name of the role */
  Role?: string
  /** The label for the role */
  Label?: string
}

export interface UserSelection {
  /** @format int32 */
  ListValidationGroupId?: number
  ListValidationOptionIds?: number[] | null
}

/** @example {"RoleId":1,"Title":"Manager","FirstName":"Bob","LastName":"Smith","DateOfBirth":"04/18/1980","PhoneNumber":"+15556667777","BusinessPhoneNumber":"+15555555555","TwitterHandle":"@Twitter","InstagramHandle":"@Instagram","LinkedInHandle":"LinkedIn","ReActivate":false} */
export interface UserUpdateRequest {
  /** @format int32 */
  RoleId?: number | null
  Title?: string | null
  /** @format date-time */
  DateOfBirth?: string | null
  /** The user's first name */
  FirstName?: string | null
  /** The user's last name */
  LastName?: string | null
  /** The phone number for the user */
  PhoneNumber?: string | null
  /** The business phone number for the user */
  BusinessPhoneNumber?: string | null
  /** The Twitter account of the user */
  TwitterHandle?: string | null
  /** The Instagram account of the user */
  InstagramHandle?: string | null
  /** The LinkedIn account of the user */
  LinkedInHandle?: string | null
  /**
   * The company role for the user
   * @format int32
   */
  CompanyRoleId?: number | null
  /** The user's associated companies */
  Companies?: number[] | null
  /** Flag to enable the user */
  ReActivate?: boolean | null
}

export interface VariableData {
  /** The name of the template variable */
  Name?: string
  /** The value of the template variable */
  Value?: string
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title Core Service - V1
 * @version v1
 *
 * Provides access to Core Portal 2 services
 */
export class ApiCore<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Asset
     * @name CreateAsset
     * @summary Creates an asset.
     * @request POST:/api/asset/{companyId}
     * @secure
     */
    createAsset: (companyId: string, data: AssetCreationRequest, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/api/asset/${companyId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Asset
     * @name CreateFolder
     * @summary Create A Folder Asset
     * @request POST:/api/asset/folder/{companyId}
     * @secure
     */
    createFolder: (companyId: string, data: CreateFolderRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/asset/folder/${companyId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Asset
     * @name GetCompanyAssets
     * @summary Lists a companies assets that do not belong to a folder, and all folders
     * @request GET:/api/asset/{companyId}/asset
     * @secure
     */
    getCompanyAssets: (companyId: number, params: RequestParams = {}) =>
      this.request<AssetResponse[], any>({
        path: `/api/asset/${companyId}/asset`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Asset
     * @name GetCompanyImageAssets
     * @summary Lists a company's image assets
     * @request GET:/api/asset/{companyId}/img-asset
     * @secure
     */
    getCompanyImageAssets: (companyId: number, params: RequestParams = {}) =>
      this.request<AssetResponse[], any>({
        path: `/api/asset/${companyId}/img-asset`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Asset
     * @name GetCompanyAssetsFolder
     * @summary Lists a companies assets in a specific folder.
     * @request GET:/api/asset/{companyId}/asset/{folder}
     * @secure
     */
    getCompanyAssetsFolder: (companyId: number, folder: string, params: RequestParams = {}) =>
      this.request<AssetResponse[], any>({
        path: `/api/asset/${companyId}/asset/${folder}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Asset
     * @name DownloadCompanyAsset
     * @summary Download's a company's assets.
     * @request GET:/api/asset/{companyId}/asset/download/{assetId}
     * @secure
     */
    downloadCompanyAsset: (companyId: number, assetId: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/asset/${companyId}/asset/download/${assetId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Asset
     * @name GetCompanyAsset
     * @summary Gets a company's specific asset object.
     * @request GET:/api/asset/{companyId}/asset/{assetId}/{assetName}
     * @secure
     */
    getCompanyAsset: (companyId: number, assetId: number, assetName: string, params: RequestParams = {}) =>
      this.request<AssetResponse, any>({
        path: `/api/asset/${companyId}/asset/${assetId}/${assetName}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Asset
     * @name RenameAsset
     * @summary Renames an asset or folder
     * @request POST:/api/asset/asset/rename/{companyId}
     * @secure
     */
    renameAsset: (companyId: string, data: AssetUpdateRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/asset/asset/rename/${companyId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Asset
     * @name DeleteCompanyAsset
     * @summary Delete's a company's assets.
     * @request DELETE:/api/asset/{companyId}/asset/{assetId}
     * @secure
     */
    deleteCompanyAsset: (companyId: number, assetId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/asset/${companyId}/asset/${assetId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Brand
     * @name CreateBrand
     * @summary Create a brand record
     * @request POST:/api/brand/companybrand
     * @secure
     */
    createBrand: (data: CreateBrandRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/brand/companybrand`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Brand
     * @name UpdateCompanyBrandConfigs
     * @summary Create or Update a Company Brand record
     * @request PUT:/api/brand/company-brand
     * @secure
     */
    updateCompanyBrandConfigs: (data: UpdateCompanyBrandRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/brand/company-brand`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name GetBrands
     * @summary Get all brands
     * @request GET:/api/brand
     * @secure
     */
    getBrands: (
      query?: {
        brandName?: string
        status?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<BrandResponse[], any>({
        path: `/api/brand`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name DownloadBrandsCsv
     * @summary Returns Brand list as csv.
     * @request GET:/api/brand/download
     * @secure
     */
    downloadBrandsCsv: (
      query?: {
        /** @default "" */
        search?: string
        filter?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/brand/download`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name BrandActive
     * @summary Updates brand active status
     * @request GET:/api/brand/brand-active
     * @secure
     */
    brandActive: (
      query?: {
        /** @format int32 */
        brandId?: number
        active?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<BrandRecord, any>({
        path: `/api/brand/brand-active`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name DisableCompanyBrand
     * @summary Disable Company Brand Connection
     * @request DELETE:/api/brand/company/{companyId}/brand/{brandId}
     * @secure
     */
    disableCompanyBrand: (companyId: number, brandId: number, params: RequestParams = {}) =>
      this.request<CompanyBrand, any>({
        path: `/api/brand/company/${companyId}/brand/${brandId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Brand
     * @name UpdateProductBrandConfigs
     * @summary Create or Update a Product Brand record
     * @request POST:/api/brand/product-brand
     * @secure
     */
    updateProductBrandConfigs: (data: ProductBrandConfigCreateRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/brand/product-brand`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name GetCampaigns
     * @summary Provides a list of Campaigns.
     * @request GET:/api/company/{companyId}/campaign
     * @secure
     */
    getCampaigns: (companyId: number, params: RequestParams = {}) =>
      this.request<CampaignResponse[], any>({
        path: `/api/company/${companyId}/campaign`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name CreateCampaign
     * @summary Creates a new Campaign.
     * @request POST:/api/company/{companyId}/campaign
     * @secure
     */
    createCampaign: (companyId: number, data: CampaignCreationRequest, params: RequestParams = {}) =>
      this.request<CampaignResponse, void>({
        path: `/api/company/${companyId}/campaign`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name GetCampaign
     * @summary Provides a specific Campaign.
     * @request GET:/api/company/{companyId}/campaign/{campaignId}
     * @secure
     */
    getCampaign: (companyId: number, campaignId: number, params: RequestParams = {}) =>
      this.request<CampaignResponse, any>({
        path: `/api/company/${companyId}/campaign/${campaignId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name UpdateCampaign
     * @summary Updates an existing Campaign.
     * @request PUT:/api/company/{companyId}/campaign/{campaignId}
     * @secure
     */
    updateCampaign: (companyId: number, campaignId: number, data: CampaignUpdateRequest, params: RequestParams = {}) =>
      this.request<CampaignResponse, void>({
        path: `/api/company/${companyId}/campaign/${campaignId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name GetCampaignProductTemplates
     * @summary Gets Campaign Product Templates.
     * @request GET:/api/company/{companyId}/campaign/campaign-product-templates
     * @secure
     */
    getCampaignProductTemplates: (
      companyId: number,
      query?: {
        /** @format uuid */
        campaignProductId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<CampaignProductTemplateResponse, void>({
        path: `/api/company/${companyId}/campaign/campaign-product-templates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name CreateCampaignProduct
     * @summary Creates a new Campaign product Relation.
     * @request POST:/api/company/{companyId}/campaign/campaign-product
     * @secure
     */
    createCampaignProduct: (companyId: number, data: CampaignProductConfigCreateRequest, params: RequestParams = {}) =>
      this.request<CampaignResponse, void>({
        path: `/api/company/${companyId}/campaign/campaign-product`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name UpdateCampaignProduct
     * @summary Updates a new Campaign product Relation.
     * @request PUT:/api/company/{companyId}/campaign/campaign-product
     * @secure
     */
    updateCampaignProduct: (companyId: number, data: CampaignProductConfigUpdateRequest, params: RequestParams = {}) =>
      this.request<CampaignResponse, void>({
        path: `/api/company/${companyId}/campaign/campaign-product`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name SetupEditor
     * @summary Retrieves the editor information for the specified campaign
     * @request POST:/api/company/{companyId}/campaign/setup-editor
     * @secure
     */
    setupEditor: (companyId: number, data: SetupEditorRequest, params: RequestParams = {}) =>
      this.request<SetupEditorResponse, void>({
        path: `/api/company/${companyId}/campaign/setup-editor`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name CreateCampaignList
     * @summary Creates a new Campaign List Relation.
     * @request POST:/api/company/{companyId}/campaign/campaign-list
     * @secure
     */
    createCampaignList: (companyId: number, data: CampaignListConfigCreationRequest, params: RequestParams = {}) =>
      this.request<number, void>({
        path: `/api/company/${companyId}/campaign/campaign-list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name UpdateCampaignList
     * @summary Updates a new Campaign list Relation.
     * @request PUT:/api/company/{companyId}/campaign/campaign-list
     * @secure
     */
    updateCampaignList: (
      companyId: number,
      query?: {
        /** @format int32 */
        campaignListId?: number
        campaignListStatus?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/campaign/campaign-list`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name GetCampaignListFileS3DownloadUrl
     * @summary Gets the URL for the list file to be downloaded
     * @request GET:/api/company/{companyId}/campaign/campaign-list-file-s3download
     * @secure
     */
    getCampaignListFileS3DownloadUrl: (
      companyId: number,
      query?: {
        /** @format int32 */
        listId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<S3UploadResponse, void>({
        path: `/api/company/${companyId}/campaign/campaign-list-file-s3download`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name GetCampaignListMapping
     * @summary Gets a List Column Mapping and Template Variable Data.
     * @request GET:/api/company/{companyId}/campaign/campaign-list-mapping
     * @secure
     */
    getCampaignListMapping: (
      companyId: number,
      query?: {
        /** @format int32 */
        campaignId?: number
        /** @format int32 */
        listId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<CampaignListColumnMappingConfigResponse, void>({
        path: `/api/company/${companyId}/campaign/campaign-list-mapping`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name CreateCampaignListMapping
     * @summary Creates a new Campaign List Column Mapping.
     * @request POST:/api/company/{companyId}/campaign/campaign-list-mapping
     * @secure
     */
    createCampaignListMapping: (
      companyId: number,
      data: CampaignListColumnMappingConfigCreationRequest[],
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/campaign/campaign-list-mapping`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b>
     *
     * @tags ListValidationSettings
     * @name GetListValidationGroupsListFile
     * @summary Get Validation Group for List Files.
     * @request GET:/api/company/{companyId}/campaign/list-validation-groups/list-file
     * @secure
     */
    getListValidationGroupsListFile: (companyId: string, params: RequestParams = {}) =>
      this.request<DefaultListValidationGroupRecord[], void>({
        path: `/api/company/${companyId}/campaign/list-validation-groups/list-file`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name CreateCampaignListValidationOptions
     * @summary Creates new Campaign List Validation Options.
     * @request POST:/api/company/{companyId}/campaign/campaign-list-validation
     * @secure
     */
    createCampaignListValidationOptions: (
      companyId: number,
      data: CampaignListValidationOptionCreateRequest[],
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/campaign/campaign-list-validation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b>
     *
     * @tags Campaign
     * @name GetCampaignStore
     * @summary Get Campaign Store Data.
     * @request GET:/api/company/{companyId}/campaign/campaign-store
     * @secure
     */
    getCampaignStore: (
      companyId: number,
      query?: {
        /** @format int32 */
        campaignId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<CampaignStoreResponse, void>({
        path: `/api/company/${companyId}/campaign/campaign-store`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name RenderProof
     * @summary Renders the specified proof for the specified campaign
     * @request POST:/api/company/{companyId}/campaign/render-proof
     * @secure
     */
    renderProof: (companyId: number, data: RenderProofImage, params: RequestParams = {}) =>
      this.request<ProofSetResponse[][], void>({
        path: `/api/company/${companyId}/campaign/render-proof`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name RenderProofToZip
     * @summary Renders the specified proof for the specified campaign as a zip file
     * @request POST:/api/company/{companyId}/campaign/render-proof-zip
     * @secure
     */
    renderProofToZip: (companyId: number, data: RenderProofImage, params: RequestParams = {}) =>
      this.request<File, void>({
        path: `/api/company/${companyId}/campaign/render-proof-zip`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name UpdateCampaignTimeline
     * @summary Updates the campaign's production timeline and mailing class
     * @request POST:/api/company/{companyId}/campaign/updatecampaigntimeline
     * @secure
     */
    updateCampaignTimeline: (
      companyId: string,
      query?: {
        /** @format int32 */
        campaignId?: number
        /** @format int32 */
        productionTimelineId?: number
        /** @format int32 */
        mailingClassId?: number
        /** @format date-time */
        inHomeDate?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<CampaignProductionTimelineConfigResponse, void>({
        path: `/api/company/${companyId}/campaign/updatecampaigntimeline`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags ProductionTimeline
     * @name GetProductionTimeline
     * @summary Fetch the production timeline options for a given campaign
     * @request GET:/api/company/{companyId}/campaign/productiontimeline
     * @secure
     */
    getProductionTimeline: (
      companyId: string,
      query?: {
        /** @format int32 */
        campaignId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<CampaignProductionTimelineConfigResponse, void>({
        path: `/api/company/${companyId}/campaign/productiontimeline`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags ProductionTimeline
     * @name GetProductionTimelines
     * @summary Fetch the production timeline options for a given campaign type
     * @request GET:/api/company/{companyId}/campaign/productiontimelines
     * @secure
     */
    getProductionTimelines: (
      companyId: string,
      query?: {
        /** @format int32 */
        campaignType?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductionTimelineResponse[], void>({
        path: `/api/company/${companyId}/campaign/productiontimelines`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags MailingClass
     * @name GetMailingClasses
     * @summary Fetch the mailing classes options for a production timeline
     * @request GET:/api/company/{companyId}/campaign/maillingclasses
     * @secure
     */
    getMailingClasses: (
      companyId: string,
      query?: {
        /** @format int32 */
        campaignId?: number
        /** @format int32 */
        productionTimelineId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<MailingClassResponse[], void>({
        path: `/api/company/${companyId}/campaign/maillingclasses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b>
     *
     * @tags Campaign
     * @name GetDataFormatOptions
     * @summary Provides a specific Campaign Data Format Options.
     * @request GET:/api/company/{companyId}/campaign/data-format-options
     * @secure
     */
    getDataFormatOptions: (companyId: number, params: RequestParams = {}) =>
      this.request<DataFormatOptionResponse[], any>({
        path: `/api/company/${companyId}/campaign/data-format-options`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name GetDataFormatTypes
     * @summary Provides a specific Campaign Data Format Types.
     * @request GET:/api/company/{companyId}/campaign/data-format-types
     * @secure
     */
    getDataFormatTypes: (companyId: number, params: RequestParams = {}) =>
      this.request<DataFormatTypeResponse[], any>({
        path: `/api/company/${companyId}/campaign/data-format-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name GetCampaignListColumnMappingConfigUpdateRequests
     * @summary Provides a list of Column Headers used in the Campaign List Mapping.
     * @request GET:/api/company/{companyId}/campaign/campaign-list-column-headers
     * @secure
     */
    getCampaignListColumnMappingConfigUpdateRequests: (
      companyId: number,
      query?: {
        /** @format int32 */
        campaignListId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<CampaignListColumnMappingConfigUpdateRequest[], any>({
        path: `/api/company/${companyId}/campaign/campaign-list-column-headers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name UpdateCampaignListColumnMapping
     * @summary Updates a Campaign List Column Mapping.
     * @request PUT:/api/company/{companyId}/campaign/campaign-list-column-mapping
     * @secure
     */
    updateCampaignListColumnMapping: (
      companyId: number,
      data: CampaignListColumnMappingConfigUpdateRequest[],
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/campaign/campaign-list-column-mapping`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name ValidateCampaignListColumnMapping
     * @summary Validates a Campaign List Column Mapping.
     * @request PUT:/api/company/{companyId}/campaign/validate-campaign-list-column-mapping
     * @secure
     */
    validateCampaignListColumnMapping: (
      companyId: number,
      data: CampaignListColumnMappingConfigUpdateRequest[],
      params: RequestParams = {},
    ) =>
      this.request<ListValidationResult, void>({
        path: `/api/company/${companyId}/campaign/validate-campaign-list-column-mapping`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name ApplyValidateCampaignListColumnMapping
     * @summary Applies List Validation to a Campaign List Column Mapping.
     * @request PUT:/api/company/{companyId}/campaign/apply-validate-campaign-list-column-mapping
     * @secure
     */
    applyValidateCampaignListColumnMapping: (
      companyId: number,
      data: CampaignListColumnMappingConfigUpdateRequest[],
      params: RequestParams = {},
    ) =>
      this.request<ListValidationResult, void>({
        path: `/api/company/${companyId}/campaign/apply-validate-campaign-list-column-mapping`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name ListValidationAndUpdate
     * @summary Validates and Updates a Campaign List Column Mapping.
     * @request POST:/api/company/{companyId}/campaign/validate-update-campaign-list
     * @secure
     */
    listValidationAndUpdate: (companyId: number, data: ListValidationResultUpdate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/campaign/validate-update-campaign-list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Campaign
     * @name ListValidationDelete
     * @summary Deletes Campaign List Column Mapping Rows.
     * @request DELETE:/api/company/{companyId}/campaign/validate-campaign-list-delete
     * @secure
     */
    listValidationDelete: (
      companyId: number,
      data: string[],
      query?: {
        /** @format int32 */
        campaignListId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/campaign/validate-campaign-list-delete`,
        method: 'DELETE',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign Type
     * @name GetCampaignTypes
     * @summary Provides a list of Campaign Types.
     * @request GET:/api/campaign-type
     * @secure
     */
    getCampaignTypes: (
      query?: {
        /** @default "" */
        search?: string
        filter?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<CampaignTypeResponse[], any>({
        path: `/api/campaign-type`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_vp</b>
     *
     * @tags Campaign Type
     * @name UpdateCampaignType
     * @summary Updates the specified Campaign Type
     * @request PUT:/api/campaign-type
     * @secure
     */
    updateCampaignType: (data: CampaignTypeUpdateRequest, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/api/campaign-type`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign Type
     * @name GetCampaignTypeProductionProducts
     * @summary Provides a list of Campaign Type Production Products.
     * @request GET:/api/campaign-type/campaign-type-production-products
     * @secure
     */
    getCampaignTypeProductionProducts: (
      query?: {
        /** @format int32 */
        campaignTypeId?: number
        /** @format int32 */
        companyId?: number
        /** @default "" */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductionProductResponse[], void>({
        path: `/api/campaign-type/campaign-type-production-products`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign Type
     * @name GetCampaignTypeProducts
     * @summary Provides a list of Campaign Type Products.
     * @request GET:/api/campaign-type/campaign-type-products
     * @secure
     */
    getCampaignTypeProducts: (
      query?: {
        /** @format int32 */
        campaignTypeId?: number
        /** @default "" */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductResponse[], void>({
        path: `/api/campaign-type/campaign-type-products`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign Type
     * @name GetCampaignTypeById
     * @summary Provides a CampaignType record.
     * @request GET:/api/campaign-type/{campaignTypeId}
     * @secure
     */
    getCampaignTypeById: (campaignTypeId: number, params: RequestParams = {}) =>
      this.request<CampaignTypeResponse, void>({
        path: `/api/campaign-type/${campaignTypeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_vp</b>
     *
     * @tags Campaign Type
     * @name CampaignTypeActivation
     * @summary Sets active status for a  Campaign Type.
     * @request POST:/api/campaign-type/campaign-type/activation
     * @secure
     */
    campaignTypeActivation: (
      query?: {
        /** @format int32 */
        campaignTypeId?: number
        activate?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/campaign-type/campaign-type/activation`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign Type
     * @name DownloadCampaignTypesCsv
     * @summary Returns Campaign Types list as csv.
     * @request GET:/api/campaign-type/campaign-type/download
     * @secure
     */
    downloadCampaignTypesCsv: (
      query?: {
        /** @default "" */
        search?: string
        filter?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/campaign-type/campaign-type/download`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign Type
     * @name GetResponseTrackings
     * @summary Provides a list of Response Tracking Products.
     * @request GET:/api/campaign-type/response-trackings
     * @secure
     */
    getResponseTrackings: (params: RequestParams = {}) =>
      this.request<ProductRecord[], void>({
        path: `/api/campaign-type/response-trackings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign Type
     * @name GetProductAddOns
     * @summary Provides a list of Product Add Ons.
     * @request GET:/api/campaign-type/product-add-ons
     * @secure
     */
    getProductAddOns: (params: RequestParams = {}) =>
      this.request<ProductRecord[], void>({
        path: `/api/campaign-type/product-add-ons`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaign Event Data Type
     * @name GetEventDateTypes
     * @summary Provides a list of Event Date Types.
     * @request GET:/api/campaign-type/eventdatetypes
     * @secure
     */
    getEventDateTypes: (
      query?: {
        /** @default "" */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<CampaignTypeEventDataTypesResponse[], any>({
        path: `/api/campaign-type/eventdatetypes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of Customer Tiers that can applied to a company
     *
     * @tags CompanyCustomerTier
     * @name GetCompanyCustomerTier
     * @summary Provides a list of Company Customer Tiers
     * @request GET:/api/company/tiers
     * @secure
     */
    getCompanyCustomerTier: (params: RequestParams = {}) =>
      this.request<CompanyCustomerTierResponse[], any>({
        path: `/api/company/tiers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of companies belonging to the company user. If the user has an authorized Mailworks role, all companies are returned
     *
     * @tags Company
     * @name GetCompanies
     * @summary Provides a list of Companies.
     * @request GET:/api/company
     * @secure
     */
    getCompanies: (params: RequestParams = {}) =>
      this.request<CompanyResponse[], any>({
        path: `/api/company`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CreateCompany
     * @summary Creates a new of Company.
     * @request POST:/api/company
     * @secure
     */
    createCompany: (data: CompanyCreationRequest, params: RequestParams = {}) =>
      this.request<CompanyResponse, void>({
        path: `/api/company`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name DownloadCompaniesCsv
     * @summary Returns company list as csv.
     * @request GET:/api/company/downloadcompanies
     * @secure
     */
    downloadCompaniesCsv: (
      query?: {
        /** @default "" */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/company/downloadcompanies`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Company
     * @name GetCompanyById
     * @summary Retrieves the specified company
     * @request GET:/api/company/{companyId}
     * @secure
     */
    getCompanyById: (companyId: number, params: RequestParams = {}) =>
      this.request<CompanyResponse, void>({
        path: `/api/company/${companyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_managing_director,mw_vp,admin</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Company
     * @name UpdateCompany
     * @summary Updates an existing Company.
     * @request PUT:/api/company/{companyId}
     * @secure
     */
    updateCompany: (companyId: number, data: CompanyUpdateRequest, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/company/${companyId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocation
     * @name GetCompanyLocations
     * @summary Retrieves the locations for a specific company
     * @request GET:/api/company/{companyId}/location
     * @secure
     */
    getCompanyLocations: (companyId: number, params: RequestParams = {}) =>
      this.request<CompanyLocationResponse[], void>({
        path: `/api/company/${companyId}/location`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_managing_director,mw_vp,admin</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocation
     * @name CreateCompanyLocation
     * @summary Creates a new location for the company
     * @request POST:/api/company/{companyId}/location
     * @secure
     */
    createCompanyLocation: (companyId: number, data: CompanyLocationCreationRequest, params: RequestParams = {}) =>
      this.request<CompanyLocationResponse, void>({
        path: `/api/company/${companyId}/location`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_managing_director,mw_vp,admin</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocation
     * @name DeleteLocation
     * @summary Deletes the specified location
     * @request DELETE:/api/company/{companyId}/location/{locationId}
     * @secure
     */
    deleteLocation: (companyId: number, locationId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/location/${locationId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_managing_director,mw_vp,admin</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocation
     * @name UpdateLocation
     * @summary Updates the specified location
     * @request PUT:/api/company/{companyId}/location/{locationId}
     * @secure
     */
    updateLocation: (
      companyId: number,
      locationId: number,
      data: CompanyLocationUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/location/${locationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocation
     * @name GetLocationById
     * @summary Retrieves the specified location
     * @request GET:/api/company/{companyId}/location/{locationId}
     * @secure
     */
    getLocationById: (companyId: number, locationId: number, params: RequestParams = {}) =>
      this.request<CompanyLocationResponse, void>({
        path: `/api/company/${companyId}/location/${locationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_production,mw_vp,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Company
     * @name GetCompanyUsers
     * @summary Retrieves the specified company's users
     * @request GET:/api/company/{companyId}/user
     * @secure
     */
    getCompanyUsers: (companyId: number, params: RequestParams = {}) =>
      this.request<CompanyUserResponse[], void>({
        path: `/api/company/${companyId}/user`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Company
     * @name CreateCompanyUser
     * @summary Creates a user for the company
     * @request POST:/api/company/{companyId}/user
     * @secure
     */
    createCompanyUser: (companyId: number, data: CompanyUserCreationRequest, params: RequestParams = {}) =>
      this.request<CompanyUserResponse, void>({
        path: `/api/company/${companyId}/user`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name RecreateCompanyUser
     * @summary Recreates the user with associated companies to resend invitation
     * @request POST:/api/company/recreate
     * @secure
     */
    recreateCompanyUser: (data: CompanyUserCreationRequest, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/company/recreate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Company
     * @name GetCompanyUserById
     * @summary Retrieves the specified company's users
     * @request GET:/api/company/{companyId}/user/{companyUserId}
     * @secure
     */
    getCompanyUserById: (companyId: number, companyUserId: number, params: RequestParams = {}) =>
      this.request<CompanyUserResponse, void>({
        path: `/api/company/${companyId}/user/${companyUserId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_managing_director,mw_vp,admin</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Company
     * @name DeleteCompanyUser
     * @summary Deletes a company user
     * @request DELETE:/api/company/{companyId}/user/{companyUserId}
     * @secure
     */
    deleteCompanyUser: (companyId: number, companyUserId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/user/${companyUserId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_managing_director,mw_vp,admin</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Company
     * @name UpdateCompanyUser
     * @summary Updates a specified company's user  by their company user Id
     * @request PUT:/api/company/{companyId}/user/{companyUserId}
     * @secure
     */
    updateCompanyUser: (
      companyId: number,
      companyUserId: number,
      data: CompanyUserUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/user/${companyUserId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocationContact
     * @name GetCompanyLocationContactById
     * @summary Retrieves the specified company location contact
     * @request GET:/api/company/{companyId}/location/{locationId}/contact/{contactId}
     * @secure
     */
    getCompanyLocationContactById: (
      companyId: number,
      locationId: number,
      contactId: number,
      params: RequestParams = {},
    ) =>
      this.request<CompanyLocationContactResponse, void>({
        path: `/api/company/${companyId}/location/${locationId}/contact/${contactId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocationContact
     * @name UpdateCompanyLocationContact
     * @summary Updates the specified company location contact
     * @request PUT:/api/company/{companyId}/location/{locationId}/contact/{contactId}
     * @secure
     */
    updateCompanyLocationContact: (
      companyId: number,
      locationId: number,
      contactId: number,
      data: CompanyLocationContactUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/location/${locationId}/contact/${contactId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocationContact
     * @name DeleteLocationContact
     * @summary Deletes the specified company location contact
     * @request DELETE:/api/company/{companyId}/location/{locationId}/contact/{contactId}
     * @secure
     */
    deleteLocationContact: (companyId: number, locationId: number, contactId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/location/${locationId}/contact/${contactId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocationContact
     * @name GetCompanyLocationContacts
     * @summary Retrieves the company location's active contacts
     * @request GET:/api/company/{companyId}/location/{locationId}/contact
     * @secure
     */
    getCompanyLocationContacts: (companyId: number, locationId: number, params: RequestParams = {}) =>
      this.request<CompanyLocationContactResponse[], void>({
        path: `/api/company/${companyId}/location/${locationId}/contact`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocationContact
     * @name CreateCompanyLocationContact
     * @summary Creates a new company location contact
     * @request POST:/api/company/{companyId}/location/{locationId}/contact
     * @secure
     */
    createCompanyLocationContact: (
      companyId: number,
      locationId: number,
      data: CompanyLocationContactCreationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CompanyLocationContactResponse, void>({
        path: `/api/company/${companyId}/location/${locationId}/contact`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocationSeed
     * @name GetCompanyLocationSeedById
     * @summary Retrieves the specified company location seed
     * @request GET:/api/company/{companyId}/location/{locationId}/seed/{seedId}
     * @secure
     */
    getCompanyLocationSeedById: (companyId: number, locationId: number, seedId: number, params: RequestParams = {}) =>
      this.request<CompanyLocationSeedResponse, void>({
        path: `/api/company/${companyId}/location/${locationId}/seed/${seedId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocationSeed
     * @name UpdateCompanyLocationSeed
     * @summary Updates the specified company location seed
     * @request PUT:/api/company/{companyId}/location/{locationId}/seed/{seedId}
     * @secure
     */
    updateCompanyLocationSeed: (
      companyId: number,
      locationId: number,
      seedId: number,
      data: CompanyLocationSeedUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/location/${locationId}/seed/${seedId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocationSeed
     * @name DeleteLocationSeed
     * @summary Deletes the specified company location seed
     * @request DELETE:/api/company/{companyId}/location/{locationId}/seed/{seedId}
     * @secure
     */
    deleteLocationSeed: (companyId: number, locationId: number, seedId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/location/${locationId}/seed/${seedId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocationSeed
     * @name GetCompanyLocationSeeds
     * @summary Retrieves the company location's active seeds
     * @request GET:/api/company/{companyId}/location/{locationId}/seed
     * @secure
     */
    getCompanyLocationSeeds: (companyId: number, locationId: number, params: RequestParams = {}) =>
      this.request<CompanyLocationSeedResponse[], void>({
        path: `/api/company/${companyId}/location/${locationId}/seed`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags CompanyLocationSeed
     * @name CreateCompanyLocationSeed
     * @summary Creates a new company location seed
     * @request POST:/api/company/{companyId}/location/{locationId}/seed
     * @secure
     */
    createCompanyLocationSeed: (
      companyId: number,
      locationId: number,
      data: CompanyLocationSeedCreationRequest,
      params: RequestParams = {},
    ) =>
      this.request<CompanyLocationSeedResponse, void>({
        path: `/api/company/${companyId}/location/${locationId}/seed`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of SIC codes. If the user has an authorized Mailworks role, all SIC codes are returned
     *
     * @tags SicCode
     * @name GetSicCodes
     * @summary Provides a list of SIC codes.
     * @request GET:/api/company/siccodes
     * @secure
     */
    getSicCodes: (params: RequestParams = {}) =>
      this.request<SicCodeResponse[], any>({
        path: `/api/company/siccodes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_production,mw_vp,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Company
     * @name DownloadCompanyUsersCsv
     * @summary Returns company user list as csv.
     * @request GET:/api/company/{companyId}/downloadusers
     * @secure
     */
    downloadCompanyUsersCsv: (companyId: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/company/${companyId}/downloadusers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_accounting,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name CreateCredit
     * @summary Creates a credit and associates it with a company.
     * @request POST:/api/discountcredit/credit
     * @secure
     */
    createCredit: (data: CreditRequest, params: RequestParams = {}) =>
      this.request<CreditResponse, void>({
        path: `/api/discountcredit/credit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_accounting,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name UpdateCredit
     * @summary Updates a credit.
     * @request PUT:/api/discountcredit/credit
     * @secure
     */
    updateCredit: (data: CreditRequest, params: RequestParams = {}) =>
      this.request<CreditResponse, void>({
        path: `/api/discountcredit/credit`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_accounting,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name DeleteCredit
     * @summary Deletes a credit.
     * @request DELETE:/api/discountcredit/credit
     * @secure
     */
    deleteCredit: (
      query?: {
        /** @format int32 */
        companyCreditId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<CreditResponse, void>({
        path: `/api/discountcredit/credit`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_accounting,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name CreateDiscount
     * @summary Creates a discount and associates it with a company.
     * @request POST:/api/discountcredit/discount
     * @secure
     */
    createDiscount: (data: DiscountRequest, params: RequestParams = {}) =>
      this.request<DiscountResponse, void>({
        path: `/api/discountcredit/discount`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_accounting,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name UpdateDiscount
     * @summary Updates a discount by its ID.
     * @request PUT:/api/discountcredit/discount
     * @secure
     */
    updateDiscount: (data: DiscountRequest, params: RequestParams = {}) =>
      this.request<DiscountResponse, void>({
        path: `/api/discountcredit/discount`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_accounting,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name DeleteDiscount
     * @summary Deletes a discount.
     * @request DELETE:/api/discountcredit/discount
     * @secure
     */
    deleteDiscount: (
      query?: {
        /** @format int32 */
        discountId?: number
        /** @format int32 */
        companyId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<DiscountResponse, void>({
        path: `/api/discountcredit/discount`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_accounting,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name ReenableCredit
     * @summary Re-enables a credit.
     * @request GET:/api/discountcredit/reenablecredit
     * @secure
     */
    reenableCredit: (
      query?: {
        /** @format int32 */
        companyCreditId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<CreditResponse, void>({
        path: `/api/discountcredit/reenablecredit`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_accounting,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name ReenableDiscount
     * @summary Re-enables a discount.
     * @request GET:/api/discountcredit/reenablediscount
     * @secure
     */
    reenableDiscount: (
      query?: {
        /** @format int32 */
        discountId?: number
        /** @format int32 */
        companyId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<DiscountResponse, void>({
        path: `/api/discountcredit/reenablediscount`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_accounting,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name GetCredit
     * @summary Gets a credit by its ID.
     * @request GET:/api/discountcredit/credit/{creditId}
     * @secure
     */
    getCredit: (creditId: number, params: RequestParams = {}) =>
      this.request<DiscountResponse, void>({
        path: `/api/discountcredit/credit/${creditId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_accounting,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name GetDiscount
     * @summary Gets a discount by its ID.
     * @request GET:/api/discountcredit/discount/{companyId}/{discountId}
     * @secure
     */
    getDiscount: (companyId: number, discountId: number, params: RequestParams = {}) =>
      this.request<DiscountResponse, void>({
        path: `/api/discountcredit/discount/${companyId}/${discountId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_accounting,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name GetCredits
     * @summary Gets all credits, filterable.
     * @request GET:/api/discountcredit/credits/{companyId}
     * @secure
     */
    getCredits: (
      companyId: string,
      query?: {
        search?: string
        /**
         * @format int32
         * @default 0
         */
        filter?: number
        /**
         * @format int32
         * @default 0
         */
        companyId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<CreditResponse[], void>({
        path: `/api/discountcredit/credits/${companyId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_accounting,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name GetDiscounts
     * @summary Gets all discounts, filterable.
     * @request GET:/api/discountcredit/discounts/{companyId}
     * @secure
     */
    getDiscounts: (
      companyId: string,
      query?: {
        search?: string
        /**
         * @format int32
         * @default 0
         */
        filter?: number
        /**
         * @format int32
         * @default 0
         */
        companyId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<DiscountResponse[], void>({
        path: `/api/discountcredit/discounts/${companyId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_accounting,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name GetDiscountReasons
     * @summary Gets the list of possible discount reasons.
     * @request GET:/api/discountcredit/discountreasons
     * @secure
     */
    getDiscountReasons: (params: RequestParams = {}) =>
      this.request<DiscountReason[], void>({
        path: `/api/discountcredit/discountreasons`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_accounting,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags DiscountCredit
     * @name GetCreditReasons
     * @summary Gets the list of possible credit reasons.
     * @request GET:/api/discountcredit/creditreasons
     * @secure
     */
    getCreditReasons: (params: RequestParams = {}) =>
      this.request<CreditReason[], void>({
        path: `/api/discountcredit/creditreasons`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DiscountCredit
     * @name DownloadDiscountsCsv
     * @summary Returns Discount list as csv.
     * @request GET:/api/discountcredit/discountdownload
     * @secure
     */
    downloadDiscountsCsv: (
      query?: {
        /** @default "" */
        search?: string
        /**
         * @format int32
         * @default 0
         */
        filter?: number
        /**
         * @format int32
         * @default 0
         */
        companyId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/discountcredit/discountdownload`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DiscountCredit
     * @name DownloadCreditsCsv
     * @summary Returns Credit list as csv.
     * @request GET:/api/discountcredit/creditdownload
     * @secure
     */
    downloadCreditsCsv: (
      query?: {
        /** @default "" */
        search?: string
        /**
         * @format int32
         * @default 0
         */
        filter?: number
        /**
         * @format int32
         * @default 0
         */
        companyId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/discountcredit/creditdownload`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Invoice
     * @name CreateInvoice
     * @summary Creates an invoice.
     * @request POST:/api/invoice
     * @secure
     */
    createInvoice: (data: InvoiceGeneratorRequest, params: RequestParams = {}) =>
      this.request<InvoiceResponse, void>({
        path: `/api/invoice`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Invoice
     * @name GetInvoices
     * @summary Gets invoices.
     * @request GET:/api/invoice
     * @secure
     */
    getInvoices: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        companyId?: number
        /**
         * @format int32
         * @default 0
         */
        invoiceStatus?: number
        /** @default "" */
        search?: string
        /** @default false */
        showHistory?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceResponse[], void>({
        path: `/api/invoice`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Invoice
     * @name CreateInvoiceFromOrder
     * @summary Creates an invoice from order.
     * @request POST:/api/invoice/fromorder
     * @secure
     */
    createInvoiceFromOrder: (
      query?: {
        /** @format uuid */
        orderId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceResponse, void>({
        path: `/api/invoice/fromorder`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Invoice
     * @name GetInvoice
     * @summary Gets an invoice by id.
     * @request GET:/api/invoice/{invoiceId}
     * @secure
     */
    getInvoice: (invoiceId: string, params: RequestParams = {}) =>
      this.request<InvoiceResponse, void>({
        path: `/api/invoice/${invoiceId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Invoice
     * @name EditInvoice
     * @summary Edits an invoice.
     * @request POST:/api/invoice/{invoiceId}
     * @secure
     */
    editInvoice: (invoiceId: string, data: InvoiceGeneratorRequest, params: RequestParams = {}) =>
      this.request<InvoiceResponse, void>({
        path: `/api/invoice/${invoiceId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Invoice
     * @name GetInvoiceDropdowns
     * @summary Gets invoice related dropdowns.
     * @request POST:/api/invoice/dropdown
     * @secure
     */
    getInvoiceDropdowns: (params: RequestParams = {}) =>
      this.request<InvoiceDropDowns, any>({
        path: `/api/invoice/dropdown`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Invoice
     * @name InvalidateExistingInvoicesByGuid
     * @summary Makes all invoices by guid non-current
     * @request GET:/api/invoice/invalidate/{invoiceId}
     * @secure
     */
    invalidateExistingInvoicesByGuid: (invoiceId: string, params: RequestParams = {}) =>
      this.request<boolean, void>({
        path: `/api/invoice/invalidate/${invoiceId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Invoice
     * @name GetInvoiceTransactionTypes
     * @summary Gets invoice Transaction Types.
     * @request GET:/api/invoice/transactiontype
     * @secure
     */
    getInvoiceTransactionTypes: (
      query?: {
        /** @default "" */
        searchString?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceTransactionType[], any>({
        path: `/api/invoice/transactiontype`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Invoice
     * @name GetInvoiceTypes
     * @summary Gets invoice types.
     * @request GET:/api/invoice/invoicetypes
     * @secure
     */
    getInvoiceTypes: (
      query?: {
        /** @default "" */
        searchString?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceType[], any>({
        path: `/api/invoice/invoicetypes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Invoice
     * @name AddInvoiceTransaction
     * @summary Adds a transaction to an invoice
     * @request POST:/api/invoice/addtransaction
     * @secure
     */
    addInvoiceTransaction: (data: InvoiceTransactionRequest, params: RequestParams = {}) =>
      this.request<InvoiceResponse, void>({
        path: `/api/invoice/addtransaction`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Invoice
     * @name CancelInvoice
     * @summary Cancels an invoice
     * @request POST:/api/invoice/cancelinvoice
     * @secure
     */
    cancelInvoice: (
      query?: {
        /** @format int32 */
        invoiceId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceResponse, void>({
        path: `/api/invoice/cancelinvoice`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Invoice
     * @name GetInvoiceTable
     * @summary Get invoices as service and postage pairs
     * @request GET:/api/invoice/invoicetable
     * @secure
     */
    getInvoiceTable: (
      query?: {
        /** @default true */
        currentOnly?: boolean
        /** @format uuid */
        serviceGuid?: string
        /** @format uuid */
        postageGuid?: string
        /** @format int32 */
        companyId?: number
        /** @format int32 */
        paymentStatusId?: number
        searchString?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceTableRecord[], any>({
        path: `/api/invoice/invoicetable`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name GetLists
     * @summary Provides a list of Lists for the Company.
     * @request GET:/api/company/{companyId}/list
     * @secure
     */
    getLists: (
      companyId: number,
      query?: {
        /** @default "" */
        listStage?: string
        /** @default true */
        includeSuppressionList?: boolean
        /** @default false */
        active?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<ListResponse[], void>({
        path: `/api/company/${companyId}/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name CreateList
     * @summary Creates the List
     * @request POST:/api/company/{companyId}/list
     * @secure
     */
    createList: (companyId: number, data: ListCreationRequest, params: RequestParams = {}) =>
      this.request<ListResponse, void>({
        path: `/api/company/${companyId}/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name GetListStages
     * @summary Provides a list of List Stages.
     * @request GET:/api/company/{companyId}/list/liststages
     * @secure
     */
    getListStages: (companyId: number, params: RequestParams = {}) =>
      this.request<ListStage[], any>({
        path: `/api/company/${companyId}/list/liststages`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name GetListById
     * @summary Retrieves the specified List
     * @request GET:/api/company/{companyId}/list/{listId}
     * @secure
     */
    getListById: (companyId: number, listId: number, params: RequestParams = {}) =>
      this.request<ListResponse, void>({
        path: `/api/company/${companyId}/list/${listId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name UpdateList
     * @summary Updates the specified List
     * @request PUT:/api/company/{companyId}/list/{listId}
     * @secure
     */
    updateList: (companyId: number, listId: number, data: ListUpdateRequest, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/list/${listId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name CreateSuppressionList
     * @summary Creates a Company Suppression List
     * @request POST:/api/company/{companyId}/list/suppression
     * @secure
     */
    createSuppressionList: (companyId: number, params: RequestParams = {}) =>
      this.request<ListResponse, void>({
        path: `/api/company/${companyId}/list/suppression`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name GetTotalListRecipients
     * @summary Retrieves the recipients for a List
     * @request GET:/api/company/{companyId}/list/{listId}/totallistrecipients
     * @secure
     */
    getTotalListRecipients: (companyId: number, listId: number, params: RequestParams = {}) =>
      this.request<number, void>({
        path: `/api/company/${companyId}/list/${listId}/totallistrecipients`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name GetListRecipients
     * @summary Retrieves the recipients for a List
     * @request GET:/api/company/{companyId}/list/{listId}/recipients
     * @secure
     */
    getListRecipients: (
      companyId: number,
      listId: number,
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ListRecipientResponse[], void>({
        path: `/api/company/${companyId}/list/${listId}/recipients`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name CreateListRecipient
     * @summary Creates recipients for a List
     * @request POST:/api/company/{companyId}/list/{listId}/recipients
     * @secure
     */
    createListRecipient: (
      companyId: number,
      listId: number,
      data: CreateListRecipientRequest,
      params: RequestParams = {},
    ) =>
      this.request<ListRecipientResponse, void>({
        path: `/api/company/${companyId}/list/${listId}/recipients`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name UpdateListRecipient
     * @summary Updates recipients for a List
     * @request PUT:/api/company/{companyId}/list/{listId}/recipients
     * @secure
     */
    updateListRecipient: (
      companyId: number,
      listId: number,
      data: UpdateListRecipientRequest,
      params: RequestParams = {},
    ) =>
      this.request<ListRecipientResponse, void>({
        path: `/api/company/${companyId}/list/${listId}/recipients`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name DeleteListRecipient
     * @summary Delete recipients for a List
     * @request DELETE:/api/company/{companyId}/list/{listId}/recipients
     * @secure
     */
    deleteListRecipient: (
      companyId: number,
      listId: number,
      data: DeleteListRecipientRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/list/${listId}/recipients`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags ListFile
     * @name GetListFileS3UploadUrl
     * @summary Gets the URL for the list file to be uploaded to
     * @request GET:/api/company/{companyId}/list/{listId}/list-file/{listFileId}/s3upload
     * @secure
     */
    getListFileS3UploadUrl: (companyId: number, listId: number, listFileId: number, params: RequestParams = {}) =>
      this.request<S3UploadResponse, void>({
        path: `/api/company/${companyId}/list/${listId}/list-file/${listFileId}/s3upload`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags ListFile
     * @name GetListFileS3DownloadUrl
     * @summary Gets the URL for the list file to be downloaded
     * @request GET:/api/company/{companyId}/list/{listId}/list-file/{listFileId}/s3download
     * @secure
     */
    getListFileS3DownloadUrl: (
      companyId: number,
      listId: number,
      listFileId: number,
      query?: {
        fileName?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<S3UploadResponse, void>({
        path: `/api/company/${companyId}/list/${listId}/list-file/${listFileId}/s3download`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags ListFile
     * @name GetListFileReport
     * @summary Gets the URL for the list file report
     * @request GET:/api/company/{companyId}/list/{listId}/list-file/{listFileId}/report
     * @secure
     */
    getListFileReport: (companyId: number, listId: number, listFileId: number, params: RequestParams = {}) =>
      this.request<S3UploadResponse, void>({
        path: `/api/company/${companyId}/list/${listId}/list-file/${listFileId}/report`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags ListValidationSettings
     * @name CreateListValidationSettingByListId
     * @summary Creates a List Validation Setting by List Id
     * @request POST:/api/company/{companyId}/list/{listId}/list-validation-setting
     * @secure
     */
    createListValidationSettingByListId: (
      companyId: number,
      listId: number,
      data: UserSelection[],
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/list/${listId}/list-validation-setting`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags ListValidationSettings
     * @name CreateListValidationSettingByListFileId
     * @summary Creates a List Validation Setting by List File Id
     * @request POST:/api/company/{companyId}/list/{listId}/list-file/{listFileId}/list-validation-setting
     * @secure
     */
    createListValidationSettingByListFileId: (
      listId: number,
      companyId: number,
      listFileId: number,
      data: UserSelection[],
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/list/${listId}/list-file/${listFileId}/list-validation-setting`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags ListFile
     * @name GetListFiles
     * @summary Gets a list of List files and their attributes
     * @request GET:/api/company/{companyId}/list/{listId}/list-file
     * @secure
     */
    getListFiles: (companyId: number, listId: number, params: RequestParams = {}) =>
      this.request<ListFileResponse[], any>({
        path: `/api/company/${companyId}/list/${listId}/list-file`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags ListFile
     * @name CreateListFile
     * @summary Creates a List File
     * @request POST:/api/company/{companyId}/list/{listId}/list-file
     * @secure
     */
    createListFile: (companyId: number, listId: number, data: ListFileCreationRequest, params: RequestParams = {}) =>
      this.request<ListFileResponse, void>({
        path: `/api/company/${companyId}/list/${listId}/list-file`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags ListFile
     * @name GetListFileById
     * @summary Gets a single List file
     * @request GET:/api/company/{companyId}/list/{listId}/list-file/{listFileId}
     * @secure
     */
    getListFileById: (companyId: number, listFileId: number, listId: number, params: RequestParams = {}) =>
      this.request<ListFileResponse, void>({
        path: `/api/company/${companyId}/list/${listId}/list-file/${listFileId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags ListFile
     * @name UpdateListFile
     * @summary Update a List File
     * @request PUT:/api/company/{companyId}/list/{listId}/list-file/{listFileId}
     * @secure
     */
    updateListFile: (
      companyId: number,
      listId: number,
      listFileId: number,
      data: ListFileUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ListFileResponse, void>({
        path: `/api/company/${companyId}/list/${listId}/list-file/${listFileId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name GetListColumnHeader
     * @summary Gets a List Column Header.
     * @request GET:/api/company/{companyId}/list/list-column
     * @secure
     */
    getListColumnHeader: (
      companyId: number,
      query?: {
        /** @format int32 */
        listId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<ListColumnResponse[], void>({
        path: `/api/company/${companyId}/list/list-column`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name CreateListColumnHeader
     * @summary Creates a new List Column Header.
     * @request POST:/api/company/{companyId}/list/list-column
     * @secure
     */
    createListColumnHeader: (companyId: number, data: ListColumnCreationRequest[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/list/list-column`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name GetListColumnMapping
     * @summary Gets a List Column Mapping.
     * @request GET:/api/company/{companyId}/list/list-mapping
     * @secure
     */
    getListColumnMapping: (
      companyId: number,
      query?: {
        /** @format int32 */
        listId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<ListColumnMappingConfigResponse[], void>({
        path: `/api/company/${companyId}/list/list-mapping`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name CreateListColumnMapping
     * @summary Creates a new List Column Mapping.
     * @request POST:/api/company/{companyId}/list/list-mapping
     * @secure
     */
    createListColumnMapping: (
      companyId: number,
      data: ListColumnMappingConfigCreationRequest[],
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/list/list-mapping`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags List
     * @name DeleteList
     * @summary Marks a List for Deletion
     * @request POST:/api/company/{companyId}/list/delete-list
     * @secure
     */
    deleteList: (
      companyId: number,
      query?: {
        /** @format int32 */
        listId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/list/delete-list`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Order
     * @name CreateOrder
     * @summary Creates a new order.
     * @request POST:/api/company/{companyId}/order
     * @secure
     */
    createOrder: (
      companyId: number,
      query?: {
        /** @format int32 */
        campaignId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderResponse, void>({
        path: `/api/company/${companyId}/order`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Order
     * @name GetOrder
     * @summary Provides a specific order.
     * @request GET:/api/company/{companyId}/order/{orderId}
     * @secure
     */
    getOrder: (orderId: string, companyId: number, params: RequestParams = {}) =>
      this.request<OrderResponse, void>({
        path: `/api/company/${companyId}/order/${orderId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,admin,approver,billing,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>billing</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Order
     * @name GetOrders
     * @summary Fetches all orders for a given company and campagin
     * @request GET:/api/company/{companyId}/order/campaignorders
     * @secure
     */
    getOrders: (
      companyId: string,
      query?: {
        /** @format int32 */
        campaignId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderResponse[], void>({
        path: `/api/company/${companyId}/order/campaignorders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireAuthenticatedUser</b>
     *
     * @tags Payment
     * @name GetPaymentMethod
     * @summary Fetches a payment method.
     * @request GET:/api/payment/paymentmethod/{paymentMethodId}
     * @secure
     */
    getPaymentMethod: (paymentMethodId: number, params: RequestParams = {}) =>
      this.request<PaymentMethodResponse, void>({
        path: `/api/payment/paymentmethod/${paymentMethodId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireAuthenticatedUser</b>
     *
     * @tags Payment
     * @name DeletePaymentMethod
     * @summary Deletes a payment method.
     * @request DELETE:/api/payment/paymentmethod/{paymentMethodId}
     * @secure
     */
    deletePaymentMethod: (paymentMethodId: number, params: RequestParams = {}) =>
      this.request<PaymentMethodResponse, void>({
        path: `/api/payment/paymentmethod/${paymentMethodId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireAuthenticatedUser</b>
     *
     * @tags Payment
     * @name GetTransaction
     * @summary Fetches a transaction.
     * @request GET:/api/payment/transaction/{transactionId}
     * @secure
     */
    getTransaction: (transactionId: number, params: RequestParams = {}) =>
      this.request<PaymentMethodResponse, void>({
        path: `/api/payment/transaction/${transactionId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireAuthenticatedUser</b>
     *
     * @tags Payment
     * @name GetUsersPaymentMethods
     * @summary Fetches a user's payment methods.
     * @request GET:/api/payment/paymentmethods/{companyUserId}
     * @secure
     */
    getUsersPaymentMethods: (companyUserId: number, params: RequestParams = {}) =>
      this.request<PaymentMethodResponse, any>({
        path: `/api/payment/paymentmethods/${companyUserId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireAuthenticatedUser</b>
     *
     * @tags Payment
     * @name GetUsersPaymentMethods2
     * @summary Fetches a user's payment methods.
     * @request GET:/api/payment/paymentmethods/{userId}/{companyId}
     * @originalName getUsersPaymentMethods
     * @duplicate
     * @secure
     */
    getUsersPaymentMethods2: (userId: number, companyId: number, params: RequestParams = {}) =>
      this.request<PaymentMethodResponse, any>({
        path: `/api/payment/paymentmethods/${userId}/${companyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireAuthenticatedUser</b>
     *
     * @tags Payment
     * @name CreatePaymentMethod
     * @summary Creates a payment method.
     * @request POST:/api/payment/paymentmethod
     * @secure
     */
    createPaymentMethod: (data: PaymentMethodRequest, params: RequestParams = {}) =>
      this.request<PaymentMethodResponse, void>({
        path: `/api/payment/paymentmethod`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireAuthenticatedUser</b>
     *
     * @tags Payment
     * @name UpdatePaymentMethod
     * @summary Updates a user's primary payment method.
     * @request PUT:/api/payment/paymentmethod
     * @secure
     */
    updatePaymentMethod: (
      query?: {
        /** @format int32 */
        companyUserId?: number
        /** @format int32 */
        paymentMethodId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<PaymentMethodResponse[], any>({
        path: `/api/payment/paymentmethod`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireAuthenticatedUser</b>
     *
     * @tags Payment
     * @name SubmitPayment
     * @summary Submits a payment for processing
     * @request POST:/api/payment
     * @secure
     */
    submitPayment: (data: ProcessPaymentRequest, params: RequestParams = {}) =>
      this.request<PaymentTransaction, void>({
        path: `/api/payment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireAuthenticatedUser</b>
     *
     * @tags Payment
     * @name RefundPayment
     * @summary Refund a payment
     * @request POST:/api/payment/refund
     * @secure
     */
    refundPayment: (
      query?: {
        /** @format int32 */
        transactionId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<PaymentTransaction, void>({
        path: `/api/payment/refund`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireAuthenticatedUser</b>
     *
     * @tags Payment
     * @name VoidPayment
     * @summary Void a payment or reverse an authorization
     * @request POST:/api/payment/void
     * @secure
     */
    voidPayment: (
      query?: {
        /** @format int32 */
        transactionId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<PaymentTransaction, void>({
        path: `/api/payment/void`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireAuthenticatedUser</b>
     *
     * @tags Payment
     * @name CreditPayment
     * @summary Credit
     * @request POST:/api/payment/credit
     * @secure
     */
    creditPayment: (data: PaymentCreditRequest, params: RequestParams = {}) =>
      this.request<PaymentTransaction, void>({
        path: `/api/payment/credit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireAuthenticatedUser</b>
     *
     * @tags Payment
     * @name GetAccountTypes
     * @summary Fetches a account types.
     * @request GET:/api/payment/accounttypes
     * @secure
     */
    getAccountTypes: (params: RequestParams = {}) =>
      this.request<PaymentMethodAccountType[], any>({
        path: `/api/payment/accounttypes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProducts
     * @summary Provides a list of Products.
     * @request GET:/api/products
     * @secure
     */
    getProducts: (
      query?: {
        /** @default "" */
        search?: string
        filter?: boolean
        /**
         * @format int32
         * @default 1
         */
        productTypeId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductResponse[], void>({
        path: `/api/products`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name UpdateProduct
     * @summary Updates the specified Product
     * @request PUT:/api/products
     * @secure
     */
    updateProduct: (data: ProductUpdateRequest, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/api/products`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name CreateProduct
     * @summary Creates the Product
     * @request POST:/api/products
     * @secure
     */
    createProduct: (data: ProductCreateRequest, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/api/products`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name DownloadProductsCsv
     * @summary Returns Product list as csv.
     * @request GET:/api/products/products/download
     * @secure
     */
    downloadProductsCsv: (
      query?: {
        /** @default "" */
        search?: string
        filter?: boolean
        /**
         * @format int32
         * @default 1
         */
        productTypeId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/products/products/download`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProductProductionNames
     * @summary Returns Product Production Names list.
     * @request GET:/api/products/products/productionnames
     * @secure
     */
    getProductProductionNames: (
      query?: {
        /** @default "" */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/products/products/productionnames`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProductById
     * @summary Retrieves the specified Product
     * @request GET:/api/products/{productId}
     * @secure
     */
    getProductById: (productId: string, params: RequestParams = {}) =>
      this.request<ProductResponse, void>({
        path: `/api/products/${productId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name ProductActivation
     * @summary Sets active status for a Product.
     * @request POST:/api/products/activation
     * @secure
     */
    productActivation: (
      query?: {
        /** @format uuid */
        productId?: string
        activate?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/products/activation`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name DuplicateProduct
     * @summary Creates a Duplicate Product.
     * @request POST:/api/products/duplicate
     * @secure
     */
    duplicateProduct: (
      query?: {
        /** @format uuid */
        productId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/products/duplicate`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name DeleteStockImg
     * @summary Deletes a Product Stock Image.
     * @request PUT:/api/products/deletestockimg
     * @secure
     */
    deleteStockImg: (
      query?: {
        /** @format uuid */
        productId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/products/deletestockimg`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product Categories
     * @name GetProductCategories
     * @summary Provides a list of Product Categories.
     * @request GET:/api/products/categories
     * @secure
     */
    getProductCategories: (
      query?: {
        /** @default "" */
        search?: string
        filter?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductCategoryResponse[], void>({
        path: `/api/products/categories`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Product Categories
     * @name CreateProductCategory
     * @summary Creates the Product Category
     * @request POST:/api/products/categories/create
     * @secure
     */
    createProductCategory: (data: ProductCategoryCreateRequest, params: RequestParams = {}) =>
      this.request<ProductCategoryResponse, void>({
        path: `/api/products/categories/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Product Categories
     * @name UpdateProductCategory
     * @summary Updates a Product Category
     * @request POST:/api/products/categories/update
     * @secure
     */
    updateProductCategory: (data: ProductCategoryUpdateRequest, params: RequestParams = {}) =>
      this.request<ProductCategoryResponse, void>({
        path: `/api/products/categories/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_vp</b>
     *
     * @tags Product Categories
     * @name ProductCategoryActivation
     * @summary Sets active status for a Product Category.
     * @request POST:/api/products/categories/activation
     * @secure
     */
    productCategoryActivation: (
      query?: {
        /** @format int32 */
        productCategoryId?: number
        activate?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/products/categories/activation`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product Categories
     * @name DownloadProductCategoriesCsv
     * @summary Returns Product Categories list as csv.
     * @request GET:/api/products/categories/download
     * @secure
     */
    downloadProductCategoriesCsv: (
      query?: {
        /** @default "" */
        search?: string
        filter?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/products/categories/download`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product Categories
     * @name ProductCheckForCategoryUpdate
     * @summary Returns Products that do not have Envelope or Paperstock when updating the Product Category to require this.
     * @request GET:/api/products/categories/productcheck
     * @secure
     */
    productCheckForCategoryUpdate: (
      query?: {
        /** @format int32 */
        productCategoryId?: number
        requiresEnvelope?: boolean
        requiresPaperStock?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/products/categories/productcheck`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Paper Stocks
     * @name GetPaperStockTypes
     * @summary Provides a list of Paper Stock Types.
     * @request GET:/api/products/paperstock-types
     * @secure
     */
    getPaperStockTypes: (
      query?: {
        /** @default "" */
        search?: string
        filter?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<PaperStockTypeResponse[], void>({
        path: `/api/products/paperstock-types`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Paper Stocks
     * @name CreatePaperStockType
     * @summary Creates the PaperStock Type
     * @request POST:/api/products/paperstock-type/create
     * @secure
     */
    createPaperStockType: (data: PaperStockTypeCreateRequest, params: RequestParams = {}) =>
      this.request<PaperStockTypeResponse, void>({
        path: `/api/products/paperstock-type/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Paper Stocks
     * @name UpdatePaperStockType
     * @summary Updates a Paper Stock Type Record
     * @request POST:/api/products/paperstock-type/update
     * @secure
     */
    updatePaperStockType: (data: PaperStockTypeUpdateRequest, params: RequestParams = {}) =>
      this.request<PaperStockTypeResponse, void>({
        path: `/api/products/paperstock-type/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_vp</b>
     *
     * @tags Paper Stocks
     * @name PaperStockTypeActivation
     * @summary Sets active status for a Paper Stock Type.
     * @request PUT:/api/products/paperstock-type/activation
     * @secure
     */
    paperStockTypeActivation: (
      query?: {
        /** @format int32 */
        paperStockTypeId?: number
        activate?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/products/paperstock-type/activation`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Paper Stocks
     * @name GetPaperStocks
     * @summary Provides a list of Paper Stocks.
     * @request GET:/api/products/paperstock
     * @secure
     */
    getPaperStocks: (
      query?: {
        /** @default "" */
        search?: string
        filter?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<PaperStockResponse[], void>({
        path: `/api/products/paperstock`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Paper Stocks
     * @name CreatePaperStock
     * @summary Creates the PaperStock
     * @request POST:/api/products/paperstock/create
     * @secure
     */
    createPaperStock: (data: PaperStockCreateRequest, params: RequestParams = {}) =>
      this.request<PaperStockResponse, void>({
        path: `/api/products/paperstock/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Paper Stocks
     * @name UpdatePaperStock
     * @summary Updates a Paper Stock Record
     * @request POST:/api/products/paperstock/update
     * @secure
     */
    updatePaperStock: (data: PaperStockUpdateRequest, params: RequestParams = {}) =>
      this.request<PaperStockResponse, void>({
        path: `/api/products/paperstock/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_vp</b>
     *
     * @tags Paper Stocks
     * @name PaperStockActivation
     * @summary Sets active status for a Paper Stock.
     * @request PUT:/api/products/paperstock/activation
     * @secure
     */
    paperStockActivation: (
      query?: {
        /** @format int32 */
        paperStockId?: number
        activate?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/products/paperstock/activation`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product Categories
     * @name DownloadPaperStocksCsv
     * @summary Returns Paper Stocks list as csv.
     * @request GET:/api/products/paperstock/download
     * @secure
     */
    downloadPaperStocksCsv: (
      query?: {
        /** @default "" */
        search?: string
        filter?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/products/paperstock/download`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Envelopes
     * @name GetEnvelopes
     * @summary Provides a list of Envelopes.
     * @request GET:/api/products/envelope
     * @secure
     */
    getEnvelopes: (
      query?: {
        /** @default "" */
        search?: string
        filter?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<EnvelopeResponse[], void>({
        path: `/api/products/envelope`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Envelopes
     * @name CreateEnvelope
     * @summary Creates the Envelope
     * @request POST:/api/products/envelope/create
     * @secure
     */
    createEnvelope: (data: EnvelopeCreateRequest, params: RequestParams = {}) =>
      this.request<EnvelopeResponse, void>({
        path: `/api/products/envelope/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Envelopes
     * @name UpdateEnvelope
     * @summary Updates an Envelope
     * @request POST:/api/products/envelope/update
     * @secure
     */
    updateEnvelope: (data: EnvelopeUpdateRequest, params: RequestParams = {}) =>
      this.request<EnvelopeResponse, void>({
        path: `/api/products/envelope/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_vp</b>
     *
     * @tags Envelopes
     * @name EnvelopeActivation
     * @summary Sets active status for an Envelope.
     * @request PUT:/api/products/envelope/activation
     * @secure
     */
    envelopeActivation: (
      query?: {
        /** @format int32 */
        envelopeId?: number
        activate?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/products/envelope/activation`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Envelopes
     * @name DownloadEnvelopesCsv
     * @summary Returns Envelopes list as csv.
     * @request GET:/api/products/envelope/download
     * @secure
     */
    downloadEnvelopesCsv: (
      query?: {
        /** @default "" */
        search?: string
        filter?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/products/envelope/download`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Envelopes
     * @name GetEnvelopeTypes
     * @summary Provides a list of Envelope Types.
     * @request GET:/api/products/envelope-type
     * @secure
     */
    getEnvelopeTypes: (
      query?: {
        /** @default "" */
        search?: string
        filter?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<EnvelopeTypeResponse[], void>({
        path: `/api/products/envelope-type`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Envelopes
     * @name CreateEnvelopeType
     * @summary Creates the Envelope Type
     * @request POST:/api/products/envelope-type/create
     * @secure
     */
    createEnvelopeType: (data: EnvelopeTypeCreateRequest, params: RequestParams = {}) =>
      this.request<EnvelopeTypeResponse, void>({
        path: `/api/products/envelope-type/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Envelopes
     * @name UpdateEnvelopeType
     * @summary Updates an Envelope Type
     * @request POST:/api/products/envelope-type/update
     * @secure
     */
    updateEnvelopeType: (data: EnvelopeTypeUpdateRequest, params: RequestParams = {}) =>
      this.request<EnvelopeTypeResponse, void>({
        path: `/api/products/envelope-type/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_vp</b>
     *
     * @tags Envelopes
     * @name EnvelopeTypeActivation
     * @summary Sets active status for an Envelope Type.
     * @request PUT:/api/products/envelope-type/activation
     * @secure
     */
    envelopeTypeActivation: (
      query?: {
        /** @format int32 */
        envelopeTypeId?: number
        activate?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/products/envelope-type/activation`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetPrimaryPrinters
     * @summary Provides a list of Primary Printers.
     * @request GET:/api/products/primaryprinters
     * @secure
     */
    getPrimaryPrinters: (params: RequestParams = {}) =>
      this.request<PrimaryPrinterResponse[], void>({
        path: `/api/products/primaryprinters`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name CreatePrimaryPrinter
     * @summary Creates the Primary Printer
     * @request POST:/api/products/primaryprinters/create
     * @secure
     */
    createPrimaryPrinter: (data: PrimaryPrinterCreateRequest, params: RequestParams = {}) =>
      this.request<PrimaryPrinterResponse, void>({
        path: `/api/products/primaryprinters/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name UpdatePrimaryPrinter
     * @summary Updates a Primary Printer
     * @request POST:/api/products/primaryprinters/update
     * @secure
     */
    updatePrimaryPrinter: (data: PrimaryPrinterUpdateRequest, params: RequestParams = {}) =>
      this.request<PrimaryPrinterResponse, void>({
        path: `/api/products/primaryprinters/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetCoverages
     * @summary Provides a list of Coverages.
     * @request GET:/api/products/coverages
     * @secure
     */
    getCoverages: (params: RequestParams = {}) =>
      this.request<CoverageResponse[], void>({
        path: `/api/products/coverages`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name CreateCoverage
     * @summary Creates the Coverage
     * @request POST:/api/products/coverages/create
     * @secure
     */
    createCoverage: (data: CoverageCreationRequest, params: RequestParams = {}) =>
      this.request<CoverageResponse, void>({
        path: `/api/products/coverages/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name UpdateCoverage
     * @summary Updates a Coverage
     * @request POST:/api/products/coverages/update
     * @secure
     */
    updateCoverage: (data: CoverageUpdateRequest, params: RequestParams = {}) =>
      this.request<CoverageResponse, void>({
        path: `/api/products/coverages/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetBinderyTypes
     * @summary Provides a list of Bindery Types.
     * @request GET:/api/products/bindery-types
     * @secure
     */
    getBinderyTypes: (params: RequestParams = {}) =>
      this.request<BinderyTypeResponse[], void>({
        path: `/api/products/bindery-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name CreateBinderyTypes
     * @summary Creates the Bindery Type
     * @request POST:/api/products/bindery-types/create
     * @secure
     */
    createBinderyTypes: (data: BinderyTypeCreationRequest, params: RequestParams = {}) =>
      this.request<BinderyTypeResponse, void>({
        path: `/api/products/bindery-types/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name UpdateBinderyType
     * @summary Updates a Bindery Type
     * @request POST:/api/products/bindery-types/update
     * @secure
     */
    updateBinderyType: (data: BinderyTypeUpdateRequest, params: RequestParams = {}) =>
      this.request<BinderyTypeResponse, void>({
        path: `/api/products/bindery-types/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProductTypes
     * @summary Provides a list of Product Types.
     * @request GET:/api/products/producttypes
     * @secure
     */
    getProductTypes: (params: RequestParams = {}) =>
      this.request<ProductTypeResponse[], void>({
        path: `/api/products/producttypes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProductHistorys
     * @summary Provides a list of Product Historys.
     * @request GET:/api/products/producthistorys
     * @secure
     */
    getProductHistorys: (
      query?: {
        /** @format uuid */
        productId?: string
        /** @format date-time */
        start?: string
        /** @format date-time */
        end?: string
        /** @default "" */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductHistoryResponse[], void>({
        path: `/api/products/producthistorys`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name DownloadProductHistorysCsv
     * @summary Returns Product History list as csv.
     * @request GET:/api/products/producthistorys/download
     * @secure
     */
    downloadProductHistorysCsv: (
      query?: {
        /** @format uuid */
        productId?: string
        /** @format date-time */
        start?: string
        /** @format date-time */
        end?: string
        /** @default "" */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/products/producthistorys/download`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Letter Shops
     * @name GetLetterShops
     * @summary Provides a list of Letter Shops.
     * @request GET:/api/products/lettershop
     * @secure
     */
    getLetterShops: (
      query?: {
        /** @default "" */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<LetterShopResponse[], void>({
        path: `/api/products/lettershop`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Letter Shops
     * @name CreateLetterShop
     * @summary Creates the Letter Shop
     * @request POST:/api/products/lettershop/create
     * @secure
     */
    createLetterShop: (data: LetterShopCreationRequest, params: RequestParams = {}) =>
      this.request<LetterShopResponse, void>({
        path: `/api/products/lettershop/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Letter Shops
     * @name UpdateLetterShop
     * @summary Updates a LetterShop
     * @request POST:/api/products/lettershop/update
     * @secure
     */
    updateLetterShop: (data: LetterShopUpdateRequest, params: RequestParams = {}) =>
      this.request<LetterShopResponse, void>({
        path: `/api/products/lettershop/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Letter Shop
     * @name DownloadLetterShopsCsv
     * @summary Returns Letter Shop list as csv.
     * @request GET:/api/products/lettershop/download
     * @secure
     */
    downloadLetterShopsCsv: (
      query?: {
        /** @default "" */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/products/lettershop/download`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProductPricingConstant
     * @summary Provides the active Product Pricing Constants.
     * @request GET:/api/products/product-pricing-constant
     * @secure
     */
    getProductPricingConstant: (params: RequestParams = {}) =>
      this.request<ProductPricingConstantResponse, void>({
        path: `/api/products/product-pricing-constant`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name UpdateProductPricingConstant
     * @summary Updates Product Pricing Constants
     * @request POST:/api/products/product-pricing-constant/update
     * @secure
     */
    updateProductPricingConstant: (data: ProductPricingConstantUpdateRequest, params: RequestParams = {}) =>
      this.request<ProductPricingConstantResponse, void>({
        path: `/api/products/product-pricing-constant/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProductionProducts
     * @summary Provides a list of Production Products.
     * @request GET:/api/products/production-product
     * @secure
     */
    getProductionProducts: (params: RequestParams = {}) =>
      this.request<ProductionProductResponse[], void>({
        path: `/api/products/production-product`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetProductionProductById
     * @summary Provides a Production Product.
     * @request GET:/api/products/production-product-id
     * @secure
     */
    getProductionProductById: (
      query?: {
        /** @format uuid */
        productionProductId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductionProductResponse[], void>({
        path: `/api/products/production-product-id`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name CreateProductionProduct
     * @summary Creates the Production Product
     * @request POST:/api/products/production-product/create
     * @secure
     */
    createProductionProduct: (data: ProductionProductCreateRequest, params: RequestParams = {}) =>
      this.request<ProductionProductResponse[], void>({
        path: `/api/products/production-product/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags Product
     * @name UpdateProductionProduct
     * @summary Updates the Production Product
     * @request PUT:/api/products/production-product/update
     * @secure
     */
    updateProductionProduct: (data: ProductionProductUpdateRequest, params: RequestParams = {}) =>
      this.request<ProductionProductResponse[], void>({
        path: `/api/products/production-product/update`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetTargetMarketAnalysisProductId
     * @summary Provides the Target Market Analysis Product Id.
     * @request GET:/api/products/target-market-analysis-id
     * @secure
     */
    getTargetMarketAnalysisProductId: (params: RequestParams = {}) =>
      this.request<string[], void>({
        path: `/api/products/target-market-analysis-id`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name GetSyntheticProduct
     * @summary Provides a Synthetic Product Response for Pricing Estimates.
     * @request POST:/api/products/synthetic-product
     * @secure
     */
    getSyntheticProduct: (data: ProductUpdateRequest, params: RequestParams = {}) =>
      this.request<ProductResponse, void>({
        path: `/api/products/synthetic-product`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductionBuilder
     * @name SendAll
     * @summary Sends all ready jobs to Automator2.
     * @request POST:/api/production-builder/send
     * @secure
     */
    sendAll: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/production-builder/send`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyRole
     * @name GetCompanyRoles
     * @summary Provides a list of Company Roles.
     * @request GET:/api/company-role
     * @secure
     */
    getCompanyRoles: (params: RequestParams = {}) =>
      this.request<CompanyRoleResponse[], any>({
        path: `/api/company-role`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserRole
     * @name GetUserRoles
     * @summary Provides a list of User Roles.
     * @request GET:/api/user-role
     * @secure
     */
    getUserRoles: (params: RequestParams = {}) =>
      this.request<UserRoleResponse[], any>({
        path: `/api/user-role`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name GetTemplates
     * @summary Provides a list of Templates for the Campaign Type.
     * @request GET:/api/campaign-type/{campaignTypeId}/template
     * @secure
     */
    getTemplates: (campaignTypeId: number, params: RequestParams = {}) =>
      this.request<TemplateResponse[], void>({
        path: `/api/campaign-type/${campaignTypeId}/template`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name GetTemplateById
     * @summary Retrieves the specified template
     * @request GET:/api/campaign-type/{campaignTypeId}/template/{templateId}
     * @secure
     */
    getTemplateById: (campaignTypeId: number, templateId: number, params: RequestParams = {}) =>
      this.request<TemplateResponse, void>({
        path: `/api/campaign-type/${campaignTypeId}/template/${templateId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name GetS3UploadUrl
     * @summary Provides a secure S3 url to which a template can be uploaded
     * @request GET:/api/campaign-type/{campaignTypeId}/template/{templateId}/s3upload
     * @secure
     */
    getS3UploadUrl: (campaignTypeId: number, templateId: number, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/api/campaign-type/${campaignTypeId}/template/${templateId}/s3upload`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Template
     * @name GetS3DownloadUrl
     * @summary Provides a secure S3 url from which a template can be downloaded
     * @request GET:/api/campaign-type/{campaignTypeId}/template/{templateId}/s3download
     * @secure
     */
    getS3DownloadUrl: (campaignTypeId: number, templateId: number, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/api/campaign-type/${campaignTypeId}/template/${templateId}/s3download`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_product_management,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags TemplateVersion
     * @name CreateTemplateVersion
     * @summary Creates the template version.
     * @request POST:/api/company/{companyId}/campaign/{campaignId}/template-version
     * @secure
     */
    createTemplateVersion: (
      companyId: number,
      campaignId: number,
      data: TemplateVersionCreationRequest,
      params: RequestParams = {},
    ) =>
      this.request<TemplateVersionResponse, void>({
        path: `/api/company/${companyId}/campaign/${campaignId}/template-version`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_production,mw_product_management,admin,approver,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>approver</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags TemplateVersion
     * @name GetTemplateVersionById
     * @summary Retrieves the specified template version
     * @request GET:/api/company/{companyId}/campaign/{campaignId}/template-version/{templateVersionId}
     * @secure
     */
    getTemplateVersionById: (
      companyId: number,
      campaignId: number,
      templateVersionId: number,
      params: RequestParams = {},
    ) =>
      this.request<TemplateVersionResponse, void>({
        path: `/api/company/${companyId}/campaign/${campaignId}/template-version/${templateVersionId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_product_management,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags TemplateVersion
     * @name UpdateTemplateVersion
     * @summary Updates the template version
     * @request PUT:/api/company/{companyId}/campaign/{campaignId}/template-version/{templateVersionId}
     * @secure
     */
    updateTemplateVersion: (
      companyId: number,
      campaignId: number,
      templateVersionId: number,
      data: TemplateVersionUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/api/company/${companyId}/campaign/${campaignId}/template-version/${templateVersionId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_product_management,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags TemplateVersion
     * @name GetS3UploadUrl2
     * @summary Provides a secure S3 url to which a template version can be uploaded
     * @request GET:/api/company/{companyId}/campaign/{campaignId}/template-version/{templateVersionId}/s3upload
     * @originalName getS3UploadUrl
     * @duplicate
     * @secure
     */
    getS3UploadUrl2: (companyId: number, campaignId: number, templateVersionId: number, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/api/company/${companyId}/campaign/${campaignId}/template-version/${templateVersionId}/s3upload`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following policy: <b>RequireCompanyAccess</b> Authorization requires  the following role: <b>mw_system_admin,mw_account_manager,mw_sales_manager,mw_managing_director,mw_vp,mw_product_management,admin,marketer</b> Authorization requires any one of  the following policies: <b>admin</b>, <b>Authorize Roles: mw_system_admin</b>, <b>marketer</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags TemplateVersion
     * @name GetS3DownloadUrl2
     * @summary Provides a secure S3 url from which a template version can be downloaded
     * @request GET:/api/company/{companyId}/campaign/{campaignId}/template-version/{templateVersionId}/s3download
     * @originalName getS3DownloadUrl
     * @duplicate
     * @secure
     */
    getS3DownloadUrl2: (companyId: number, campaignId: number, templateVersionId: number, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/api/company/${companyId}/campaign/${campaignId}/template-version/${templateVersionId}/s3download`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name GetCurrentUser
     * @summary Returns the current user.
     * @request GET:/api/user/current
     * @secure
     */
    getCurrentUser: (params: RequestParams = {}) =>
      this.request<UserResponse, any>({
        path: `/api/user/current`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_managing_director</b>, <b>mw_vp</b>
     *
     * @tags User
     * @name GetUserById
     * @summary Returns the user by id.
     * @request GET:/api/user/{userId}
     * @secure
     */
    getUserById: (userId: number, params: RequestParams = {}) =>
      this.request<UserResponse, void>({
        path: `/api/user/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UpdateUser
     * @summary Updates the user.
     * @request PUT:/api/user/{userId}
     * @secure
     */
    updateUser: (userId: number, data: UserUpdateRequest, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/user/${userId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_managing_director</b>, <b>mw_vp</b>
     *
     * @tags User
     * @name DisableUser
     * @summary Disable the user.
     * @request DELETE:/api/user/{userId}
     * @secure
     */
    disableUser: (userId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/user/${userId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_managing_director</b>, <b>mw_vp</b>
     *
     * @tags User
     * @name DisableCompanyUser
     * @summary Disables the company association with the user
     * @request POST:/api/user/companyuser
     * @secure
     */
    disableCompanyUser: (
      query?: {
        /** @format int32 */
        companyUserId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/user/companyuser`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_managing_director</b>, <b>mw_vp</b>
     *
     * @tags User
     * @name CreateUser
     * @summary Creates the user.
     * @request POST:/api/user
     * @secure
     */
    createUser: (data: UserCreationRequest, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/user`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name DownloadUsersCsv
     * @summary Returns internal or client user list as csv.
     * @request GET:/api/user
     * @secure
     */
    downloadUsersCsv: (
      query?: {
        search?: string
        sortField?: string
        sortOrder?: string
        userType?: string
        userStatus?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/user`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp,mw_account_manager,mw_sales_manager</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_managing_director</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags User
     * @name RecreateUser
     * @summary Recreates the user to resend invitation
     * @request POST:/api/user/recreate
     * @secure
     */
    recreateUser: (data: UserCreationRequest, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/user/recreate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp,mw_account_manager,mw_sales_manager,mw_accounting,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags User
     * @name GetUsersByTitle
     * @summary Returns all users by a title.
     * @request GET:/api/user/title
     * @secure
     */
    getUsersByTitle: (
      query?: {
        title?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<UserResponse[], any>({
        path: `/api/user/title`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authorization requires  the following role: <b>mw_system_admin,mw_managing_director,mw_vp,mw_account_manager,mw_sales_manager,mw_accounting,mw_production,mw_product_management</b> Authorization requires any one of  the following policies: <b>Authorize Roles: mw_system_admin</b>, <b>mw_account_manager</b>, <b>mw_accounting</b>, <b>mw_managing_director</b>, <b>mw_product_management</b>, <b>mw_production</b>, <b>mw_sales_manager</b>, <b>mw_vp</b>
     *
     * @tags User
     * @name GetAllUsers
     * @summary Returns all users.
     * @request GET:/api/user/all
     * @secure
     */
    getAllUsers: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number
        /**
         * @format int32
         * @default 10
         */
        pageSize?: number
        search?: string
        sortField?: string
        sortOrder?: string
        userType?: string
        userStatus?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<UserResponsePaged, any>({
        path: `/api/user/all`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name GetUserPreferences
     * @summary Returns user's preferences.
     * @request GET:/api/user/{userId}/preferences
     * @secure
     */
    getUserPreferences: (userId: number, params: RequestParams = {}) =>
      this.request<UserPreferenceResponse, any>({
        path: `/api/user/${userId}/preferences`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name SetUserPreferences
     * @summary Update's selected user's preferences.
     * @request PUT:/api/user/preferences
     * @secure
     */
    setUserPreferences: (data: UserPreferenceUpdateRequest, params: RequestParams = {}) =>
      this.request<UserPreferenceResponse, any>({
        path: `/api/user/preferences`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  status = {
    /**
     * No description
     *
     * @tags Status
     * @name GetVersion
     * @summary Provides a way to determine the version of the service.
     * @request GET:/status/version
     * @secure
     */
    getVersion: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/status/version`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Status
     * @name GetPing
     * @summary Provides a static response of 'pong' to determine the service's health.
     * @request GET:/status
     * @secure
     */
    getPing: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/status`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
}
